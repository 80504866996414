import "./guarantor.scss"
import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import TextInput from "../../../Components/Input/TextInput"
import { useNavigate } from "react-router-dom"
import EmailList from "../../../Components/EmailList/EmailList"
import { useState } from "react"

function Guarantor() {
  const [copied, setCopied] = useState(false)

  const navigate = useNavigate()

  return (
    <section className="Guarantor">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate(-1)
          }}
        >
          back
        </button>
        <ProgressBar ProgressBar type="tenant" step="6" />

        <h1>add a guarantor to your application</h1>
        <p>
          Sometimes it helps to have a guarantor on your application,
          whether it’s because you are a student, are new to a job, or
          just to make the landlord more comfortable with your
          application. This step is optional, but can often help if
          competition is high for a home.
        </p>

        <div className="Guarantor__container">
          <h3>invite by email</h3>
          <EmailList />
        </div>

        <div className="Guarantor__container">
          <div className="Guarantor__link">
            <span>invite with a link:</span>
            <a href="#">path/to/link?token=1234abcd</a>
            <button
              onClick={() => {
                navigator.clipboard.writeText(
                  "path/to/link?token=1234abcd"
                )
                setCopied(true)
              }}
              className="btn"
            >
              Copy link
            </button>
          </div>
          {copied && (
            <p className="mt-1 mb-0">
              <small>link copied</small>
            </p>
          )}
        </div>

        <div className="btn-group">
          <button
            className="btn btn--primary"
            onClick={() => {
              navigate("/tenant/basic/request")
            }}
          >
            next
          </button>
          <button
            className="btn btn--secondary-alt"
            onClick={() => {
              navigate("/tenant/basic/request")
            }}
          >
            skip
          </button>
        </div>
      </div>
    </section>
  )
}

export default Guarantor
