import "./applyCoApplicants.scss"
import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import { useNavigate } from "react-router-dom"
import Logo from "../../../assets/svg/rentPandaLogo.svg"

function ApplyCoApplicants() {
  let navigate = useNavigate()

  return (
    <section className="ApplyCoApplicants">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate(-1)
          }}
        >
          back
        </button>

        <ProgressBar type="tenant" step="2" />

        <div className="row">
          <div className="col-lg-10">
            <h1 className="co-app-title">
              Are you applying alone or with others?
            </h1>
          </div>
        </div>

        <div className="ApplyCoApplicants__choices">
          <button
            className="btn"
            onClick={() => {
              navigate("/tenant/current-housing")
            }}
          >
            alone
          </button>
          <button
            className="btn"
            onClick={() => {
              navigate("/tenant/send-coapplicant-link")
            }}
          >
            together
          </button>
        </div>
      </div>
    </section>
  )
}

export default ApplyCoApplicants
