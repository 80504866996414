import { useState } from "react"
import DatePickerInput from "../../../../../Components/Input/DatePickerInput"
import TextInput from "../../../../../Components/Input/TextInput"
import CreditReportConfirmModal from "../CreditReportConfirmModal/CreditReportConfirmModal"
import SelectInput from "../../../../../Components/Input/SelectInput"
import axios from "axios"
import config from "../../../../../config/config"
import Cookies from "universal-cookie"

function CreditReportModal({ closeModal }) {
  const [
    openCreditReportConfirmModal,
    setOpenCreditReportConfirmModal,
  ] = useState(false)

  const [creditAddress, setCreditAddress] = useState(false)
  const [creditCity, setCreditCity] = useState(false)
  const [creditProvince, setCreditProvince] = useState("AB")
  const [creditSIN, setCreditSIN] = useState(false)
  const [creditDOB, setCreditDOB] = useState(false)
  const cookie = new Cookies()
  const applicationId = localStorage.getItem("currentApplicationId")
  const [ageErr, setAgeErr] = useState(false)
  const [ageErrMsg, setAgeErrMsg] = useState("")

  const checkAge = (dob) => {
    const today = new Date()
    const birthDate = new Date(dob)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age >= 18
  }

  const handleAge = (dob) => {
    const validAge = checkAge(dob)
    if (!validAge) {
      setAgeErrMsg("Applicant age cannot be less than 18.")
      setAgeErr(true)
    } else {
      setCreditDOB(dob)
      setAgeErr(false)
    }
  }

  const runCreditCheck = function () {
    const res = axios
      .post(
        `${config.BASE_URL}api/credit-check`,
        {
          sin: creditSIN,
          address: creditAddress,
          city: creditCity,
          province: creditProvince,
          date_of_birth: creditDOB,
          country: "CA",
          application_id: applicationId,
        },
        {
          headers: {
            Authorization: "Bearer " + cookie.get("auth_token"),
          },
        }
      )
      .then(function (response) {
        if (response.data.status == 1) {
          setOpenCreditReportConfirmModal(true)
        } else {
          alert("There was an error submitting your credit report")
        }
      })
  }

  return (
    <div className="CreditReportModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2>please provide your SIN and birthdate</h2>
          <p>
            <strong>
              this information will not be saved or displayed to the
              landlord.
              <br />
              It is used to accurately pull your credit information
              directly from Equifax.
            </strong>
          </p>
          <div className="row w-full">
            <div className="col-md-6">
              <TextInput
                type="text"
                label="your address"
                name="address"
                onChange={(e) => setCreditAddress(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                type="text"
                label="your city"
                name="city"
                onChange={(e) => setCreditCity(e.target.value)}
              />
            </div>
          </div>
          <div className="row w-full">
            <div className="col-md-12">
              <SelectInput
                label="your province"
                name="province"
                inline="true"
                onChange={(e) => setCreditProvince(e.target.value)}
              >
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="NT">Northwest Territories</option>
                <option value="NU">Nunavut</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="YT">Yukon</option>
              </SelectInput>
            </div>
          </div>
          <br />
          <div className="row w-full">
            <div className="col-md-6">
              <TextInput
                type="text"
                label="your SIN"
                name="sin"
                onChange={(e) => setCreditSIN(e.target.value)}
                hasError={/^\d{9}$/.test(creditSIN) === false}
                placeholder={"#########"}
              />
            </div>
            <div className="col-md-6">
              <DatePickerInput
                label="your birthday"
                name="dob"
                onChange={(e) => {
                  handleAge(e.target.value)
                }}
              />
              {ageErr && <p className={"text-danger"}>{ageErrMsg}</p>}
            </div>
          </div>

          <div className="btn-group">
            <button
              className="btn btn--primary"
              onClick={() => {
                !ageErr && runCreditCheck()
              }}
            >
              next
            </button>
            <button
              className="btn btn--back"
              onClick={() => {
                closeModal(false)
              }}
            >
              cancel
            </button>
          </div>
        </div>
      </div>
      {openCreditReportConfirmModal && (
        <CreditReportConfirmModal
          closeModal={setOpenCreditReportConfirmModal}
        />
      )}
    </div>
  )
}

export default CreditReportModal
