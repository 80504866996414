import { useEffect, useState } from "react"
import clsx from "clsx"
import Cookies from "universal-cookie"
import axios from "axios"
import config from "../../../config/config"


function ScanResults({ passed, name, meta }) {
  return (
    <>
      <h4>{name}</h4>
      <div
        className={clsx(
          "status-label",
          !passed && "status-label--warning"
        )}
      >
        <span
          className={passed ? "circle-check--tiny" : "circle-x--tiny"}
        ></span>
        <span>{passed ? "clear" : "warning"}</span>
      </div>
      {meta}
      {/* <p className="mb-0 mt-0-5">
        The criminal record search revealed a potential warning.
        <br />
        Please download the PDF to read the full report.{" "}
      </p> */}
    </>
  )
}


function BackgroundCheck({ data }) {
  const [showData, setShowData] = useState(true)
  const [certnId, setCertnId] = useState(null)
  const [showDownloadBtn, setShowDownloadBtn] = useState(false)
  const scan = data.risk_result
  const status = {
    RETURNED: "completed",
    ANALYZING: "analyzing",
    NONE: "analyzing",
    default: "incomplete",
  }


  useEffect(() => {
    let userCertnId = data?.id || null
    if (userCertnId) {
      setCertnId(userCertnId)
      setShowDownloadBtn(true)
    }

  }, [])


  const handleGetFullReport = () => {
    const endpoints = {
      pdf: "download-report",
      web: "web-report",
      link: "link-report", // Only one that works atm
    }

    const openInNewTab = (url) => {
      const win = window.open(url, '_blank')
      win.focus()
    }

    const cookies = new Cookies()
    const apiConfig = {
      headers: {
        Authorization: "Bearer " + cookies.get("auth_token"),
      },
    }
    axios.post(
      `${config.BASE_URL}api/certn/${endpoints.link}`,
      { application_id: certnId },
      apiConfig
    )
      .then((response) => {
        if (response.data.status === 1) {
          openInNewTab(response.data.data.report_url)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }





    return (
    <section className="background-check-info application-preview-container">
      <div className="info-title-container">
        <div className="info-title">
          <div
            className={`info__label info__label--${
              status[scan?.status] || status.default
            }`}
          >
            {status[scan?.status] || status.default}
          </div>
          <h2>
            <span className="mr-1">background check</span>
            {showDownloadBtn && (
              <button className="btn btn--download"
                      onClick={handleGetFullReport}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z" />
                </svg>
              </button>
            )}
          </h2>
        </div>
        <div className="info-title-controls">
          <button
            className="btn btn--toggle btn--toggle-inverse"
            onClick={() => setShowData(!showData)}
          ></button>
        </div>
      </div>

      {showData ? (
        <>
          <ScanResults
            name={"criminal record search"}
            passed={scan.scan_status.criminal_scan === "CLEARED"}
            // meta={
            //   scan.scan_status.criminal_scan === "CLEARED" && (
            //     <p className="mb-0 mt-0-5">
            //       The criminal record search revealed a potential
            //       warning.
            //       <br />
            //       Please download the PDF to read the full report.{" "}
            //     </p>
            //   )
            // }
          />
          <ScanResults
            name="fraud scan"
            passed={scan.scan_status.fraud_scan === "CLEARED"}
          />
          <ScanResults
            name="sex offender scan"
            passed={scan.scan_status.sex_offender_scan === "CLEARED"}
          />
        </>
      ) : null}
    </section>
  )
}

export default BackgroundCheck
