const DatePickerInput = ({
  value,
  label,
  name,
  placeholder,
  onChange,
  hasError,
}) => (
  <div className="form-group">
    {label && <label htmlFor={name}>{label}</label>}
    <input
      type="date"
      value={value}
      name={name}
      id={name}
      //   className="form-group__form-control"
      className={[
        "form-group__form-control",
        hasError ? "input-error-border" : null,
      ]
        .filter(Boolean)
        .join(" ")}
      placeholder={placeholder}
      onChange={onChange}
    />
    {/*  error message here  */}
  </div>
)

export default DatePickerInput
