import "./basicLLRequest.scss"
import ProgressBar from "../../../../Components/ProgressBar/ProgressBar"
import CheckboxInput from "../../../../Components/Input/CheckboxInput"
import { useNavigate } from "react-router-dom"
import { CartState } from "Context/cartContext/CartContext"

function BasicLLRequest() {
  const navigate = useNavigate()
  const {
    state: { premiumFeatures, cart },
    dispatch,
  } = CartState()

  const handleClick = (e) => {
    //update cart
  }

  return (
    <section className="BasicLLRequest">
      <div className="container">
      <button
            className="btn btn--back"
            onClick={() => {
              navigate(-1)
            }}
          >
            back
          </button>
        <ProgressBar type="tenant" step="7" />

        <div className="row">
          {/* Payment form */}
          <div className="col-xl-8 col-lg-7">
            <h1>
              you’ve completed the basic info for
              your&nbsp;application.
            </h1>
            <h3 className="mb-3">
              would you like to set yourself apart
              from other tenants with some of the
              advanced tools below?
            </h3>
            <div className="row justify-content-between">
              <div className="col-xl">
                {premiumFeatures.map(
                  (feature) => (
                    <div className="col-lg-6 mr-auto advancedFeatures">
                      <div
                        key={feature.id}
                        type="checkbox"
                        name={feature.name}
                        className="form-checkbox__form-control"
                        checked={
                          feature?.isChecked ||
                          false
                        }
                        // onChange={handleChange}
                        onClick={handleClick}
                      />
                      <label className="form-checkbox__details advancedFtText">
                        {feature.name}
                      </label>
                      {cart.some(
                        (f) => f.id === feature.id
                      ) ? (
                        <button
                          className="checkedbox checkBtn"
                          onClick={() => {
                            dispatch({
                              type: "REMOVE_FROM_CART",
                              payload: feature,
                            })
                          }}
                        >
                          
                        </button>
                      ) : (
                        <button
                          className="greyBtn"
                          onClick={() => {
                            dispatch({
                              type: "ADD_TO_CART",
                              payload: feature,
                            })
                          }}
                        >
                          
                        </button>
                      )}
                    </div>
                  )
                )}
                {/* <CheckboxInput
                  label="credit check"
                  name="credit_check"
                >
                  industry standard credit checks
                  are a secure and trusted way to
                  back up your application with
                  financial info.
                </CheckboxInput>
              </div>
              <div className="col-xl-6">
                <CheckboxInput
                  label="id verification"
                  name="id_verification"
                >
                  let the landlord know you are a
                  real person, so they aren’t
                  worried about any scams.
                </CheckboxInput>
              </div>
              <div className="col-xl-6">
                <CheckboxInput
                  label="background check"
                  name="background_check"
                >
                  get an instant background check,
                  so you and the landlord dont
                  have to go searching.
                </CheckboxInput>
              </div>
              <div className="col-xl-6">
                <CheckboxInput
                  label="verified landlord references"
                  name="verified_landlord_references"
                >
                  get your old landlords to leave
                  you a reference, so no one has
                  to bother with old school calls.
                </CheckboxInput>
              </div>
              <div className="col-xl-6">
                <CheckboxInput
                  label="personalized video"
                  name="personalized_video"
                >
                  introduce yourself to your
                  potential landlord, so they can
                  get to know you quickly and
                  easily.
                </CheckboxInput>
              </div>
              <div className="col-xl-6">
                <CheckboxInput
                  label="income & expenses verification"
                  name="employment_income_verification"
                >
                  securely connect with your bank,
                  to verify your income and rent
                  payments so you dont have to
                  pull paystubs or letters of
                  employment anymore.
                </CheckboxInput> */}
              </div>
            </div>
          </div>

          {/* Order Summary */}
          <div className="col-xl-4 col-lg-5">
            <div className="order-summary">
              <div className="order-summary__details">
                <h2>order summary</h2>
                {cart.length > 0 ? (
                  <div className="order-summary__order-item">
                    {cart.map((feature) => (
                      <div className="order-summary__order-item">
                        <span>
                          {feature.name}
                        </span>
                        <span>
                          ${feature.price}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  "add premium features!"
                )}
                <div className="order-summary__order-item mt-1">
                  <span>
                    coupon/ referral discount
                  </span>
                  <span>-$5.00</span>
                </div>
              </div>
              <div className="order-summary__total">
                <h2>total amount</h2>
                <h2>$4.00</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-group">
          <button
            className="btn btn--primary"
            onClick={() => {
              navigate("/premium/payment")
            }}
          >
            proceed to payment
          </button>
          <button
            className="btn btn--secondary-alt"
            onClick={() => {
              navigate("/tenant/premium-features")
            }}
          >
            skip for now
          </button>
        </div>
      </div>
    </section>
  )
}

export default BasicLLRequest
