import "./premiumModal.scss"

function PremiumModal({ setOpenPremiumModal }) {
  return (
    <div className="PremiumModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h1>some helpful info for you</h1>
          <h2>advanced screening features</h2>

          <div className="row justify-content-between PremiumModal__modal__details">
            <div className="col-lg-5">
              <h3>credit check</h3>
              <p>
                Detailed credit report including credit score, who
                they owe money to and how much, all cases sent to
                collections and past bankruptcies.
              </p>
            </div>
            <div className="col-lg-5">
              <h3>id verification</h3>
              <p>
                Verified government ID and face matching technology to
                ensure safety and security.
              </p>
            </div>
            <div className="col-lg-5">
              <h3>background check</h3>
              <p>
                Exposes any criminal record, shows Landlord and Tenant
                Board appearances, analyzes their social media
                profiles and checks all public records.
              </p>
            </div>
            <div className="col-lg-5">
              <h3>verified landlord reference</h3>
              <p>
                Real reviews from past landlords who have been
                verified through lease and landlord ID matching
                technology.
              </p>
            </div>
            <div className="col-lg-5">
              <h3>income & expenses verification</h3>
              <p>
                We scan their bank statements, access their pay stubs
                and measure their expenses with details directly from
                the bank.
              </p>
            </div>
            <div className="col-lg-5">
              <h3>personalized video</h3>
              <p>
                Get a video of your applicants so you can get to know
                them and do your ‘gut’ check, before you meet in
                person.
              </p>
            </div>
          </div>

          <button
            className="btn btn--primary btn--small"
            onClick={() => setOpenPremiumModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default PremiumModal
