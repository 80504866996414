function HandleLoginRedirect(navigate, response) {
    if (
    (response.data.status == 0 && typeof response.data.authed != "undefined" && response.data.authed == false) ||
    (typeof response.data.message != "undefined" && response.data.message == 'Unauthenticated.')
    ) {
      localStorage.setItem("user", null);
      navigate("/login");
    }

    return false;
}

export default HandleLoginRedirect;