import {useState} from "react";
import {useNavigate} from "react-router-dom";
import EditLLComponent from "./EditLLComponent";
import "./editLLReference.scss";

function EditLLReference() {
    const [referenceList, setReferenceList] =
        useState([<EditLLComponent/>]);
    const addReference = () => {
        setReferenceList([
            ...referenceList,
            {
                id: referenceList.length,
                value: Math.floor(Math.random() * 10) + 1,
            },
        ]);
    };

    const navigate = useNavigate();
    return (
        <section className="editLlReference">
            <div className="container">
                <div className="info-title-container">
                    <div className="info-title">
                        <h2>verified landlord reference</h2>
                    </div>
                    <div className="info-title-controls"></div>
                </div>
                {referenceList.map((index) => (
                    <>
                        <EditLLComponent key={index}/>
                    </>
                ))}
                <button
                    className="btn btn--secondary-alt"
                    onClick={addReference}
                >
                    add more
                </button>
                <div className="btn-group mt-3">
                    <button
                        className="btn btn--back"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        cancel
                    </button>
                    <button className="btn btn--primary">
                        done
                    </button>
                </div>
            </div>
        </section>
    );
}

export default EditLLReference;
