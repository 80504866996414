import "./landlordPaywall.scss"
import CheckboxInput from "../../../Components/Input/CheckboxInput"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import RequestApplicantPayModal from "../ScreenApplicants/RequestApplicantPayModal/RequestApplicantPayModal"
import { CartState } from "Context/cartContext/CartContext"

function LandlordPaywall(props) {
  const navigate = useNavigate()
  const [openRequestApplicantModal, setOpenRequestApplicantModal] =
    useState(false)
  const [selected, setSelected] = useState(false)

  const handleClick = (e) => {
    //update cart
  }

  const {
    state: { premiumFeatures, cart },
    dispatch,
  } = CartState()

  const [total, setTotal] = useState()

  useEffect(() => {
    let total = 0
    Object.keys(cart).map((item) =>
      Object.keys(cart[item]).map(
        (cartItem) =>
          (total =
            total +
            Number(cart[item][cartItem].price) *
              cart[item][cartItem].qty)
      )
    )

    setTotal(total)
    /*
    setTotal(
      Object.keys(applicationList).map((id) => (
      /* 
      cart.reduce(
        (acc, curr) =>
          acc + Number(curr.price) * curr.qty,
        0
      ) 
    ) */
  }, [cart])

  // useEffect(() => {
  //   localStorage.setItem(
  //     "cart",
  //     JSON.stringify(cart)
  //   )
  // }, [cart])
  return (
    <section className="LandlordPaywall">
      <div className="container">
        <div className="row">
          {/* Payment form */}
          <div className="col-xl-8 col-lg-7">
            <h1>get more information about -tenant name-</h1>

            <div className="row justify-content-between">
              {premiumFeatures.map((feature) => (
                <div className="col-lg-6 mr-auto advancedFeatures">
                  <div
                    key={feature.id}
                    type="checkbox"
                    name={feature.name}
                    className="form-checkbox__form-control"
                    checked={feature?.isChecked || false}
                    // onChange={handleChange}
                    onClick={handleClick}
                  />
                  <label className="form-checkbox__details advancedFtText">
                    {feature.name}
                  </label>
                  {typeof selected[feature.id] != "undefined" &&
                  selected[feature.id] == true ? (
                    <button
                      className="checkedbox checkBtn"
                      onClick={() => {
                        dispatch({
                          type: "REMOVE_FROM_CART",
                          payload: {
                            ...feature,
                            applicantId: Number(props.id),
                          },
                          id: props.id,
                        })
                        console.log(props.id)
                        setSelected({
                          ...selected,
                          [feature.id]: false,
                        })
                      }}
                    ></button>
                  ) : (
                    <button
                      className="greyBtn"
                      onClick={() => {
                        dispatch({
                          type: "ADD_TO_CART",
                          payload: {
                            ...feature,
                            applicantId: Number(props.id),
                          },
                          id: props.id,
                        })
                        console.log(props.id)
                        setSelected({
                          ...selected,
                          [feature.id]: true,
                        })
                      }}
                    ></button>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Order Summary */}
          <div className="col-xl-4 col-lg-5">
            <div className="order-summary">
              <div className="order-summary__details">
                <h2>Order Summary</h2>
                {cart.length > 0 ? (
                  <>
                    {cart.map((feature) => (
                      <div className="order-summary__order-item">
                        <span>{feature[0].name}</span>
                        <span>${feature[0].price}</span>
                      </div>
                    ))}
                  </>
                ) : (
                  "add premium features!"
                )}

                {/* <div className="order-summary__order-item">
                  <span>HST</span>
                  <span>$3.00</span>
                </div> */}
                {/* <div className="order-summary__order-item mt-1">
                  <span>Coupon/ Referral discount</span>
                  <span>-$5.00</span>
                </div> */}
              </div>
              <div className="order-summary__total">
                <h2>total amount</h2>
                <h2>${total}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-group">
          <button className="btn btn--back">back</button>
          <button
            className="btn btn--secondary-alt coming-soon"
            onClick={() => {
              // RS-89
              //   setOpenRequestApplicantModal(true)
            }}
            disabled
          >
            {/* request tenant pays */}
            <span className="disabled">request tenant pays</span>
            <div
              className="comingSoon"
              style={{
                top: "0px",
                right: "20%",
                padding: "5px 5px",
              }}
            >
              <h4 className="comingText">coming soon!</h4>
            </div>
          </button>
          <button
            className="btn btn--primary"
            onClick={() => {
              navigate("/premium/payment")
            }}
          >
            proceed to payment
          </button>
        </div>
        <p className="mt-2 mb-0">
          Note: you will only be charged when a tenant submits their
          application
        </p>
      </div>
      {openRequestApplicantModal && (
        <RequestApplicantPayModal
          closeModal={setOpenRequestApplicantModal}
        />
      )}
    </section>
  )
}

export default LandlordPaywall
