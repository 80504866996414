function DeleteModal({ onDelete, onClose }) {
  return (
    <div className="DeleteModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2>are you sure you want to delete?</h2>

          <div className="btn-group">
            <button
              className="btn btn--primary"
              onClick={() => {
                onDelete()
                onClose()
              }}
            >
              Yes
            </button>
            <button
              className="btn btn--primary"
              onClick={() => {
                onClose()
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteModal
