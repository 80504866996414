import axios from "axios"
import config from "../../config/config"
import { loginStart, loginFailure, loginSuccess } from "./AuthActions"
import Cookies from "universal-cookie"

export const login = async (user, dispatch) => {
  const INVALID_LOGIN = new Error("Invalid login")

  dispatch(loginStart)
  try {
    const res = await axios
      .post(`${config.BASE_URL}api/login`, user)
      .then(function (response) {
        // handle invalid login
        if (response.data.authed === false) {
          throw INVALID_LOGIN
        }
        if (
          typeof response.data.errors != "undefined" &&
          response.data.errors.length > 0
        ) {
          dispatch(loginFailure)
        }
        if (
          typeof response.data.status != "undefined" &&
          response.data.authed != "undefined" &&
          response.data.status == 1 &&
          response.data.authed == true &&
          typeof response.data.tokens != "undefined"
        ) {
          if (response.data.tokens[1] == "bearer") {
            const cookies = new Cookies()
            cookies.set("auth_token", response.data.tokens[0], {
              path: "/",
            })

            dispatch(loginSuccess(response.data))
          }
        }
      })
  } catch (err) {
    dispatch(loginFailure())
  }
}
