import { useState } from "react"
import RejectionNoticeModal from "./RejectionNoticeModal"

function LLPaidRejectModal({ setOpenModal }) {
  const [openModal2, setOpenModal2] =
    useState(false)
  return (
    <div className="LLPaidRejectModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h3>
            are you sure you dont want to complete
            the advanced tools as part of your
            application? Without it, your
            application will be withdrawn.
          </h3>

          <div className="btn-group">
            <button
              className="btn btn--back"
              onClick={() => {
                setOpenModal(false)
              }}
            >
              back
            </button>
            <button
              className="btn btn--primary"
              onClick={() => {
                setOpenModal2(true)
              }}
            >
              yes
            </button>
          </div>
        </div>
      </div>
      {openModal2 && (
        <RejectionNoticeModal
          openModal2={openModal2}
          setOpenModal2={setOpenModal2}
        />
      )}
    </div>
  )
}

export default LLPaidRejectModal
