import { useEffect, useState } from "react"

const NumberPickerInput = ({
  value,
  label,
  name,
  placeholder,
  min,
  max,
  onChange,
  hasError,
}) => {
  const [count, setCount] = useState(parseInt(value))

  function decrementCount() {
    if (count > min) {
      setCount((prevCount) => parseInt(prevCount) - 1)
    }
  }

  function incrementCount() {
    setCount((prevCount) => parseInt(prevCount) + 1)
  }

  useEffect(() => {
    setCount(parseInt(value))
  }, [value])


  useEffect(() => {
    if (count !== value) {
      onChange(count)
    }
  }, [count])

  return (
    <div className="form-group">
      {/* todo: add functionality to buttons */}
      {label && <label htmlFor={name}>{label}</label>}
      <div className={"form-group__number-container"}>
        <button
          className={[
            "btn",
            hasError
              ? "input-error-border-left input-error-border-top input-error-border-bottom"
              : null,
          ]
            .filter(Boolean)
            .join(" ")}
          onClick={decrementCount}
        ></button>
        <input
          type="number"
          value={count}
          name={name}
          id={name}
          className={[
            "form-group__form-number input-maybe-error",
            hasError
              ? "input-error-border-top input-error-border-bottom"
              : null,
          ]
            .filter(Boolean)
            .join(" ")}
          placeholder={placeholder}
          onChange={(e) => {
            setCount(parseInt(e.target.value))
          }}
          min={min}
          max={max}
        />
        <button
          className={[
            "btn",
            hasError
              ? "input-error-border-right input-error-border-top input-error-border-bottom"
              : null,
          ]
            .filter(Boolean)
            .join(" ")}
          onClick={incrementCount}
        ></button>
      </div>
      {/*  error message here  */}
    </div>
  )
}

export default NumberPickerInput
