import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import Navbar from "../Layout/Navbar/Navbar"
import Login from "../Pages/Login/Login"
import Signup from "../Pages/Signup/Signup"
import ChoosePath from "../Pages/Landlord/ChoosePath/ChoosePath"
import AddressInformation from "../Pages/Landlord/AddressInformation/AddressInformation"
import CustomizeApplication from "Pages/Landlord/CustomizeApplication/CustomizeApplication"
import ApplicationInvite from "../Pages/Landlord/ApplicationInvite/ApplicationInvite"
import ScreenApplicants from "../Pages/Landlord/ScreenApplicants/ScreenApplicants"
import Payment from "../Pages/Payment/Payment"
import LandlordDashboard from "../Pages/Landlord/LandlordDashboard/LandlordDashboard"
import Application from "../Pages/Landlord/Application/Application"
import ApplyCoApplicants from "../Pages/Tenants/ApplyCoApplicants/ApplyCoApplicants"
import CoapplicantSendLink from "../Pages/Tenants/CoapplicantSendLink/CoapplicantSendLink"
import CoapplicantConfirmInvitation from "../Pages/Tenants/CoapplicantConfirmInvitation/CoapplicantConfirmInvitation"
import Housing from "../Pages/Tenants/Housing/Housing"
import EmploymentInfo from "../Pages/Tenants/EmploymentInfo/EmploymentInfo"
import HouseholdInfo from "../Pages/Tenants/HouseholdInfo/HouseholdInfo"
import Guarantor from "../Pages/Tenants/Guarantor/Guarantor"
import GuarantorLink from "../Pages/Tenants/Guarantor/GuarantorLink"
import BasicLLRequest from "../Pages/Tenants/Premium/BasicLLRequest/BasicLLRequest"
import LLPaid from "../Pages/Tenants/Premium/LLPaid/LLPaid"
import TenantPay from "../Pages/Tenants/Premium/TenantPay/TenantPay"
import ApplicationPreview from "../Pages/Tenants/ApplicationPreview/ApplicationPreview"
import TenantDashboard from "../Pages/Tenants/TenantDashboard/TenantDashboard"
import TenantApplication from "../Pages/Tenants/TenantDashboard/TenantApplication/TenantApplication"
import TenantPremiumFeatures from "../Pages/Tenants/TenantPremiumFeatures/TenantPremiumFeatures"
import LandlordReferences from "../Pages/Tenants/PremiumFeatures/LandlordReferences/LandlordReferences"
import LandlordReference from "../Pages/Tenants/LandlordReference/LandlordReference"
import LandlordReferenceThankYou from "../Pages/Tenants/LandlordReference/LandlordReferenceThankYou/LandlordReferenceThankYou"
import CreditReport from "../Pages/Tenants/PremiumFeatures/CreditReport/CreditReport"
import BackgroundCheck from "../Pages/Tenants/PremiumFeatures/BackgroundCheck/BackgroundCheck"
import EmploymentIncomeVerification from "../Pages/Tenants/PremiumFeatures/EmploymentIncomeVerification/EmploymentIncomeVerification"
import IdVerification from "../Pages/Tenants/PremiumFeatures/IdVerification/IdVerification"
import { AuthContext } from "Context/authContext/AuthContext"
import { useContext } from "react"
import LandlordLanding from "../Pages/Landlord/LandlordLanding/LandlordLanding"
import TenantPaywall from "../Pages/Tenants/Premium/TenantPaywall/TenantPaywall"
import LandlordPaywall from "../Pages/Landlord/LandlordPaywall/LandlordPaywall"
import EditLLReference from "Components/ApplicationPreview/VerifiedLandlordReference/EditLLReference/EditLLReference"
import EditView from "Components/ApplicationPreview/VerifiedLandlordReference/EditView/EditView"
import MyAccount from "../Pages/MyAccount/MyAccount"
import ViewScreeningRequests from "../Pages/Landlord/ViewScreeningRequests/ViewScreeningRequests"
import Footer from "../Layout/Footer/Footer"
import TermsConditions from "../Pages/General/TermsConditions/TermsConditions"
import PrivacyPolicy from "../Pages/General/PrivacyPolicy/PrivacyPolicy"
import TenantLanding from "Pages/Tenants/TenantLanding/TenantLanding"
import ScrollToTop from "ScrollToTop"
import ForgotPassword from "Pages/ForgotPassword/ForgotPassword"

import Helmet from "react-helmet"

import { FlowPremiumContextProvider } from "Context/flowPremiumContext/FlowPremiumContext"
import ResetPassword from "Pages/ResetPassword/ResetPassword"

function AppRoutes({ openModal, setOpenModal }) {
  const { user } = useContext(AuthContext)
  //   function AuthRoute({ children }) {
  //     if (!user && !user?.user_id) {
  //       //Not signed in
  //       return <Navigate to="/login" />
  //     }
  //     //Signed in
  //     return children
  //   }

  function LandlordAuthRoute({ children }) {
    if (
      (!user && !user?.user_id) ||
      (user && user?.roles?.find((role) => role.name !== "landlord"))
    ) {
      //Not signed in
      return <Navigate to="/" />
    }
    //Signed in
    return children
  }

  function ApplicantAuthRoute({ children }) {
    if (
      (!user && !user?.user_id) ||
      (user && user?.roles?.find((role) => role.name !== "applicant"))
    ) {
      //Not signed in
      return <Navigate to="/" />
    }
    //Signed in
    return (
      <FlowPremiumContextProvider>
        {children}
      </FlowPremiumContextProvider>
    )
  }

  const URLSearch = new URLSearchParams(window.location.search)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Smart Screening - Tenant Screening | Rent Panda</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          {/* ==== AUTH ==== */}
          <Route
            path="/"
            element={
              user?.roles?.[0]?.name === "landlord" ? (
                <Navigate to="/landlord/dashboard" />
              ) : (
                <LandlordLanding />
              )
            }
          />
          <Route
            path="/login"
            element={
              /**
               * user auth
               * 	has "landlord" role
               * 		/landlord/property/create
               * 	else
               * 		/404
               * render login
               */
              user ? (
                URLSearch.has("redirect") ? (
                  <Navigate to={atob(URLSearch.get("redirect"))} />
                ) : user?.roles?.find(
                    (role) => role.name === "landlord"
                  ) ? (
                  <Navigate to="/landlord/dashboard" />
                ) : (
                  // TODO: replace this path with the tenant landing page path
                  <Navigate to="/tenant/dashboard" />
                )
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/signup"
            element={
              user ? (
                URLSearch.has("redirect") ? (
                  <Navigate to={atob(URLSearch.get("redirect"))} />
                ) : user?.roles?.find(
                    (role) => role.name === "landlord"
                  ) ? (
                  <Navigate to="/landlord/dashboard" />
                ) : (
                  // TODO: replace this path with the tenant landing page path
                  <Navigate to="/tenant/dashboard" />
                )
              ) : (
                <Signup />
              )
            }
          />

          <Route
            path="/forgot-password"
            element={<ForgotPassword />}
          />

          <Route
            path="/reset-password/:token/:email"
            element={<ResetPassword />}
          />

          {/* ==== MY ACCOUNT ==== */}

          {/* 6.2 My Account */}
          <Route path="/my-account" element={<MyAccount />} />

          {/* ==== TENANT ==== */}
          {/* 1.1: invitation from LL - tenant to pay */}
          <Route path="/apply/:token" element={<TenantLanding />} />

          {/* 1.2: invitation from LL - LL prepaid  */}
          <Route
            path="/apply/paid/:token"
            element={<TenantLanding />}
          />

          {/* 4.1 Are you applying alone or with co applicants */}
          <Route
            path="/tenant/coapplication"
            element={
              <ApplicantAuthRoute>
                <ApplyCoApplicants />
              </ApplicantAuthRoute>
            }
          />

          {/* 4.2-2 Tenant link*/}
          <Route
            path="/tenant/send-coapplicant-link"
            element={
              <ApplicantAuthRoute>
                <CoapplicantSendLink />
              </ApplicantAuthRoute>
            }
          />

          {/* 4.2-1 Confirm co-application invitation*/}
          <Route
            path="/tenant/coapplicant-confirm-invite"
            element={
              <ApplicantAuthRoute>
                <CoapplicantConfirmInvitation />
              </ApplicantAuthRoute>
            }
          />

          {/* 5.1 & 5.2 Current Housing */}
          <Route
            path="/tenant/current-housing"
            element={
              <ApplicantAuthRoute>
                <Housing type="current" key="current" />
              </ApplicantAuthRoute>
            }
          />

          {/* 6.1 & 6.2 Previous Housing */}
          <Route
            path="/tenant/previous-housing"
            element={
              <ApplicantAuthRoute>
                <Housing type="previous" key="previous" />
              </ApplicantAuthRoute>
            }
          />

          {/* 7.1 & 7.2 & 7.3 & 7.4 & 7.5 */}
          <Route
            path="/tenant/employment-info"
            element={
              <ApplicantAuthRoute>
                <EmploymentInfo />
              </ApplicantAuthRoute>
            }
          />

          {/* 8.1 & 8.2 */}
          <Route
            path="/tenant/household-info"
            element={
              <ApplicantAuthRoute>
                <HouseholdInfo />
              </ApplicantAuthRoute>
            }
          />

          {/* 9.1-2 */}
          <Route
            path="/tenant/guarantor"
            element={
              <ApplicantAuthRoute>
                <Guarantor />
              </ApplicantAuthRoute>
            }
          />

          {/* 9.2 & 9.2-2 */}
          <Route
            path="/tenant/guarantor/link"
            element={<GuarantorLink />}
          />

          {/* 10.2 LL requested basic application */}
          <Route
            path="/tenant/basic/request"
            element={<BasicLLRequest />}
          />

          {/* 10.3 LL paid for premium */}
          <Route
            path="/tenant/premium/paid"
            element={
              <FlowPremiumContextProvider>
                <LLPaid />
              </FlowPremiumContextProvider>
            }
          />

          {/* 10.4 LL requests tenant pays */}
          <Route path="/tenant/premium/pay" element={<TenantPay />} />

          {/* 11 Payment */}
          <Route
            path="/premium/payment"
            element={<Payment type="tenant" />}
          />

          {/* 12.1 Premium features*/}
          <Route
            path="/tenant/premium-features"
            element={
              <FlowPremiumContextProvider>
                <TenantPremiumFeatures />
              </FlowPremiumContextProvider>
            }
          />

          {/* 12.3-1 & 12.3-2 & 12.3-3 Credit Report*/}
          <Route
            path="/tenant/credit-report"
            element={
              <ApplicantAuthRoute>
                <CreditReport />
              </ApplicantAuthRoute>
            }
          />

          {/* 12.4-1 & 12.4-2 &12.4-3 Background Check*/}
          <Route
            path="/tenant/background-check"
            element={
              <ApplicantAuthRoute>
                <BackgroundCheck />
              </ApplicantAuthRoute>
            }
          />

          {/* 12.5-1 Automated Employment & Income Verification*/}
          <Route
            path="/tenant/employment-income-verification"
            element={
              <ApplicantAuthRoute>
                <EmploymentIncomeVerification />
              </ApplicantAuthRoute>
            }
          />

          {/* 12.6 ID Verification */}
          <Route
            path="/tenant/premium/id-verification"
            element={
              <ApplicantAuthRoute>
                <IdVerification />
              </ApplicantAuthRoute>
            }
          />

          {/* 12.7-1 & 12.7-2 & 12.7-3 Tenant creates Landlord Reference*/}
          <Route
            path="/tenant/landlord-references"
            element={
              <ApplicantAuthRoute>
                <LandlordReferences />
              </ApplicantAuthRoute>
            }
          />

          {/* 12.7-4 Landlord receives Landlord Reference*/}
          <Route
            path="/verified-landlord-reference/:token"
            element={<LandlordReference />}
          />
          {/* 12.7-5 Landlord Reference Thank you */}
          <Route
            path="/verified-landlord-reference/thank-you"
            element={<LandlordReferenceThankYou />}
          />

          {/*<Route*/}
          {/*    path="/tenant/video"*/}
          {/*    element={*/}
          {/*        <PersonalVideo />*/}
          {/*    }*/}
          {/*/>*/}

          {/*<Route*/}
          {/*    path="/tenant/share"*/}
          {/*    element={<ShareResume />}*/}
          {/*/>*/}

          {/* 12 Application Preview */}
          <Route
            path="/tenant/application-preview"
            element={
              <ApplicantAuthRoute>
                <ApplicationPreview />
              </ApplicantAuthRoute>
            }
          />

          {/* 12.8-1 When a tenant clicks on "edit" within "landlord reference"  */}
          <Route
            path="/tenant/application-preview/edit"
            element={
              <ApplicantAuthRoute>
                <EditLLReference />
              </ApplicantAuthRoute>
            }
          />

          {/* 12.8-2 When a tenant clicks the edit button */}
          <Route
            path="/tenant/application-preview/edit-view"
            element={
              <ApplicantAuthRoute>
                <EditView />
              </ApplicantAuthRoute>
            }
          />

          {/* 3.2 Dashboard */}
          <Route
            path="/tenant/dashboard"
            element={
              <ApplicantAuthRoute>
                <TenantDashboard />
              </ApplicantAuthRoute>
            }
          />

          {/* 3.3 Tenant Application */}
          <Route
            path="/tenant/dashboard/application"
            element={
              <FlowPremiumContextProvider>
                <TenantApplication />
              </FlowPremiumContextProvider>
            }
          />

          {/* 3.3-3 Tenant Paywall */}
          <Route
            path="/tenant/premium-checkout"
            element={<TenantPaywall />}
          />

          <Route
            path="/landlord/dashboard"
            element={
              user?.roles?.[0]?.name === "landlord" ? (
                <LandlordDashboard />
              ) : (
                <Navigate to="/tenant/dashboard" />
              )
            }
          />

          {/* ==== LANDLORD ========================================================================== */}

          {/* 4.1 Input Address Information */}
          <Route
            path="landlord/property/create"
            element={
              <LandlordAuthRoute>
                <AddressInformation />
              </LandlordAuthRoute>
            }
          />

          {/* 5 Choose your path*/}
          <Route
            path="landlord/property/path"
            element={
              <LandlordAuthRoute>
                <ChoosePath />
              </LandlordAuthRoute>
            }
          />

          {/* 5.1-1 Application preview*/}
          <Route
            path="landlord/customize-application"
            element={
              <LandlordAuthRoute>
                <CustomizeApplication />
              </LandlordAuthRoute>
            }
          />

          {/* 5.1-2 Invite people to apply to rental*/}
          <Route
            path="landlord/application-invite"
            element={
              <LandlordAuthRoute>
                <ApplicationInvite
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                />
              </LandlordAuthRoute>
            }
          />

          {/* 5.2-1 Screen your applicant*/}
          {/* 5.3-1 Popup message */}
          {/* 5.3-2 Popup message */}
          {/* 5.4-1 Popup message */}
          {/* 5.4-2 Popup message */}
          <Route
            path="landlord/screen-applicants"
            element={
              <LandlordAuthRoute>
                <ScreenApplicants />
              </LandlordAuthRoute>
            }
          />

          {/* 5.2-2 Paywall */}
          {/* 5.1.4 Congratulations Pop Up */}
          <Route
            path="landlord/screen-applicants/payment"
            element={
              <LandlordAuthRoute>
                <Payment type="landlord" />
              </LandlordAuthRoute>
            }
          />

          {/* 6.1 Landlord Dashboard */}
          {/* 7.1 Verify Landlord Modal */}
          {/* 7.2 & 7.2-1 Verify Email Modal */}

          {/* 8.2 Application Ranking System */}
          <Route
            path="landlord/application"
            element={
              <LandlordAuthRoute>
                <Application />
              </LandlordAuthRoute>
            }
          />

          {/* 8.2-3 View Screening Request */}
          <Route
            path="landlord/application/view-screening-requests"
            element={
              <LandlordAuthRoute>
                <ViewScreeningRequests />
              </LandlordAuthRoute>
            }
          />

          {/* 8.1-2 Landlord Paywall */}
          <Route
            path="/landlord/premium-checkout"
            element={
              <LandlordAuthRoute>
                <LandlordPaywall />
              </LandlordAuthRoute>
            }
          />
          {/* PAGES ======================================================================== */}
          <Route
            path="/terms-and-conditions"
            element={<TermsConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default AppRoutes
