import "./idVerification.scss"
import FileUpload from "../../../../Components/Input/FileUpload"
import { useNavigate } from "react-router-dom"

function IdVerification() {
  const navigate = useNavigate()
  return (
    <section className="IdVerification">
      <div className="container">
      <button
            className="btn btn--back"
            onClick={() => {
              navigate(-1)
            }}
          >
            back
          </button>
        <h1>id verification</h1>
        <h2>status</h2>
        <p>incomplete</p>
        <div className="row">
          <div className="col-lg-8">
            <FileUpload
              label="upload ID"
              name="id"
            />
          </div>
        </div>

        <div className="btn-group">
          <button
            className="btn btn--primary"
            onClick={() => {
              navigate("/tenant/premium-features")
            }}
          >
            done
          </button>
        </div>
      </div>
    </section>
  )
}

export default IdVerification
