
import "./footer.scss"
import { Link } from "react-router-dom"

export default function Footer() {
    const getCurrentYear = () => {
        return new Date().getFullYear();
    };
    return (
        <footer className="Footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-auto">
                        <div className="Footer__copyright">
                            © {getCurrentYear()} Rent Panda. All rights reserved.
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <nav>
                            <Link to="/terms-and-conditions">terms of service</Link>
                            <Link to="/privacy-policy">privacy policy</Link>
                        </nav>
                    </div>
                </div>
            </div>
        </footer>
    )
}
