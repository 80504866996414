import "./confirmModal.scss"
import { useState } from "react"
import CongratulationsModal from "../CongratulationsModal/CongratulationsModal"
import axios from "axios"
import config from "../../../../config/config"
import Cookies from "universal-cookie"
function ConfirmModal({ closeModal, address }) {
  const [openCongratulationsModal, setOpenCongratulationsModal] =
    useState(false)
  const cookie = new Cookies()
  return (
    <div className="ConfirmModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2>
            are you ready to submit your application for {address}?
          </h2>

          <div className="btn-group">
            <button
              className="btn btn--primary"
              onClick={() => {
                const isFirstSubmission = JSON.parse(
                  localStorage.getItem("isNotFirstSubmission")
                )
                if (isFirstSubmission)
                  return axios
                    .post(
                      `${config.BASE_URL}api/screening-request/tenant-fulfills-items`,
                      {
                        application_id: localStorage.getItem(
                          "currentApplicationId"
                        ),
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${cookie.get(
                            "auth_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      setOpenCongratulationsModal(true)
                    })
                axios
                  .post(
                    `${
                      config.BASE_URL
                    }api/applicant/${localStorage.getItem(
                      "currentApplicantId"
                    )}/submit-applicant`,
                    {},
                    {
                      headers: {
                        Authorization: `Bearer ${cookie.get(
                          "auth_token"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    setOpenCongratulationsModal(true)
                  })
              }}
            >
              Yes
            </button>
            <button
              className="btn btn--primary"
              onClick={() => {
                closeModal(false)
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
      {openCongratulationsModal && (
        <CongratulationsModal
          closeModal={setOpenCongratulationsModal}
        />
      )}
    </div>
  )
}

export default ConfirmModal
