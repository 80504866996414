import "./editLLComponent.scss"
import Pencil from "../../../../assets/svg/pencil.svg"
import { useNavigate } from "react-router-dom"

function EditLLComponent() {
  const navigate = useNavigate()
  return (
    <div className="editLLComponent">
      <button className="btn btn--edit"
          onClick={() => {
            navigate(
                "/tenant/application-preview/edit-view"
            )
          }}
      ></button>
      <div className="row">
        <div className="col-lg-6">
          <h5>name</h5>
          <p>Michael Scott</p>
        </div>
        <div className="col-lg-6">
          <h5>email address:</h5>
          <p>
            <a href="mailto:scott.michael@dundermifflin.com">
              scott.michael@dundermifflin.com
            </a>
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <h5>lease</h5>
          <div className="info__label info__label">
            lease file
          </div>
        </div>
        <div className="col-lg-6">
          <h5>status</h5>
          <div className="info__label info__label--incomplete">
            incomplete
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditLLComponent
