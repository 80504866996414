import { useNavigate } from "react-router-dom"

function BackgroundCheckConfirmModal({
  closeModal,
}) {
  const navigate = useNavigate()
  return (
    <div className="BackgroundCheckConfirmModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2>
              your background check is being pulled.
              please check back shortly for the results. (typically takes 2-3 minutes)
          </h2>

          <div className="btn-group">
            <button
              className="btn btn--primary"
              onClick={() => {
                navigate(
                  "/tenant/premium-features"
                )
              }}
            >
              confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BackgroundCheckConfirmModal
