import Cookies from "universal-cookie"
import axios from "axios"
import config from "../../config/config"
import "./Carousel.scss"
import { useState, useEffect } from "react"


const Carousel = ({coreData, coapplicants, userId}) => {
  const [coApplicants, setCoApplicants] = useState(null)
  const cookies = new Cookies()
  const apiConfig = {
    headers: {
      Authorization: "Bearer " + cookies.get("auth_token"),
    },
  }
  const currentApplicationId = cookies.get('current_property_application_id');

  const populateCoApplicantData = (coapplicant) => {
    let currentUserId = coapplicant.id
    let applicant = false

    axios
      .post(
        `${config.BASE_URL}api/applicant/byUserIdAndApplicationId`,
        {
          application_id: currentApplicationId,
          user_id: currentUserId
        },
        apiConfig
      )
      .then((response) => {
        if (response.data.applicant !== undefined) {
          applicant = response.data.applicant;
        }
      })
      .catch((error) => {
        console.log(error)
      })

    const {
      credit_checks,
      background_checks,
      user,
      id,
      employment_income_verifications,
      id_verifications,
      pivot
    } = coapplicant
    const data = {
      credit_checks,
      background_checks,
      employment_income_verifications,
      id_verifications,
      houseSize: coapplicant.households.reduce(
        (a, v) => a + v.tenant_count,
        0
      ),
      income: coapplicant.employments.reduce(
        (a, v) => a + v.salary * (v.is_past === 0),
        0
      ),
      name: `${coapplicant.first_name} ${coapplicant?.last_name?.charAt(0) || ""}.`,
      pets: coapplicant.households.reduce(
        (a, v) => [...a, ...(JSON.parse(v.pets) || [])],
        []
      ),
      smoker: Boolean(coapplicant.smoke),
      profilePercent:
        applicant.status === "in-review" ? 100 : 50,
      top: false,
      disabled: false,
      is_favourite: applicant.is_favourite,
      id: id,
    }

    return data
  }

  useEffect(() => {
    const fullCoAppProfile = coapplicants.map((coapplicant) => {
      return populateCoApplicantData(coapplicant)
    })
    setCoApplicants(fullCoAppProfile)

    return () => {
      setCoApplicants(null)
    }
  }, [userId])

  return (
    <div className="carousel">
      <div className="carouselContainer">
        { coApplicants !== null && coApplicants.map((coapplicant) => {
          return (
            <div className="CoApplicants__applicant" key={coapplicant.id}>
              {/*
              <span className={"profileCompletionCont"}>
                profile completion
                <div className="progress-bar progress-bar--highest">
                  <div
                    className="progress-bar__value"
                    style={{ width: coapplicant.profilePercent + "%" }}
                  ></div>
                  <div className="progress-bar__label">{ coapplicant.profilePercent }%</div>
                </div>
              </span>
              */}
              <h2>{coapplicant.name}</h2>
              <span className="viewProfile">
                     view profile
                <button className="btn btn--toggle btn--toggle-inverse rotate-btn"
                        onClick={() => {
                          coreData.setPreviousUser(coreData.user_id)
                          coreData.currentUserApplication(coapplicant.id)
                        }}
                />
                   </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Carousel
