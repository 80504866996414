import React, {
  createContext,
  useEffect,
  useContext,
  useCallback,
  useState,
} from "react"

const context = createContext({})
export function MountedElementContainer(props) {
  const [items, setItems] = useState({})
  const getItem = useCallback(
    (item) => {
      return items[item]
    },
    [items]
  )
  return (
    <context.Provider
      value={{
        set(name, value) {
          setItems((items) => ({
            ...items,
            [name]: value,
          }))
        },
        get: getItem,
        delete(name) {
          setItems((items) => {
            let v = {
              ...items,
            }
            delete v[name]
            return v
          })
        },
        has(name) {
          return items[name] !== undefined
        },
      }}
    >
      {props.children}
    </context.Provider>
  )
}

export const MountedSlot = new Proxy(
  function MountedSlot(name, props) {
    const ctx = useContext(context)
    const child = ctx.get(name)
    return child || null
  },
  {
    apply() {
      throw new Error(
        "NameableMountedChildrenTarget can only be called by accessing a property"
      )
    },
    get(target, p) {
      if (p === "prototype") return target[p]
      return target.bind(target, p)
    },
  }
)
export const MountedProvider = new Proxy(
  function MountedProvider(name, props) {
    const ctx = useContext(context)
    useEffect(() => {
      ctx.set(name, props.children)
      return () => ctx.delete(name)
      // DO NOT FIX THIS LINTING ISSUE, FIXING IT WILL CAUSE INFINITE RERENDERING
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.children])
    return null
  },
  {
    apply() {
      throw new Error(
        "NameableMountedChildrenProvider can only be called by accessing a property"
      )
    },
    get(target, p) {
      if (p === "prototype") return target[p]
      let x = target.bind(target, p)
      //   x.name = `MountedProvider.${p}`
      return x
    },
  }
)
export const HasSlotData = new Proxy(
  function HasSlotData(name, { children }) {
    const ctx = useContext(context)
    const hasChild = ctx.has(name)
    return hasChild && children
  },
  {
    apply() {
      throw new Error(
        "HasSlotData can only be called by accessing a property"
      )
    },
    get(target, p) {
      if (p === "prototype") return target[p]
      let x = target.bind(target, p)
      return x
    },
  }
)
