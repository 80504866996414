import "./tenantApplication.scss"
import HousingInfo from "../../../../Components/ApplicationPreview/HousingInfo/HousingInfo"
import EmploymentInfo from "../../../../Components/ApplicationPreview/EmploymentInfo/EmploymentInfo"
import HouseholdInfo from "../../../../Components/ApplicationPreview/HouseholdInfo/HouseholdInfo"
import CreditCheck from "../../../../Components/ApplicationPreview/CreditCheck/CreditCheck"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import Cookies from "universal-cookie"
import config from "../../../../config/config"
function TenantApplication() {
  const navigate = useNavigate()
  const handleNavBack = () => {
    navigate("/tenant/dashboard")
  }
  const [{ applicant }, setData] = useState({})
  useEffect(() => {
    const cookie = new Cookies()
    axios
      .get(
        `${config.BASE_URL}api/applicant/${localStorage.getItem(
          "currentApplicantId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${cookie.get("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setData(res.data)
      })
  }, [])

  return (
    <section className="TenantApplication">
      <div className="container">
        <button className="btn btn--back" onClick={handleNavBack}>
          back
        </button>
        <div className="row justify-content-between">
          <div className="col-lg-auto">
            <h1>{applicant?.application.address}</h1>
          </div>
        </div>
        <h2>your application</h2>

        <div className="row justify-content-between">
          <div className="col-lg-6">
            <h5 className="status">Status: {applicant?.status}</h5>
          </div>
        </div>

        <div className="TenantApplication__container">
          {applicant?.housings?.map((house) => {
            return (
              <HousingInfo
                display={house}
                key={house.id}
                type={house.type}
                editable="true"
              />
            )
          })}
          {applicant?.employments?.map((employment) => {
            return (
              <EmploymentInfo
                display={employment}
                key={employment.id}
              />
            )
          })}
          {applicant?.households?.map((household) => {
            return (
              <HouseholdInfo display={household} key={household.id} />
            )
          })}
          {applicant?.credit_checks?.map((credit_check) => {
            return (
              <CreditCheck
                type="tenant"
                data={JSON.parse(credit_check.certn_data)}
                key={credit_check.id}
              />
            )
          })}
        </div>
        <div className="col-lg-auto">
          <button className="btn btn--danger">delete</button>
        </div>
      </div>
    </section>
  )
}

export default TenantApplication
