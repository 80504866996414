import { useNavigate } from "react-router-dom"

function CongratulationsModal({ closeModal }) {
  const navigate = useNavigate()
  return (
    <div className="CongratulationsModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2>
            congratulations! you’ve successfully
            submitted your application{" "}
          </h2>

          <div className="btn-group">
            <button
              className="btn btn--primary"
              onClick={() => {
                navigate("/tenant/dashboard")
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CongratulationsModal
