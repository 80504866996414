import "./resetPassword.scss"
import { useState } from "react"
import config from "../../config/config"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import Cookies from "universal-cookie"
import Helmet from "react-helmet"
import PWDRequisite from "./PWDRequisite"
import { motion } from "framer-motion"

function ResetPassword() {
  // REACT-ROUTER

  const params = useParams()
  const navigate = useNavigate()

  // STATE
  const [token, setToken] = useState(params.token)
  const [email, setEmail] = useState(params.email)
  const [password, setPassword] = useState("")
  const [pwdRequisite, setPwdRequisite] = useState(false)
  const [checks, setChecks] = useState({
    capsLetterCheck: false,
    numberCheck: false,
    pwdLengthCheck: false,
    specialCharCheck: false,
  })
  const [passwordConfirm, setPasswordConfirm] = useState(false)
  const [focused, setFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  //  ERROR MESSAGES

  const PasswordErrMsg = "Do better"
  const ErrorMsg = "Must be a valid email address!"
  const LoadingMsg = "Hang on a second..."
  const SuccessMsg = "You've successfully changed your password!"
  const passwordConfirmErr = "The passwords don't match!"

  // API

  const cookies = new Cookies()

  // FUNCTIONS
  const handleOnFocus = (e) => {
    setPwdRequisite(true)
  }

  const handleOnBlur = (e) => {
    setPwdRequisite(false)
  }

  const handleConfirmFocus = (e) => {
    setFocused(true)
  }

  const handleOnKeyUp = (e) => {
    const { value } = e.target
    const capsLetterCheck = /[A-Z]/.test(value)
    const numberCheck = /[0-9]/.test(value)
    const pwdLengthCheck = value.length >= 8
    const specialCharCheck = /[!@#$%^&*]/.test(value)
    setChecks({
      capsLetterCheck,
      numberCheck,
      pwdLengthCheck,
      specialCharCheck,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      const res = await axios.post(
        `${config.BASE_URL}api/reset-password`,
        {
          token,
          email,
          password,
          password_confirm: passwordConfirm,
        }
      )
      if (res.status == 200) {
        setIsLoading(false)
        setSuccess(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="resetPassword">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Smart Screening - Forgot Password | Rent Panda</title>
        <meta
          name="description"
          content="Let's reset your password for Rent Panda Smart Screening."
        />
      </Helmet>
      <section className="ResetPasswordHeader">
        <div className="container">
          <button
            className="btn btn--back"
            onClick={() => navigate(-1)}
          >
            back
          </button>
        </div>
      </section>
      <section className="ResetPasswordInfo">
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="ResetPassword__container">
              <div className="row">
                <div className="col-lg-4">
                  <label htmlFor="newPassword">new password</label>
                  <input
                    className="form_group"
                    type="password"
                    label="password address"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onKeyUp={handleOnKeyUp}
                  />
                  {pwdRequisite ? (
                    <PWDRequisite
                      capsLetterFlag={
                        checks.capsLetterCheck ? "valid" : "invalid"
                      }
                      numberFlag={
                        checks.numberCheck ? "valid" : "invalid"
                      }
                      specialCharFlag={
                        checks.specialCharCheck ? "valid" : "invalid"
                      }
                      pwdLengthFlag={
                        checks.pwdLengthCheck ? "valid" : "invalid"
                      }
                    />
                  ) : null}
                  <label htmlFor="confirmPassword">
                    confirm Password
                  </label>
                  <input
                    className="form_group"
                    type="password"
                    label="password address"
                    name="password"
                    onChange={(e) =>
                      setPasswordConfirm(e.target.value)
                    }
                    pattern={password}
                    required
                    focused={focused.toString()}
                    onBlur={handleConfirmFocus}
                  />
                  <motion.p
                    initial={{ x: 200, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 1 }}
                    className="errorMessage"
                  >
                    {passwordConfirmErr}
                  </motion.p>
                  {success && (
                    <motion.span
                      initial={{ x: -"100vh", opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0, duration: 1.5 }}
                      className="loadingMessage success"
                    >
                      {SuccessMsg}
                    </motion.span>
                  )}
                  {isLoading && (
                    <motion.span
                      initial={{ y: -"100vw", opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0, duration: 0.3 }}
                      className="loadingMessage"
                    >
                      {LoadingMsg}
                    </motion.span>
                  )}
                  <div className="btn-group">
                    {success ? null : (
                      <button
                        className="btn btn--primary btn--small"
                        type="submit"
                      >
                        submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  )
}

export default ResetPassword
