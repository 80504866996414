import { useEffect, useState } from "react"
function NOOP() {}
export const DelayRenderingUntilCondition = ({
  condition,
  pollRate = 1000,
  children,
}) => {
  const [isRendered, setIsRendered] = useState(false)
  useEffect(() => {
    if (!isRendered) {
      const id = setInterval(() => {
        if (condition()) {
          setIsRendered(true)
          clearInterval(id)
        }
      }, pollRate)
      return () => clearInterval(id)
    }
    return NOOP
  }, [condition, isRendered, pollRate])

  return isRendered ? children : null
}
