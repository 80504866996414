import Helmet from "react-helmet"
import "./privacyPolicy.scss"

function PrivacyPolicy() {
  return (
    <section className="PrivacyPolicy">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Smart Screening - Privacy Policy | Rent Panda</title>
        <meta
          name="description"
          content="Please read and understand our Rent Panda Smart Screening Privacy Policy."
        />
      </Helmet>
      <div className="container">
        <h1>privacy policy</h1>

        <h2>INTRODUCTION</h2>

        <p>
          Rent Panda Inc. ("Company" or "We") respects your privacy
          and is committed to protecting it by complying with this
          policy.
        </p>

        <p>This policy describes:</p>
        <ul>
          <li>
            How we collect, use, disclose, and protect the personal
            information of our customers and website users ("you").
          </li>
          <li>
            Describes the types of information we may collect from you
            or that you may provide when you visit the website
            www.rentpanda.ca (our "Website").
          </li>
          <li>
            Our practices for collecting, using, maintaining,
            protecting, and disclosing that information.
          </li>
        </ul>
        <p>
          We will only use your personal information in accordance
          with this policy unless otherwise required by applicable
          law. We take steps to ensure that the personal information
          that we collect about you is adequate, relevant, not
          excessive, and used for limited purposes.
        </p>

        <p>
          Privacy laws in Canada generally define "personal
          information" as any information about an identifiable
          individual, which includes information that can be used on
          its own or with other information to identify, contact, or
          locate a single person.
        </p>

        <p>
          This policy applies to information we collect, use, or
          disclose about you:
        </p>
        <ul>
          <li>On this Website.</li>
          <li>
            In email, text, and other electronic messages between you
            and this Website.
          </li>
        </ul>
        <p>
          The Website may include links to third-party websites,
          plug-ins, services, social networks, or applications.
          Clicking on those links or enabling those connections may
          allow the third party to collect or share data about you. If
          you follow a link to a third-party website or engage a
          third-party plugin, please note that these third parties
          have their own privacy policies and we do not accept any
          responsibility or liability for these policies. We do not
          control these third-party websites, and we encourage you to
          read the privacy policy of every website you visit.
        </p>

        <p>
          Please read this policy carefully to understand our policies
          and practices for collecting, processing, and storing your
          information. If you do not agree with our policies and
          practices, your choice is not to use our Website. By
          accessing or using this Website, you indicate that you
          understand, accept, and consent to the practices described
          in this policy. This policy may change from time to time
          (see{" "}
          <a href="https://rentpanda.ca/privacy-policy#ChangestoOurPrivacyPolicy">
            Changes to Our Privacy Policy
          </a>
          ).
        </p>

        <h4>INFORMATION WE COLLECT ABOUT YOU</h4>

        <p>
          We collect and use several types of information from and
          about you, including:
        </p>
        <ul>
          <li>
            Personal information, that we can reasonably use to
            directly or indirectly identify you, such as your name,
            mailing address, e-mail address, telephone number,
            Internet protocol (IP) address used to connect your
            computer to the Internet, user name or other similar
            identifier, any and all information you input or upload
            onto the Website (including through your user account
            (“account”) such as personal information contained in your
            history of residence, work history, pet information,
            vehicle information, income information, lease
            agreement(s), move-in and out inspections, credit score
            and credit history, criminal background, bank account
            information, video submission, ID information, any notices
            you issue or are issued to you, any viewing requests or
            viewings scheduled, or any messages you send to another
            user, and any other identifier we may use to contact you
            ("personal information").
          </li>
          <li>
            We provide an opportunity for any user to unsubscribe or
            opt-out of contact for marketing purposes on an ongoing
            basis by using the unsubscribe mechanism at the bottom of
            our e-mails. If you wish to have your account permanently
            deleted from our system, please email hello@rentpanda.ca
            and your account and associated personal information will
            be permanently deleted within 10 days of notice.
          </li>
          <li>
            Non-personal information, that does not directly or
            indirectly reveal your identity or directly relate to an
            identifiable individual, such as demographic information,
            or statistical or aggregated information. Statistical or
            aggregated data does not directly identify a specific
            person, but we may derive non-personal statistical or
            aggregated data from personal information. For example, we
            may aggregate personal information to calculate the
            percentage of users accessing a specific Website feature.
          </li>
          <li>
            Technical information, including your login information,
            browser type and version, time zone setting, browser
            plug-in types and versions, operating system and platform,
            or information about your internet connection, the
            equipment you use to access our Website, and usage
            details.
          </li>
          <li>
            Non-personal details about your Website interactions,
            including the full Uniform Resource Locators (URLs),
            clickstream to, through and from our Website (including
            date and time), products you viewed or searched for, page
            response times, download errors, length of visits to
            certain pages, page interaction information (such as
            scrolling, clicks, and mouse-overs), methods used to
            browse away from the page, or any phone number used to
            call our customer service number.
          </li>
        </ul>
        <h4>HOW WE COLLECT INFORMATION ABOUT YOU</h4>

        <p>
          We use different methods to collect your information,
          including through:
        </p>
        <ul>
          <li>
            Direct interactions with you when you provide it to us,
            for example, by filling in forms or corresponding with us
            by phone, email, or otherwise.
          </li>
          <li>
            User contributions. You may also provide information for
            us to publish or display on public Website areas or
            transmit to other Website users or third parties.
          </li>
          <li>
            References. Other users of the Website who have engaged
            with you may disclose information about their experience
            and interactions with you.
          </li>
          <li>
            Automated technologies or interactions, as you navigate
            through our Website. Information collected automatically
            may include usage details, IP addresses, and information
            collected through cookies.
          </li>
          <li>
            Third parties or publicly available sources, for example,
            our business partners.
          </li>
          <li>
            OTHER MEANS OF COLLECTING PERSONAL INFORMATION OR
            INFORMATION GENERALLY.
          </li>
        </ul>
        <h4>Information You Provide to Us</h4>

        <p>
          The information we collect directly from you on or through
          our Website may include:
        </p>
        <ul>
          <li>
            Information that you provide by filling in forms on our
            Website. This includes information provided at the time of
            creating your account, posting material, completing
            application forms, credit, criminal or bank account
            checks, adding information to your account, inputting
            information in lease agreements, notices, viewing
            schedulers or repair requests, or requesting further
            services. We may also ask you for information when you
            report a problem with our Website or write a review.
          </li>
          <li>
            Records and copies of your correspondence (including email
            addresses), if you contact us.
          </li>
          <li>
            Details of transactions you carry out through our Website
            and of the fulfillment of your agreements with other
            users.
          </li>
          <li>Your search queries on the Website.</li>
          <li>OTHER TYPES OF INFORMATION THE USER PROVIDES.</li>
        </ul>
        <p>
          You may also provide information to be published or
          displayed (hereinafter, "posted") on public areas of the
          Website or transmitted to other users of the Website or
          third parties (collectively, "User Contributions"). Your
          User Contributions are posted on and transmitted to others
          at your own risk. Although we limit access to certain pages,
          please be aware that no security measures are perfect.
          Additionally, we cannot control the actions of other users
          of the Website with whom you may choose to share your User
          Contributions. Therefore, we cannot and do not guarantee
          that unauthorized persons will not view your User
          Contributions.
        </p>

        <h4>
          Information We Collect Through Cookies and Other Automatic
          Data Collection Technologies
        </h4>

        <p>
          As you navigate through and interact with our Website, we
          may use cookies or other automatic data collection
          technologies to collect certain information about your
          equipment, browsing actions, and patterns, including:
        </p>
        <ul>
          <li>
            Details of your visits to our Website and other
            communication data and the resources that you access and
            use on the Website.
          </li>
          <li>
            Information about your computer and internet connection,
            including your IP address, operating system, and browser
            type.
          </li>
        </ul>
        <p>
          The information we collect automatically is statistical
          information that includes personal information, and we may
          maintain it or associate it with personal information we
          collect in other ways or receive from third parties. It
          helps us to improve our Website and to deliver a better and
          more personalized service, including by enabling us to:
        </p>
        <ul>
          <li>Estimate our audience size and usage patterns.</li>
          <li>
            Store information about your preferences, allowing us to
            customize our Website according to your individual
            interests.
          </li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you return to our Website.</li>
        </ul>
        <p>
          The technologies we use for this automatic data collection
          may include:
        </p>
        <ul>
          <li>
            Cookies (or browser cookies). A cookie is a small file
            placed on the hard drive of your computer. You may refuse
            to accept browser cookies by activating the appropriate
            setting on your browser. However, if you select this
            setting you may be unable to access certain parts of our
            Website. Unless you have adjusted your browser setting so
            that it will refuse cookies, our system will issue cookies
            when you direct your browser to our Website.
          </li>
          <li>
            Flash Cookies. Certain features of our Website may use
            local stored objects (or Flash cookies) to collect and
            store information about your preferences and navigation
            to, from, and on our Website. Flash cookies are not
            managed by the same browser settings that are used for
            browser cookies..
          </li>
          <li>
            Web Beacons. Pages of our Website and our e-mails may
            contain small electronic files known as web beacons (also
            referred to as clear gifs, pixel tags, and single-pixel
            gifs) that permit the Company, for example, to count users
            who have visited those pages or opened an email and for
            other related website statistics (for example, recording
            the popularity of certain website content and verifying
            system and server integrity).
          </li>
        </ul>
        <h4>
          Third Party Use of Cookies and Other Tracking Technologies
        </h4>

        <p>
          Some content or applications on the Website, including
          advertisements, are served by third-parties, including
          advertisers, ad networks and servers, content providers, and
          application providers. These third parties may use cookies
          alone or in conjunction with web beacons or other tracking
          technologies to collect information about you when you use
          our Website. The information they collect may be associated
          with your personal information or they may collect
          information, including personal information, about your
          online activities over time and across different websites
          and other online services. They may use this information to
          provide you with interest-based (behavioural) advertising or
          other targeted content. In addition to helping advertisers
          reach the right people for their products and services,
          behavioural advertising helps support our Website so that
          you can enjoy free content.
        </p>

        <p>
          You can opt-out of several third party ad servers' and
          networks' cookies simultaneously by using an opt-out tool
          created by the Digital Advertising Alliance of Canada. You
          can also access these websites to learn more about online
          behavioural advertising and how to stop websites from
          placing cookies on your device. Opting out of a network does
          not mean you will no longer receive online advertising. It
          does mean that the network from which you opted out will no
          longer deliver ads tailored to your web preferences and
          usage patterns.
        </p>

        <p>
          We do not control these third parties' tracking technologies
          or how they are used. If you have any questions about an
          advertisement or other targeted content, you should contact
          the responsible provider directly.
        </p>

        <h4>HOW WE USE YOUR INFORMATION</h4>

        <p>
          We use information that we collect about you or that you
          provide to us, including any personal information:
        </p>
        <ul>
          <li>To present our Website and its contents to you.</li>
          <li>
            To provide you with information, products, or services
            that you request from us.
          </li>
          <li>
            To fulfill the purposes for which you provided the
            information or that were described when it was collected,
            or any other purpose for which you provide it.
          </li>
          <li>
            To provide you with notices about your account, including
            expiration and renewal notices.
          </li>
          <li>
            To carry out our obligations and enforce our rights
            arising from any contracts with you, including for billing
            and collection or to comply with legal requirements.
          </li>
          <li>
            To notify you about changes to our Website or any products
            or services we offer or provide though it.
          </li>
          <li>
            To improve our Website, products or services, marketing,
            or customer relationships and experiences.
          </li>
          <li>
            To allow you to participate in interactive features,
            social media, or similar features on our Website.
          </li>
          <li>
            To measure or understand the effectiveness of the
            advertising we serve to you and others, and to deliver
            relevant advertising to you.
          </li>
          <li>
            In any other way we may describe when you provide the
            information.
          </li>
          <li>
            To create personalized products and/or pricing for you or
            to create classes of products and/or pricing for those
            exhibiting similar or dissimilar characteristics or user
            behaviours to you
          </li>
          <li>
            To analyze trends, patterns or correlations between user
            behaviours and/or identifiers and to create and/or market
            products to unique user groups based on that data
          </li>
          <li>
            To sell personal or non-personal data to entities which
            use that information to create or modify products and/or
            pricing which can then be marketed to you or others
          </li>
          <li>For any other purpose with your consent.</li>
        </ul>
        <p>
          We may also use your information to contact you about our
          own and third-parties' services that may be of interest to
          you, as permitted by law.
        </p>

        <p>
          We may use the information we have collected from you to
          enable us to display advertisements to our advertisers'
          target audiences. Even though we do not disclose your
          personal information for these purposes without your
          consent, if you click on or otherwise interact with an
          advertisement, the advertiser may assume that you meet its
          target criteria.
        </p>

        <h4>DISCLOSURE OF YOUR INFORMATION</h4>

        <p>
          We may disclose personal information that we collect or you
          provide as described in this privacy policy:
        </p>
        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>
            In accordance with applicable law, to a buyer or other
            successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale
            or transfer of some or all of Company's assets, whether as
            a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which personal information held by
            Company about our customers and users is among the assets
            transferred.
          </li>
          <li>
            To advertisers and advertising networks that require the
            information to select and serve relevant advertisements to
            you and others. We may also use such aggregate information
            to help advertisers target a specific audience (for
            example, men in a specific location). We may make use of
            the personal information we have collected from you to
            enable us to display our advertisers' advertisement to
            that target audience.
          </li>
          <li>
            To third parties to create and/or market their products or
            services to you, provided you have consented to this type
            of disclosure. We contractually require these third
            parties to keep personal information confidential and use
            it only for the purposes for which we disclose it to them.
          </li>
          <li>
            To contractors, service providers, and other third parties
            we use to support our business (such as analytics and
            search engine providers that assist us with Website
            improvement and optimization) and who are contractually
            obligated to keep personal information confidential, use
            it only for the purposes for which we disclose it to them,
            and to process the personal information with the same
            standards set out in this policy.
          </li>
          <li>
            To fulfill the purpose for which you provide it. For
            example, if you give us an email address to use the "email
            a friend" feature of our Website, we will transmit the
            contents of that email and your email address to the
            recipients.
          </li>
          <li>
            For any other purpose disclosed by us when you provide the
            information.
          </li>
          <li>With your consent.</li>
        </ul>
        <p>We may also disclose your personal information:</p>
        <ul>
          <li>
            To comply with any court order, law, or legal process,
            including to respond to any government or regulatory
            request, in accordance with applicable law.
          </li>
          <li>
            To enforce or apply our{" "}
            <a href="https://rentpanda.ca/terms-and-conditions">
              terms of use
            </a>{" "}
            and other agreements, including for billing and collection
            purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to
            protect the rights, property, or safety of Company, our
            customers, or others. This includes exchanging information
            with other companies and organizations for the purposes of
            fraud protection and credit risk reduction.
          </li>
        </ul>
        <h4>TRANSFERRING YOUR PERSONAL INFORMATION</h4>

        <p>
          We may transfer personal information that we collect or that
          you provide as described in this policy to contractors,
          service providers, and other third parties we use to support
          our business (such as analytics and search engine providers
          that assist us with Website improvement and optimization)
          and who are contractually obligated to keep personal
          information confidential, use it only for the purposes for
          which we disclose it to them, and to process the personal
          information with the same standards set out in this policy.
        </p>

        <p>
          We may process, store, and transfer your personal
          information in and to a foreign country, with different
          privacy laws that may or may not be as comprehensive as
          Canadian law. In these circumstances, the governments,
          courts, law enforcement, or regulatory agencies of that
          country may be able to obtain access to your personal
          information through the laws of the foreign country.
          Whenever we engage a service provider, we require that its
          privacy and security standards adhere to this policy and
          applicable Canadian privacy legislation.
        </p>
        <p></p>
        <p>
          By submitting your personal information or engaging with the
          Website, you consent to this transfer, storage, or
          processing.
        </p>

        <h4>
          CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION
        </h4>

        <p>
          We strive to provide you with choices regarding the personal
          information you provide to us. We have created mechanisms to
          provide you with the following control over your
          information:
        </p>

        <p>
          Tracking Technologies and Advertising. You can set your
          browser to refuse all or some browser cookies, or to alert
          you when cookies are being sent. To learn how you can manage
          your Flash cookie settings, visit the Flash player settings
          page on Adobe's website. If you disable or refuse cookies,
          please note that some parts of this Website may not be
          accessible or may not function properly.
        </p>

        <p>
          We do not control third parties' collection or use of your
          information to serve interest-based advertising. However,
          these third parties may provide you with ways to choose not
          to have your information collected or used in this way. You
          can opt out of several third party ad servers' and networks'
          cookies simultaneously by using an opt-out tool. You can
          also access these websites to learn more about online
          behavioural advertising and how to stop websites from
          placing cookies on your device. Opting out of a network does
          not mean you will no longer receive online advertising. It
          does mean that the network from which you opted out will no
          longer deliver ads tailored to your web preferences and
          usage patterns.
        </p>

        <h4>DATA SECURITY</h4>

        <p>
          The security of your personal information is very important
          to us. We use physical, electronic, and administrative
          measures designed to secure your personal information from
          accidental loss and from unauthorized access, use,
          alteration, and disclosure. We store all information you
          provide to us behind firewalls on our secure servers.
        </p>

        <p>
          The safety and security of your information also depends on
          you. Where we have given you (or where you have chosen) a
          password for access to certain parts of our Website, you are
          responsible for keeping this password confidential. We ask
          you not to share your password with anyone. We urge you to
          be careful about giving out information in public areas of
          the Website like message boards, which any Website visitor
          can view.
        </p>

        <p>
          Unfortunately, the transmission of information via the
          Internet is not completely secure. Although we do our best
          to protect your personal information, we cannot guarantee
          the security of your personal information transmitted to our
          Website. Any transmission of personal information is at your
          own risk. We are not responsible for circumvention of any
          privacy settings or security measures contained on the
          Website.
        </p>

        <h4>DATA RETENTION</h4>

        <p>
          Except as otherwise permitted or required by applicable law
          or regulation, we will only retain your personal information
          for as long as necessary to fulfill the purposes we
          collected it for, including for the purposes of satisfying
          any legal, accounting, or reporting requirements.
        </p>

        <p>
          Under some circumstances we may anonymize your personal
          information so that it can no longer be associated with you.
          We reserve the right to use such anonymous and de-identified
          data for any legitimate business purpose without further
          notice to you or your consent.
        </p>

        <h4>ACCESSING AND CORRECTING YOUR PERSONAL INFORMATION</h4>

        <p>
          It is important that the personal information we hold about
          you is accurate and current. Please keep us informed if your
          personal information changes. By law you have the right to
          request access to and to correct the personal information
          that we hold about you.
        </p>

        <p>
          You can review and change your personal information by
          logging into account through the Website.
        </p>

        <p>
          We may request specific information from you to help us
          confirm your identity and your right to access, and to
          provide you with the personal information that we hold about
          you or make your requested changes. Applicable law may allow
          or require us to refuse to provide you with access to some
          or all of the personal information that we hold about you,
          or we may have destroyed, erased, or made your personal
          information anonymous in accordance with our record
          retention obligations and practices. If we cannot provide
          you with access to your personal information, we may inform
          you of the reasons why, subject to any legal or regulatory
          restrictions.
        </p>

        <p>
          If you delete your User Contributions from the Website,
          copies of your User Contributions may remain viewable in
          cached and archived pages or might have been copied or
          stored by other Website users. Proper access and use of
          information provided on the Website, including User
          Contributions, is governed by our{" "}
          <a href="https://rentpanda.ca/terms-and-conditions">
            terms of use
          </a>
          .
        </p>

        <h4>WITHDRAWING YOUR CONSENT</h4>

        <p>
          Where you have provided your consent to the collection, use,
          and transfer of your personal information, you may have the
          legal right to withdraw your consent under certain
          circumstances. To withdraw your consent, if applicable,
          contact us at hello@rentpanda.ca. Please note that if you
          withdraw your consent we may not be able to provide you with
          a particular product or service. We will explain the impact
          to you at the time to help you with your decision.
        </p>

        <h4>CHANGES TO OUR PRIVACY POLICY</h4>

        <p>
          It is our policy to post any changes we make to our privacy
          policy on this page with a notice that the privacy policy
          has been updated on the Website home page. If we make
          material changes to how we treat our users' personal
          information, we will notify you.
        </p>

        <p>
          We include the date the privacy policy was last revised at
          the top of the page. You are responsible for ensuring we
          have an up-to-date, active, and deliverable email address
          for you, and for periodically visiting our Website and this
          privacy policy to check for any changes.
        </p>

        <h4>CONTACT INFORMATION AND CHALLENGING COMPLIANCE</h4>

        <p>
          We welcome your questions, comments, and requests regarding
          this privacy policy and our privacy practices. Please
          contact us by sending an email to hello@rentpanda.ca.
        </p>
      </div>
    </section>
  )
}

export default PrivacyPolicy
