import "./tenantPaywall.scss"
import CheckboxInput from "../../../../Components/Input/CheckboxInput";

function TenantPaywall() {
    return (
        <section className="TenantPaywall">
            <div className="container">
                <div className="row">

                    {/* Payment form */}
                    <div className="col-xl-8 col-lg-7">
                        <h2>set yourself apart from other tenants and get ahead of the pack with some of the advanced tools</h2>

                        <div className="row">
                            <div className="col-xl-6">
                                <CheckboxInput
                                    label="credit check"
                                    name="credit_check"
                                >
                                    industry standard credit checks are a secure and trusted way to back up your application with financial info.
                                </CheckboxInput>
                            </div>
                            <div className="col-xl-6">
                                <CheckboxInput
                                    label="id verification"
                                    name="id_verification"
                                >
                                    let the landlord know you are a real person, so they aren’t worried about any scams.
                                </CheckboxInput>
                            </div>
                            <div className="col-xl-6">
                                <CheckboxInput
                                    label="background check"
                                    name="background_check"
                                >
                                    get an instant background check, so you and the landlord dont have to go searching.
                                </CheckboxInput>
                            </div>
                            <div className="col-xl-6">
                                <CheckboxInput
                                    label="verified landlord references"
                                    name="verified_landlord_references"
                                >
                                    get your old landlords to leave you a reference, so no one has to bother with old school calls.
                                </CheckboxInput>
                            </div>
                            <div className="col-xl-6">
                                <CheckboxInput
                                    label="personalized video"
                                    name="personalized_video"
                                >
                                    introduce yourself to your potential landlord, so they can get to know you quickly and easily.
                                </CheckboxInput>
                            </div>
                            <div className="col-xl-6">
                                <CheckboxInput
                                    label="income & expenses verification"
                                    name="employment_income_verification"
                                >
                                    securely connect with your bank, to verify your income and rent payments so you dont have to pull paystubs or letters of employment anymore.
                                </CheckboxInput>
                            </div>
                        </div>

                    </div>

                    {/* Order Summary */}
                    <div className="col-xl-4 col-lg-5">
                        <div className="order-summary">
                            <div className="order-summary__details">
                                <h2>Order Summary</h2>
                                <div className="order-summary__order-item">
                                    <span>credit check</span>
                                    <span>$1.00</span>
                                </div>
                                <div className="order-summary__order-item">
                                    <span>background check</span>
                                    <span>$1.00</span>
                                </div>
                                <div className="order-summary__order-item">
                                    <span>personalized video</span>
                                    <span>$1.00</span>
                                </div>
                                <div className="order-summary__order-item">
                                    <span>id verification</span>
                                    <span>$1.00</span>
                                </div>
                                <div className="order-summary__order-item">
                                    <span>verified landlord references</span>
                                    <span>$1.00</span>
                                </div>
                                <div className="order-summary__order-item">
                                    <span>automated employment & income verification</span>
                                    <span>$1.00</span>
                                </div>
                                <div className="order-summary__order-item">
                                    <span>HST</span>
                                    <span>$3.00</span>
                                </div>
                                <div className="order-summary__order-item mt-1">
                                    <span>Coupon/ Referral discount</span>
                                    <span>-$5.00</span>
                                </div>
                            </div>
                            <div className="order-summary__total">
                                <h2>total amount</h2>
                                <h2>$4.00</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-group">
                    <button className="btn btn--back">back</button>
                    <button className="btn btn--primary">proceed to payment</button>
                </div>
            </div>
        </section>
    );
}

export default TenantPaywall
