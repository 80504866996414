import { useRandomId } from "Hooks/useRandomId"

/**
 *
 * @param {{
 * value:any
 * label?:string
 * name?:string
 * placeholder?:string
 * type:string
 * inline:boolean
 * onChange?:(e:import("react").ChangeEvent<HTMLInputElement>)=>any
 * onBlur?:(e:Event)=>any
 * required?:boolean
 * hasError?:boolean
 * }} param0
 * @returns
 */
const TextInput = ({
  value,
  label,
  name,
  placeholder,
  type,
  inline,
  onChange,
  onBlur,
  required,
  hasError,
}) => {
  // using a random id to avoid conflicts with other inputs on the page
  // since we are using react there should be no reason to have to reference the id programmatically
  const id = useRandomId()
  return (
    <div className={inline ? "form-group-inline" : "form-group"}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type={type}
        value={value}
        name={name}
        id={label ? id : undefined}
        className={[
          inline ? "inline-input" : "form-group__form-control",
          hasError ? "input-error-border" : null,
        ]
          .filter(Boolean)
          .join(" ")}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
      />
      {/*  error message here  */}
    </div>
  )
}

export default TextInput
