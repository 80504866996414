import { useState } from "react"
import "./householdInfo.scss"
import Trash from "../../../assets/svg/trash.svg"
import { useEffect } from "react"

const PetInfo = (props) => {
  const [selected, setSelected] = useState(props.type)
  const [size, setSize] = useState(props.size)
  const { id } = props
  useEffect(() => {
    props.onChange?.({ type: selected, size, id })

    // DO NOT listen to this, it will cause a recursive rerender.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, size, id])
  return (
    <>
      <div className="HouseholdInfo__pet">
        <h4>pet info</h4>
        <button
          className="btn trash"
          onClick={() => props.removePet(props.id)}
        >
          <img src={Trash} alt="remove pet" />
        </button>
        <div className="row">
          <div className="col-auto">
            <div className="form-group">
              <label>what type?</label>
              <div className="form-choose-group">
                <button
                  id="cat"
                  className={
                    selected == "cat"
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  onClick={(e) => {
                    setSelected(e.target.id)
                  }}
                >
                  cat
                </button>
                <button
                  id="dog"
                  className={
                    selected == "dog"
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  onClick={(e) => {
                    setSelected(e.target.id)
                  }}
                >
                  dog
                </button>
                <button
                  id="other"
                  className={
                    selected == "other"
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  onClick={(e) => {
                    setSelected(e.target.id)
                  }}
                >
                  other
                </button>
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="form-group">
              <label>what size?</label>
              <div className="form-choose-group">
                <button
                  id="small"
                  className={
                    size == "small"
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  onClick={(e) => {
                    setSize(e.target.id)
                  }}
                >
                  small
                </button>
                <button
                  id="medium"
                  className={
                    size == "medium"
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  onClick={(e) => {
                    setSize(e.target.id)
                  }}
                >
                  medium
                </button>
                <button
                  id="large"
                  className={
                    size == "large"
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  onClick={(e) => {
                    setSize(e.target.id)
                  }}
                >
                  large
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PetInfo
