function TenantPayRejectModal({closeModal}) {

    return (
        <div className="TenantPayRejectModal modal">
            <div className="modal__overlay"></div>
            <div className="modal__wrapper">

                <div className="modal__container">

                    <h2>Are you sure you want to reject?</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab deserunt dicta dolorum est harum hic
                        inventore! Aliquam consequatur eius eligendi, facilis minima qui quisquam reiciendis, repellat
                        rerum, similique tempore voluptates.</p>

                    <div className="btn-group">
                        <button
                            className="btn btn--back"
                            onClick={() => {
                                closeModal(true);
                            }}
                        >
                            back
                        </button>
                        <button
                            className="btn btn--primary"
                            onClick={() => {
                                closeModal(true);
                            }}
                        >
                            yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TenantPayRejectModal;
