import "./tenantLanding.scss"
import "./InviteContactReminder/InviteContactReminder.scss"
import "./AlreadyAppliedModal/AlreadyAppliedModal.scss"
import check from "../../../assets/svg/Tenant Landing/check.svg"
import employment from "../../../assets/svg/Tenant Landing/employment.svg"
import fax from "../../../assets/svg/Tenant Landing/fax.svg"
import home from "../../../assets/svg/Tenant Landing/home.svg"
import house from "../../../assets/svg/Tenant Landing/house.svg"
import id from "../../../assets/svg/Tenant Landing/id.svg"
import income from "../../../assets/svg/Tenant Landing/income.svg"
import search from "../../../assets/svg/Tenant Landing/search.svg"
import video from "../../../assets/svg/Tenant Landing/video.svg"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../../Context/authContext/AuthContext"
import axios from "axios"
import HandleLoginRedirect from "API/HandleLoginRedirect"
import Cookies from "universal-cookie"
import config from "../../../config/config"
import InviteContactReminder from "./InviteContactReminder/InviteContactReminder"
import AlreadyAppliedModal from "./AlreadyAppliedModal/AlreadyAppliedModal"
import LLPaid from "../Premium/LLPaid/LLPaid"

export default function TenantLanding(props) {
  const [showModal, setShowModal] = useState(false)
  const [showAlreadyAppliedModal, setShowAlreadyAppliedModal] = useState(false)
  const [shownFeatures, setShownFeatures] = useState([])
  const cookies = new Cookies()
  const navigate = useNavigate()
  const { token } = useParams()
  const [applicationId, setApplicationId] = useState(false)
  const [address, setAddress] = useState()
  const [unit, setUnit] = useState()
  const { user } = useContext(AuthContext)
  const [requestTenantPay, setRequestTenantPay] = useState(false)
  const [applicant_token, setApplicantToken] = useState(null)
  const [applicantId, setApplicantId] = useState(null)
  const [isCoapplicant, setIsCoapplicant] = useState(false)
  const [urlAppend, setUrlAppend] = useState('')
  const details = [
    {
      id: 0,
      title: "Current and past home information",
      desc: "including where you lived, if you owned or rented and for how long",
      img: home,
    },
    {
      id: 1,
      title: "Verified landlord references",
      desc: "through an online portal, for certainty of where you lived, without annoying phone calls.",
      img: check,
    },
    {
      id: 2,
      title: "A comprehensive tenant background check",
      desc: "showing any criminal and public record  information in a secure check.",
      img: search,
      premium_id: 'background_check',
    },
    {
      id: 3,
      title: "Household information",
      desc: "including who you will be living with, your total household income, parking requirements and more.",
      img: house,
    },
    {
      id: 4,
      title: "Introductory video",
      desc: "so you can say hi to the landlord and let them get to know you quickly and easily.",
      img: video,
    },
    {
      id: 5,
      title: "A long form Equifax credit report",
      desc: "including your credit score, amounts owed, any collections or bankruptcies reported.",
      img: fax,
      premium_id: 'credit_check',
    },
    {
      id: 6,
      title: "Current and past employment information",
      desc: "including your employer, job reference and type of employment.",
      img: employment,
    },
    {
      id: 7,
      title: "Income and expense verification",
      desc: "through a secure link with your bank account, to show financial stability.",
      img: income,
      premium_id: 'financial_verification',
    },
    {
      id: 8,
      title: "ID verification",
      desc: "with a government issued ID and face matching analysis for safety and security.",
      img: id,
      premium_id: 'id_verification',
    },
  ]

  const apiConfig = {
    headers: {
      Authorization: "Bearer " + cookies.get("auth_token"),
    },
  }

  const showReminderModal = () => {
    setTimeout(() => {
      setShowModal(true)
    }, 3500)
  }
  //   const { applicant_token } = useParams()
  //   console.log({ applicant_token })
  //   const applicant_token = "cEZ3Dycw"
  useEffect(() => {
    showReminderModal()
    
    // Check to see if coapplicant_id exists, if so, set the localStorage item, otherwise, delete it (for other requests)
    const is_coapplicant = new URLSearchParams(
          window.location.search
        ).get("is_coapplicant");
    if(is_coapplicant == 1) {
      setIsCoapplicant(true);
    
      const coapplicant_id = new URLSearchParams(
          window.location.search
        ).get("coapplicant_id");
      const coapplicant_applicant_id = new URLSearchParams(
          window.location.search
        ).get("applicant_id");
        
      setUrlAppend('&is_coapplicant=1&coapplicant_id=' + coapplicant_id + '&applicant_id' + coapplicant_applicant_id);
    }

    const res = axios
      .get(`${config.BASE_URL}api/application/token/` + token)
      .then(function (response) {
        setApplicationId(response.data.data.id)
        setAddress(response.data.data.address)
        setUnit(response.data.data.unit)
        
        const items = new URLSearchParams(window.location.search).get("items");
        if(items) {
          let newItems = atob(items);
          newItems = newItems.split(',');
          
          setShownFeatures(newItems);
        }
        
        const applicant_token = new URLSearchParams(
          window.location.search
        ).get("applicant_token")
        if (applicant_token) {
          setApplicantToken(applicant_token)
          axios
            .post(
              `${config.BASE_URL}api/screening-requests-by-applicant-token`,
              {
                applicant_token,
                application_id: response.data.data.id,
              }
            )
            .then((res) => {
              const data = res.data
              setApplicantId(data.applicant_id)
              let toShow = []
              console.log({ data })
              for (let applicant of data.applicant_screening_requests) {
                for (let field of applicant.screening_request_items) {
                  if (
                    field.is_completed === 0 &&
                    !toShow.includes(field.item)
                  ) {
                    toShow.push(field.item)
                  }
                }
              }
              setShownFeatures(toShow)
            })
        }
        // axios
        //   .get(
        //     `${config.BASE_URL}api/screening-request/tenants-request-items/` +
        //       response.data.data.id,
        //     apiConfig
        //   )
        //   .then(function (response) {
        //     console.log({ response })
        //   })
        // @todo display application requirements once saved.
      })
  }, [])

  const handleApplyNowAction = (
    skip_applicant = false
  ) => {
    const is_coapplicant = new URLSearchParams(
      window.location.search
    ).get("is_coapplicant");
    let endpoint = "/tenant/coapplication";
    
    if(is_coapplicant == true) {
      endpoint = "/tenant/current-housing";
    }
  
    if (
      applicationId !== false &&
      user &&
      user.roles.find((role) => role.name === "applicant")
    ) {
      let updatedData = {
        application_id: applicationId,
      }

      axios
        .post(
          `${config.BASE_URL}api/screening-request/screening-request-get-connect/${applicationId}`,
          {
            tenant_phone_number: user.phone_number,
            tenant_id: user.user_id,
            tenant_email: user.email,
          },
          apiConfig
        )
        .then((res) => {
          if (skip_applicant) {
            localStorage.setItem(
              "currentApplicationId",
              applicationId
            )

            localStorage.setItem("currentApplicantId", applicantId)
            localStorage.setItem("isNotFirstSubmission", true)
            setTimeout(() => {
              return navigate(endpoint)
            })
            return
          }
          // If is coapplicant (check from local storage, update the user)
          if(is_coapplicant == 1) {
            const coapplicant_id = new URLSearchParams(
              window.location.search
            ).get("coapplicant_id");
            const coapplicant_applicant_id = new URLSearchParams(
              window.location.search
            ).get("applicant_id");
            
            axios
            .post(
              `${config.BASE_URL}api/applicant/update-coapplicant/` + coapplicant_id,
              {
                status: 1,
              },
              apiConfig
            )
            .then(function (response) {
              // handle response.
            })
          }
          
          // Add as an applicant & redirect
          axios
            .post(
              `${config.BASE_URL}api/applicant`,
              updatedData,
              apiConfig
            )
            .then(function (response) {
              // handle response.
              if (response.data.status === 1) {
                // Set the current tbd
                localStorage.setItem(
                  "currentApplicationId",
                  applicationId
                )
                localStorage.setItem(
                  "currentApplicantId",
                  response.data.applicant_id
                )
                localStorage.setItem("isNotFirstSubmission", false)

                navigate(endpoint) // Redirect to first step.
              } else {
                // error handling, specifically for duplicate entries.
                if(response.data.status === 0 && typeof response.data.error_type != 'undefined') {
                  switch(response.data.error_type) {
                    case 'ApplicantAlreadyApplied':
                      setShowAlreadyAppliedModal(true);
                      break;
                  }
                }
              }
            })
        })
    } else {
      navigate("/signup?redirect=" + btoa("/apply/" + token + '?returning=1' + urlAppend), {
        state: {
          role: "applicant",
        },
      })
    }
  }
  if (shownFeatures.length > 0 && applicant_token != null)
    return (
      <section className="LLPaid">
        <div className="container">
          <h1>
            the landlord would like some more info from you to
            complete the application.
          </h1>
          <p className="mb-3">
            The landlord has purchased the following items to add to
            your application. This information is kept secure and only
            shared with the landlord who has requested it. Would you
            like to consent and move forward?{" "}
          </p>
          <div className="row">
            {shownFeatures.includes("credit_check") && (
              <div className="col-md-6">
                <div className="LLPaid__feature-wrapper">
                  <div className="LLPaid__feature">
                    <span className="circle-check--small"></span>
                    <div>
                      <h4>credit check</h4>
                      <p>
                        industry standard credit checks are a secure
                        and trusted way to back up your application
                        with financial info.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {shownFeatures.includes("id_verification") && (
            <div className="col-md-6">
                <div className="LLPaid__feature-wrapper">
                  <div className="LLPaid__feature">
                    <span className="circle-check--small"></span>
                    <div>
                      <h4>id verification</h4>
                      <p>
                        let the landlord know you are a real person, so
                        they aren�t worried about any scams.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {shownFeatures.includes("background_check") && (
              <div className="col-md-6">
                <div className="LLPaid__feature-wrapper">
                  <div className="LLPaid__feature">
                    <span className="circle-check--small"></span>
                    <div>
                      <h4>background check</h4>
                      <p>
                        get an instant background check, so you and
                        the landlord dont have to go searching.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {shownFeatures.includes("reference_check") && (
              <div className="col-md-6">
                <div className="LLPaid__feature-wrapper">
                  <div className="LLPaid__feature">
                    <span className="circle-check--small"></span>
                    <div>
                      <h4>verified landlord references</h4>
                      <p>
                        get your old landlords to leave you a
                        reference, so no one has to bother with old
                        school calls.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* 
          <div className="col-md-6">
            <div className="LLPaid__feature-wrapper">
              <div className="LLPaid__feature">
                <span className="circle-check--small"></span>
                <div>
                  <h4>personalized video</h4>
                  <p>
                    introduce yourself to your potential landlord, so
                    they can get to know you quickly and easily.
                  </p>
                </div>
              </div>
            </div>
          </div>
          */}
          {shownFeatures.includes("financial_verification") && (
              <div className="col-md-6">
                <div className="LLPaid__feature-wrapper">
                  <div className="LLPaid__feature">
                    <span className="circle-check--small"></span>
                    <div>
                      <h4>income & expenses verification</h4>
                      <p>
                        securely connect with your bank, to verify your
                        income and rent payments so you dont have to pull
                        paystubs or letters of employment anymore.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
          )}
          </div>
          <div className="btn-group mt-3">
            <button
              className="btn btn--back"
              onClick={() => {
                navigate(-1)
              }}
            >
              back
            </button>
            {user ? (
              <button
                className="btn btn--primary"
                onClick={() => {
                  handleApplyNowAction(
                    "/tenant/premium-features",
                    true
                  )
                }}
              >
                continue
              </button>
            ) : (
              <button
                className="btn btn--primary"
                onClick={() => {
                  navigate(
                    "/login?redirect=" +
                      btoa(
                        "/apply/" +
                          token +
                          "?applicant_token=" +
                          applicant_token + "&returning=1" + urlAppend
                      )
                  )
                }}
              >
                log in
              </button>
            )}
          </div>
        </div>
      </section>
    )
  return (
    <section className="TenantLanding">
      <div className="container">
        {showModal && <InviteContactReminder props={setShowModal} />}
        {showAlreadyAppliedModal && <AlreadyAppliedModal props={setShowAlreadyAppliedModal} />}
        <div className="row">
          <div className="col-lg-">
            {isCoapplicant ? (
              <h1>
                your friend has invited you to apply for {unit}, {address}
              </h1>
            ) : (
              <h1>
                you've been invited to apply for {unit}, {address}
              </h1>
            )}
            <h4>
              the application may contain the following items:
              {/* the
              landlord has requested that you pay the advanced
              screening features below. */}
            </h4>
          </div>
        </div>
        <div className="TenantLanding__details-container">
          {details ? (
            <div className="TenantLanding__details">
              {details.map((details) => (
                <>
                  {/* Only show if the feature is a non-premium feature, OR the premium feature (if) is included in the shownFeatures array */}
                  {(typeof details.premium_id == 'undefined' || shownFeatures.includes(details.premium_id)) && (
                    <div
                      className="TenantLanding__details-item"
                      key={details.id}
                      >
                      <div className="imgContainer">
                        <img src={details.img} alt="" />
                      </div>
                      <p>
                        <b>{details.title}</b>
                        <br />
                        {details.desc}
                      </p>
                    </div>
                  )}
                </>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
        
        <div className="TenantLanding__details-container">
          {requestTenantPay ? (
            <div className="infoBox" id="requestTenantPayBox">
              <h2>advanced features total cost</h2>
              <h3>$Xx</h3>
              <p>
                note that payment details will be collected later in
                the application process
              </p>
            </div>
          ) : (
            ""
          )}

          <div className="row">
            <button
              className="btn btn--primary"
              onClick={() => handleApplyNowAction()}
            >
              apply now
            </button>
            <button className="btn btn--secondary-alt">
              reject invitation
            </button>
          </div>
          <p>
            already have an account?{" "}
            <Link
              className="link"
              to={"/login?redirect=" + btoa("/apply/" + token + '?returning=1' + urlAppend)}
            >
              sign in
            </Link>
          </p>
        </div>
      </div>
    </section>
  )
}
