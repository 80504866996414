import "./resubmitReferenceModal.scss"
import {
  Link,
  useNavigate,
} from "react-router-dom"

function ResubmitReferenceModal({
  openModal,
  setOpenModal,
}) {
  let navigate = useNavigate()

  return (
    <div className="ResubmitReferenceModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
            <h2>
              are you sure to resubmit the
              reference&nbsp;request?
            </h2>
          <div className="btn-group">
            <button
              className="btn btn--back"
              onClick={() => {
                setOpenModal(false)
              }}
            >
              cancel
            </button>
            <button
              className="btn btn--primary"
              onClick={() => {
                navigate(
                  "/tenant/application-preview"
                )
              }}
            >
              confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResubmitReferenceModal
