import "./coapplicantSendLink.scss"
// import "../../../assets/sass/components/_forms.scss"
import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import TextInput from "../../../Components/Input/TextInput"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import axios from "axios"
import config from "config/config"
import Cookies from "universal-cookie"

function CoapplicantSendLink() {
  let navigate = useNavigate()
  const [coapplicants, setCoapplicants] = useState([
    {
      name: "",
      email: "",
      phone: "",
      contactMethod: "email",
      id: Math.random(),
    },
  ])
  return (
    <section className="CoapplicantSendLink">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate(-1)
          }}
        >
          back
        </button>
        <ProgressBar />
        <h1>send link to co-applicants</h1>
        <div className="CoapplicantSendLink__item-container">
          {coapplicants.map((coapplicant, index) => {
            const update = (updater) => {
              setCoapplicants((prev) => {
                const newCoapplicants = [...prev]
                newCoapplicants[index] = {
                  ...newCoapplicants[index],
                  ...updater,
                }
                return newCoapplicants
              })
            }
            return (
              <div
                className="CoapplicantSendLink__item"
                key={coapplicant.id}
              >
                <div className="row">
                  <div className="col-xl-4 col-lg-6">
                    <TextInput
                      type="text"
                      label="co-applicant name"
                      name="name"
                      placeholder="type full name"
                      value={coapplicant.name}
                      onChange={(e) => {
                        update({ name: e.target.value })
                      }}
                    />
                  </div>
                </div>
                <label>Contact Method</label>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-choose-group mb-1 coapplicantToggle">
                      <button
                        className={
                          coapplicant.contactMethod === "email"
                            ? "choose-input-group label active"
                            : "choose-input-group label"
                        }
                        onClick={() =>
                          update({ contactMethod: "email" })
                        }
                      >
                        email
                      </button>
                      <button
                        className={
                          coapplicant.contactMethod === "phone"
                            ? "choose-input-group label active"
                            : "choose-input-group label"
                        }
                        onClick={() =>
                          update({ contactMethod: "phone" })
                        }
                      >
                        phone
                      </button>
                    </div>
                  </div>
                  {coapplicant.contactMethod === "email" && (
                    <div className="col-xl-4 col-lg-6">
                      <TextInput
                        type="text"
                        // label="share by email"
                        name="email"
                        placeholder="type tenant's email address"
                        value={coapplicant.email}
                        onChange={(e) => {
                          update({ email: e.target.value })
                        }}
                      />
                    </div>
                  )}
                  {coapplicant.contactMethod === "phone" && (
                    <div className="col-xl-4 col-lg-6">
                      <TextInput
                        type="text"
                        // label="share by text"
                        name="phone_number"
                        placeholder="type tenant's phone number"
                        value={coapplicant.phone}
                        onChange={(e) => {
                          update({ phone: e.target.value })
                        }}
                      />
                    </div>
                  )}
                </div>
                {coapplicants.length > 1 && (
                  <div className="row">
                    <div className="col-xl-12">
                      <button
                        className="btn btn--secondary-alt btn--small"
                        onClick={() => {
                          setCoapplicants((prev) => [
                            ...prev.slice(0, index),
                            ...prev.slice(index + 1),
                          ])
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )
          })}

          <button
            className="btn btn--secondary-alt btn--small"
            onClick={() => {
              setCoapplicants([
                ...coapplicants,
                {
                  name: "",
                  email: "",
                  phone: "",
                  contactMethod: "email",
                  id: Math.random(),
                },
              ])
            }}
          >
            add another co-applicant
          </button>
        </div>

        <div className="btn-group">
          <button
            className="btn btn--primary"
            onClick={() => {
              axios
                .post(
                  ///api/applicant/{applicantId}/send-coapplicant-link
                  `${
                    config.BASE_URL
                  }api/applicant/${localStorage.getItem(
                    "currentApplicantId"
                  )}/send-coapplicant-link`,
                  {
                    emails: coapplicants
                      .filter(
                        (coapplicant) =>
                          coapplicant.contactMethod === "email"
                      )
                      .map((coapplicant) => coapplicant.email),
                    phone_numbers: coapplicants
                      .filter(
                        (coapplicant) =>
                          coapplicant.contactMethod === "phone"
                      )
                      .map((coapplicant) => coapplicant.phone),
                  },
                  {
                    headers: {
                      Authorization:
                        "Bearer " + new Cookies().get("auth_token"),
                    },
                  }
                )
                .then((res) => {
                  navigate("/tenant/current-housing")
                })
            }}
          >
            next
          </button>
        </div>
      </div>
    </section>
  )
}

export default CoapplicantSendLink
