import { useState } from "react"

function Number({ numbers, removeNumber }) {
  const [edit, setEdit] = useState({
    id: null,
    value: "",
  })

  return numbers.map((number, index) => (
    <div
      key={index}
      className="inline-input-group"
    >
      <div className="inline input">
        <span
          onClick={() =>
            setEdit({
              id: number.id,
              value: number.tex,
            })
          }
        >
          {number.text}
        </span>
        <button
          className="btn"
          onClick={() => removeNumber(number.id)}
        >
          &#10006;
        </button>
      </div>
    </div>
  ))
}

export default Number
