import { useNavigate } from "react-router-dom"

function CongratulationsModal() {
  const navigate = useNavigate()

  return (
    <div className="CongratulationsModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2 className="mb-3">
            congrats! you’ll get an email whenever an applicant
            completes their application.
          </h2>
          <div className="btn-group">
            <button
              className="btn btn--primary btn--small"
              onClick={() => {
                navigate("/landlord/dashboard")
              }}
            >
              done
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CongratulationsModal
