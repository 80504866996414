import { useEffect, useState } from "react"
import axios from "axios"
import Recaptcha from "react-recaptcha"
import ProgressBar from "../../Components/ProgressBar/ProgressBar"
import TextInput from "../../Components/Input/TextInput"
import CheckboxInput from "../../Components/Input/CheckboxInput"
import { loginSuccess } from "../../Context/authContext/AuthActions"
import { AuthContext } from "../../Context/authContext/AuthContext"
import { useContext } from "react"
import config from "../../config/config"
import "./signup.scss"
import { useNavigate, useLocation } from "react-router-dom"
import ChooseInput from "../../Components/Input/ChooseInput"
import numberValidatorHelper from "./numberValidatorHelper"
import Cookies from "universal-cookie"
import { DelayRenderingUntilCondition } from "Components/DelayRenderingUntilCondition/DelayRenderingUntilCondition"
import { PASSWORD_VALIDATOR } from "util/validators"
import { EMAIL_VALIDATOR } from "util/validators"

//  TOGGLE PASSWORD VISIBILITY
import ShowPassword from "../../assets/images/PasswordShow.png"
import HidePassword from "../../assets/images/PasswordHide.png"

export default function Signup() {
  // TOGGLE PASSWORD VISIBILITY
  const [visible, setVisible] = useState(false)
  const InputType = visible ? "text" : "password"
  // REACT-ROUTER
  const navigate = useNavigate()
  const location = useLocation()
  const { user, dispatch } = useContext(AuthContext)
  // STATE
  const initUserState = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone_number: "",
    role: "",
    terms: false,
    newsletter: false,
  }

  const [newUser, setNewUser] = useState(initUserState)

  const [roleSelected, setRoleSelected] = useState(false)
  const [roleErrMsg, setRoleErrMsg] = useState("")

  const [emailIsValid, setEmailIsValid] = useState(true)
  const [invalidEmailMsg, setInvalidEmailMsg] = useState("")

  const [numIsValid, setNumIsValid] = useState(true)
  const [invalidPhoneMsg, setInvalidPhoneMsg] = useState("")

  const [passIsValid, setPassIsValid] = useState(true)
  const [invalidPassMsg, setInvalidPassMsg] = useState("")

  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [passNoMatchMsg, setPassNoMatchMsg] = useState("")

  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)
  const [termsErr, setTermsErr] = useState("")

  const [noServerErrs, setNoServerErrs] = useState(true)
  const [serverErrMsgs, setServerErrMsgs] = useState([])

  const [reCaptchaCode, setReCaptchaCode] = useState(null)

  const handleChange = (e) => {
    setNewUser((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  // Only to be used for Newsletter and Terms
  const handleConsent = (e) => {
    if (e.target.name === "terms") {
      setHasAgreedToTerms((prevState) => !prevState)
    }
    setNewUser((prevState) => ({
      ...prevState,
      [e.target.name]: !prevState[e.target.name],
    }))
  }

  const handleRoleChange = (e) => {
    e.preventDefault()
    setRoleSelected(true)

    setNewUser((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const INVALID_EMAIL = new Error("Please enter a valid email")

  const validateEmail = (email) => {
    const validatorResult = EMAIL_VALIDATOR(email)
    if (!validatorResult.valid) throw INVALID_EMAIL
    setEmailIsValid(true)
  }

  const handleBlurEmail = () => {
    try {
      validateEmail(newUser.email)
    } catch (e) {
      setEmailIsValid(false)
      setInvalidEmailMsg(e.message)
    }
  }

  const handleBlurPhone = () => {
    try {
      setNumIsValid(true)
      setInvalidPhoneMsg("")
      numberValidatorHelper(newUser.phone_number)
    } catch (e) {
      setNumIsValid(false)
      setInvalidPhoneMsg(e.message)
    }
  }
  const INVALID_PASSWORD = new Error(
    "Password must contain: Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
  )

  const validatePass = (input) => {
    const validatorResult = PASSWORD_VALIDATOR(input)
    if (validatorResult.valid === false) throw INVALID_PASSWORD
    setPassIsValid(true)
  }

  const handleBlurPass = () => {
    try {
      validatePass(newUser.password)
    } catch (e) {
      setPassIsValid(false)
      setInvalidPassMsg(e.message)
    }
  }

  const PASSWORD_NO_MATCH = new Error("Passwords don't match")

  const validateMatch = () => {
    if (newUser.password !== newUser.password_confirmation)
      throw PASSWORD_NO_MATCH
    setPasswordsMatch(true)
  }

  const handleBlurConfirmPass = () => {
    try {
      validateMatch()
    } catch (e) {
      setPassNoMatchMsg(e.message)
      setPasswordsMatch(false)
    }
  }

  const NO_ROLE_SELECTED = new Error("Please select a role")

  const validateUserRole = () => {
    if (!roleSelected) throw NO_ROLE_SELECTED
  }

  const handleCheckRole = () => {
    try {
      validateUserRole()
    } catch (e) {
      setRoleErrMsg(e.message)
    }
  }

  const NO_AGREE_TERMS = new Error(
    "Please agree to the terms and conditions"
  )

  const validateTerms = () => {
    if (!hasAgreedToTerms) throw NO_AGREE_TERMS
  }

  const handleCheckTerms = () => {
    try {
      validateTerms()
    } catch (e) {
      setTermsErr(e.message)
    }
  }

  const validateAll = () => {
    handleCheckRole()
    handleCheckTerms()
    return (
      emailIsValid &&
      numIsValid &&
      passIsValid &&
      passwordsMatch &&
      hasAgreedToTerms &&
      roleSelected
    )
  }

  const handleServerErr = (response) => {
    setServerErrMsgs([]) // Clear out any previous errors
    const errorKeys = Object.keys(response.data.errors)

    errorKeys.forEach((key) => {
      // First check if the key value is an array
      if (Array.isArray(response.data.errors[key])) {
        setServerErrMsgs((prevState) => {
          let newErrMsgs = []
          if (prevState.length !== 0) {
            newErrMsgs = [...prevState]
          }
          if (!newErrMsgs.includes(response.data.errors[key][0])) {
            newErrMsgs.push(response.data.errors[key][0])
            return newErrMsgs
          } else {
            // If the error message is already in the array, don't add it again
            return prevState
          }
        })
      } else {
        // Then check if the key is a string
        setServerErrMsgs((prevState) => {
          if (prevState.includes(response.data.errors[key])) {
            return prevState
          } else {
            return [...prevState, response.data.errors[key]]
          }
        })
      }
    })
    // This check is only to display the error messages
    setNoServerErrs(false)
  }

  const handleRedirect = () => {
    newUser.role === "landlord"
      ? navigate("/landlord/dashboard")
      : navigate("/tenant/dashboard")
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Make sure password is submitted in correct format
    setVisible(false)
    if (!reCaptchaCode) return
    if (validateAll()) {
      axios
        .post(`${config.BASE_URL}api/register`, {
          ...newUser,
          recaptcha: reCaptchaCode,
        })
        .then(function (response) {
          if (response.data.has_errors === true) {
            return handleServerErr(response)
          }
          if (response.data.status === 1) {
            localStorage.setItem(
              "user",
              JSON.stringify(response.data)
            )
            dispatch(loginSuccess(response.data))
            const cookies = new Cookies()
            cookies.set("auth_token", response.data.tokens[0], {
              path: "/",
            })
            handleRedirect()
          }
        })
    }
  }
  const recaptchaVerifyCallback = (code) => {
    setReCaptchaCode(code)
  }
  const recaptchaExpiredCallback = () => {
    setReCaptchaCode(null)
  }

  // Handles if being redirected from /apply page, to set the role to applicant, and hide the selection options.
  useEffect(() => {
    if (location.state?.role === "applicant") {
      setNewUser((prevState) => ({
        ...prevState,
        role: "applicant",
      }))
      setRoleSelected(true)
    }
  }, [])

  return (
    <section className="Signup">
      <div className="container">
        {location.state?.role === "applicant" ? null : (
          <div className="progress">
            {" "}
            <ProgressBar type="landlord" step="1" />
          </div>
        )}
        <h1>getting started.</h1>
        <div className="row">
          <div className="col-lg-8">
            <h3 className="mb-3">
              you must be smart. you're signing up!
              <br />
              this should only take 2 minutes.
            </h3>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-4">
              <TextInput
                type="text"
                label="First Name"
                name="first_name"
                onChange={handleChange}
                value={newUser.first_name || ""}
              />
            </div>
            <div className="col-lg-4">
              <TextInput
                type="text"
                label="Last Name"
                name="last_name"
                onChange={handleChange}
                value={newUser.last_name || ""}
              />
            </div>
            <div className="col-lg-4">
              {!numIsValid && (
                <p className={"text-danger"}>{invalidPhoneMsg}</p>
              )}
              <TextInput
                type="text"
                label="Phone"
                name="phone_number"
                hasError={!numIsValid}
                onChange={handleChange}
                onBlur={handleBlurPhone}
                value={newUser.phone_number || ""}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              {!emailIsValid && (
                <p className={"text-danger"}>{invalidEmailMsg}</p>
              )}
              <TextInput
                type="email"
                label="Email Address"
                name="email"
                hasError={!emailIsValid}
                onChange={handleChange}
                onBlur={handleBlurEmail}
                value={newUser.email || ""}
              />
            </div>
          </div>
          <div className="row">
            {!passIsValid && (
              <p className={"text-danger"}>{invalidPassMsg}</p>
            )}
            <div className="col-lg-4">
              <div className="row">
                <TextInput
                  type={InputType}
                  label="Password"
                  name="password"
                  hasError={!passIsValid}
                  onChange={handleChange}
                  onBlur={handleBlurPass}
                  value={newUser.password || ""}
                />
                <span className="passwordToggle">
                  <img
                    alt={""}
                    src={visible ? ShowPassword : HidePassword}
                    onClick={() => setVisible(!visible)}
                  />
                </span>
              </div>
            </div>
            <div className="col-lg-4">
              {!passwordsMatch && (
                <p className={"text-danger"}>{passNoMatchMsg}</p>
              )}
              <TextInput
                type="password"
                label="Confirm Password"
                name="password_confirmation"
                hasError={!passwordsMatch}
                onChange={handleChange}
                onBlur={handleBlurConfirmPass}
                value={newUser.password_confirmation || ""}
              />
            </div>
          </div>
          {/* RS-75 IF THEY COME FROM AN INVITE DISABLE THE CODE BELOW */}
          {location.state?.role === "applicant" ? null : (
            <div className="form-group">
              <div className="err-msg-wrapper">
                <label>Who are you?</label>
                &nbsp; &nbsp;
                {!roleSelected && (
                  <p className={"text-danger"}>{roleErrMsg}</p>
                )}
              </div>

              <div className="form-choose-group">
                <button
                  className={
                    newUser?.role === "landlord"
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  name="role"
                  value="landlord"
                  onClick={handleRoleChange}
                >
                  I'm a landlord looking for a&nbsp;tenant
                </button>
                <button
                  className={
                    newUser?.role === "applicant"
                      ? "choose-input-group label active"
                      : "choose-input-group label"
                  }
                  name="role"
                  value="applicant"
                  onClick={handleRoleChange}
                >
                  I'm a tenant looking for a&nbsp;home
                </button>
              </div>
            </div>
          )}
          {/* RS-75 */}
          <div className="Signup__checkbox-container">
            {!hasAgreedToTerms && (
              <p className="text-danger">{termsErr}</p>
            )}
            <CheckboxInput
              type="checkbox"
              label="Agree to terms of service and privacy policy"
              name="terms"
              onChange={handleConsent}
              value={newUser.terms || false}
            />
            <CheckboxInput
              type="checkbox"
              label="Agree to receive important news, such as newsletter, marketing, product updates"
              name="newsletter"
              onChange={handleConsent}
              value={newUser.newsletter || false}
            />
          </div>
          <div className="Signup__recaptcha-container">
            {/* recapthca logic and sitekey */}
            <DelayRenderingUntilCondition
              condition={() => {
                //! since recaptcha is being loaded through its own script tag and react-recaptcha is not validating it exists postpone rendering until we can confirm it is loaded
                // eslint-disable-next-line no-undef
                return Boolean(globalThis.recaptcha)
              }}
              pollRate={100}
            >
              <Recaptcha
                sitekey="6LefTTMgAAAAADRxrvCwjVWzgrri6o7PiCBRwIFD"
                render="explicit"
                verifyCallback={recaptchaVerifyCallback}
                expiredCallback={recaptchaExpiredCallback}
              />
            </DelayRenderingUntilCondition>
          </div>
          {!noServerErrs &&
            serverErrMsgs.map((msg) => (
              <p className={"text-danger"} key={msg}>
                {msg}
              </p>
            ))}

          <button className="btn btn--primary" type="submit">
            next
          </button>
          <p className="alreadySigned">
            Already have an account?{" "}
            <button
              className="btn btn--small btn--shrink"
              onClick={() => {
                navigate("/login")
              }}
            >
              Sign In
            </button>
          </p>
        </form>
      </div>
    </section>
  )
}
