export function removeEmptyStringsFromObject(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop) && obj[prop] === "") {
      delete obj[prop]
    } else if (obj[prop] instanceof Object) {
      removeEmptyStringsFromObject(obj[prop])
    }
  }
  return obj
}
