import FileUpload from "Components/Input/FileUpload";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import ResubmitReferenceModal from "../../../Modals/ResubmitReferenceModal/ResubmitReferenceModal";
import TextInput from "../../../Input/TextInput";

function EditView() {
    const [openModal, setOpenModal] = useState();
    const navigate = useNavigate();
    return (
        <section className="editLlReference">
            <div className="container">
                <div className="info-title-container">
                    <div className="info-title">
                        <h2>verified landlord reference</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <TextInput
                            type="text"
                            name="name"
                            label="name of Landlord"
                            value="John Smith"
                        />
                    </div>
                    <div className="col-lg-4">
                        <TextInput
                            type="email"
                            name="email"
                            label="landlord's email"
                            value="john@smith.com"
                        />
                    </div>
                </div>
                <FileUpload
                    label="Upload your lease"
                    name="lease"
                />

                <div className="btn-group mt-3">
                    <button
                        className="btn btn--back"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        cancel
                    </button>
                    <button
                        className="btn btn--primary"
                        onClick={() => {
                            setOpenModal(true);
                        }}
                    >
                        resubmit
                    </button>
                </div>
                {openModal ? (
                    <ResubmitReferenceModal
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                    />
                ) : null}
            </div>
        </section>
    );
}

export default EditView;
