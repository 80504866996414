import { useEffect, useRef } from "react"

export const useEffectOnce = (effect) => {
  const mounted = useRef(false)
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return effect()
    }
    // I want the cleanup to run when the component dismounts and not when the effect function changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
