import Helmet from "react-helmet"
import "./termsConditions.scss"

function TermsConditions() {
  return (
    <section className="TermsConditions">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Smart Screening - Terms And Conditions | Rent Panda
        </title>
        <meta
          name="description"
          content="Please read and understand our Rent Panda Smart Screening Terms of Service."
        />
      </Helmet>
      <div className="container">
        <h1>website terms and conditions of use</h1>

        <h3>Acceptance Of The Website Terms And Conditions Of Use</h3>

        <p>
          These website terms and conditions of use for{" "}
          <a href="http://www.rentpanda.ca">www.rentpanda.ca</a> and
          any products and/or services rendered through the use of the
          website or its subdomains constitute a legal agreement and
          are entered into by and between you and Rent Panda Inc.
          ("Company," "we," "us," "our"). The following terms and
          conditions, together with any documents and/or additional
          terms they expressly incorporate by reference (collectively,
          these "Terms and Conditions"), govern your access to and
          use, including any content, functionality, and services
          offered on or through www.rentpanda.ca (the "Website").
        </p>

        <h4>
          BY USING THE WEBSITE, YOU ACCEPT AND AGREE TO BE BOUND AND
          COMPLY WITH THESE TERMS AND CONDITIONS AND OUR PRIVACY
          POLICY, FOUND AT{" "}
          <a href="https://rentpanda.ca/privacy-policy">
            PRIVACY POLICY
          </a>
          , INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO
          THESE TERMS AND CONDITIONS OR THE PRIVACY POLICY, YOU MUST
          NOT ACCESS OR USE THE WEBSITE.
        </h4>

        <p>
          By using this Website, you represent and warrant that you
          are the legal age of majority under applicable law to form a
          binding contract with the Company and meet all of the
          foregoing eligibility requirements. If you do not meet all
          of these requirements, you must not access or use the
          Website.
        </p>

        <h4>
          Modifications To The Terms And Conditions And To The Website
        </h4>

        <p>
          We reserve the right in our sole discretion to revise and
          update these terms and conditions from time to time. Any and
          all such modifications are effective immediately upon
          posting and apply to all access to and continued use of the
          Website. You agree to periodically review the terms and
          conditions in order to be aware of any such modifications
          and your continued use shall be your acceptance of these.
        </p>

        <p>
          The information and material on this Website, and the
          Website, may be changed, withdrawn, or terminated at any
          time in our sole discretion without notice. We will not be
          liable if, for any reason, all or any part of the Website is
          restricted to users or unavailable at any time or for any
          period.
        </p>

        <h4>
          Your Use Of The Website And Account Set-Up And Security
        </h4>

        <p>
          The security of your personal information is very important
          to us. We use physical, electronic, and administrative
          measures designed to secure your personal information from
          accidental loss and from unauthorized access, use,
          alteration, and disclosure.
        </p>

        <p>
          The safety and security of your information also depends on
          you. Users are responsible for obtaining their own access to
          the Website. Users are required to ensure that all persons
          who access the Website through a user's internet connection
          are aware of these Terms and Conditions and comply with
          them. The Website, including content or areas of the
          Website, may require user registration. It is a condition of
          your use of the Website that all the information you provide
          on the Website is correct, current, and complete. You may
          not impersonate any person or entity, or falsify or
          otherwise misrepresent yourself or your affiliation with any
          other person or entity.
        </p>

        <p>
          Unfortunately, the transmission of information via the
          Internet is not completely secure. Although we do our best
          to protect your personal information, we cannot guarantee
          the security of your personal information transmitted to our
          Website. Any transmission of personal information is at your
          own risk. We are not responsible for circumvention of any
          privacy settings or security measures contained on the
          Website.
        </p>

        <p>
          Your provision of registration information and any
          submissions you make to the Website through any
          functionality such as applications, credit checks, criminal
          background checks, bank checks, video submissions, ID
          verification, chat rooms, e-mail and messaging system,
          message boards, profiles, forums, bulletin boards, Listings
          (as later defined), references, lease agreements, repair
          requests, notice forms, viewing schedulers and other such
          functions (collectively, "Interactive Functions")
          constitutes your consent to all actions we take with respect
          to such information consistent with our Privacy Policy,
          found at{" "}
          <a href="https://rentpanda.ca/privacy-policy">
            Privacy Policy
          </a>
          .
        </p>

        <p>
          Any username, password, or any other piece of information
          chosen by you, or provided to you as part of our security
          procedures, must be treated as confidential, and you must
          not disclose it to any other person or entity. You must
          exercise caution when accessing your account from a public
          or shared computer so that others are not able to view or
          record your password or other personal information. You
          understand and agree that should you be provided an account,
          your account is personal to you and you agree not to provide
          any other person with access to this Website or portions of
          it using your username, password, or other security
          information. You agree to notify us immediately of any
          unauthorized access to or use of your username or password
          or any other breach of security. You also agree to ensure
          that you logout from your account at the end of each
          session. You are responsible for any password misuse or any
          unauthorized access.
        </p>

        <p>
          You are limited to creating one (1) account for each of the
          following user categories:
        </p>
        <ul>
          <p>
            accounts used by property owners, property managers,
            leasing agents, or other users that could reasonably be
            considered landlords (“Landlord(s)”); and
          </p>
          <p>accounts used by tenants (“Tenant(s)”).</p>
        </ul>
        <p>
          We reserve the right at any time and from time to time, to
          disable or terminate your account, any username, password,
          or other identifier, whether chosen by you or provided by
          us, in our sole discretion for any or no reason, including
          any violation of any provision of these Terms and
          Conditions.
        </p>

        <p>
          You are prohibited from attempting to circumvent and from
          violating the security of this Website, including, without
          limitation: (a) accessing content and data that is not
          intended for you; (b) attempting to breach or breaching the
          security and/or authentication measures which are not
          authorized; (c) restricting, disrupting or disabling service
          to users, hosts, servers, or networks; (d) illicitly
          reproducing TCP/IP packet header; (e) disrupting network
          services and otherwise disrupting Website owner's ability to
          monitor the Website; (f) using any robot, spider, or other
          automatic device, process, or means to access the Website
          for any purpose, including monitoring or copying any of the
          material on the Website; (g) introducing any viruses, Trojan
          horses, worms, logic bombs, or other material that is
          malicious or technologically harmful; (h) attacking the
          Website via a denial-of-service attack, distributed
          denial-of-service attack, flooding, mailbombing, or
          crashing; and (i) otherwise attempting to interfere with the
          proper working of the Website.
        </p>

        <h4>Intellectual Property Rights and Ownership</h4>

        <p>
          You understand and agree that the Website and its entire
          contents, features, and functionality, including, but not
          limited to, all information, software, code, text, displays,
          graphics, photographs, video, audio, design, presentation,
          selection, and arrangement, are owned by the Company, its
          licensors, or other providers of such material and are
          protected in all forms by intellectual property laws
          including without limitation, copyright, trademark, patent,
          trade secret, and any other proprietary rights.
        </p>

        <p>
          The Company name, the Company logo, and all related names,
          logos, product and service names, designs, images, and
          slogans are trademarks of the Company or its affiliates or
          licensors. You must not use such marks without the prior
          written permission of the Company. Other names, logos,
          product and service names, designs, images, and slogans
          mentioned, or which appear on this Website are the
          trademarks of their respective owners. Use of any such
          property, except as expressly authorized, shall constitute
          an infringement or violation of the rights of the property
          owner and may be a violation of federal or other laws and
          could subject the infringer to legal action.
        </p>

        <p>
          You may only use the Website for your personal use or for
          certain legitimate business purposes relating to your role
          as a property owner, property manager, landlord or leasing
          agent. You shall not directly or indirectly reproduce,
          compile for an internal database, distribute, modify, create
          derivative works of, publicly display, publicly perform,
          republish, download, store, or transmit any of the material
          on our Website, in any form or medium whatsoever except:
        </p>
        <ol>
          <li>
            your computer and browser may temporarily store or cache
            copies of materials being accessed and viewed;
          </li>
          <li>
            a reasonable number of copies for personal use only may be
            printed keeping any proprietary notices thereon, which may
            only be used for non-commercial and lawful personal use
            and not for further reproduction, publication, or
            distribution of any kind on any medium whatsoever;
          </li>
          <li>
            one single user copy may be downloaded with any
            proprietary notices intact, for your own personal,
            non-commercial use, conditional on your agreement to be
            bound by our end user licence agreement for such
            downloads; and
          </li>
          <li>
            in the event social media platforms are linked to certain
            content on our Website, you may take such actions as our
            Website and such third-party social media platforms
            permit.
          </li>
        </ol>
        <p>
          Users are not permitted to modify copies of any materials
          from this Website nor delete or alter any copyright,
          trademark, or other proprietary rights notices from copies
          of materials from this site.
        </p>

        <p>
          If you print off, copy or download any part of our Website
          in breach of these Terms and Conditions, your right to use
          the Website will cease immediately and you must, at our
          option, return or destroy any copies of the materials you
          have made. You have no right, title, or interest in or to
          the Website or to any content on the Website, and all rights
          not expressly granted are reserved by the Company. Any use
          of the Website not expressly permitted by these Terms and
          Conditions is a breach of these Terms and Conditions and may
          infringe or violate copyright, trademark, and other
          intellectual property or other proprietary laws.
        </p>

        <h4>
          Conditions Of Use And User Submissions And Site Content
          Standards
        </h4>

        <p>
          As a condition of your access and use, you agree that you
          may use the Website only for lawful purposes and in
          accordance with these Terms and Conditions.
        </p>

        <p>
          The following content standards apply to any and all
          content, material, and information a user submits, posts,
          publishes, displays, or transmits (collectively, "submit")
          to the website, to other users or other persons
          (collectively, "User Submissions") and any and all
          Interactive Functions. Any and all User Submissions must
          comply with all applicable federal, provincial, local, and
          international laws, regulations, and terms of service.
        </p>

        <p>
          Without limiting the foregoing, you warrant and agree that
          your use of the Website and any User Submissions shall not:
        </p>
        <ol>
          <li>
            In any manner violate any applicable federal, provincial,
            local, or international law or regulation including,
            without limitation, any laws regarding the export of data
            or software, patent, trademark, trade secret, copyright,
            or other intellectual property, legal rights (including
            the rights of publicity and privacy of others) or contain
            any material that could give rise to any civil or criminal
            liability under applicable laws or regulations or that
            otherwise may be in conflict with these Terms and
            Conditions and our Privacy Policy found at
            <a href="https://rentpanda.ca/privacy-policy">
              Privacy Policy
            </a>
            .
          </li>
          <li>
            In any manner violate the terms of use of any third-party
            website that is linked to the Website, including but not
            limited to, any third-party social media website.
          </li>
          <li>
            Include or contain any material that is exploitive,
            obscene, harmful, threatening, abusive, harassing,
            hateful, defamatory, sexually explicit or pornographic,
            violent, inflammatory, or discriminatory based on race,
            sex, religion, nationality, disability, sexual
            orientation, or age or other such legally prohibited
            ground or be otherwise objectionable, such determination
            to be made in the Company's sole discretion.
          </li>
          <li>
            Involve stalking, attempting to exploit or harm any
            individual (including minors) in any way by exposing them
            to inappropriate content or otherwise or ask for personal
            information as prohibited under applicable laws,
            regulations, or code.
          </li>
          <li>
            Involve, provide, or contribute any false, inaccurate, or
            misleading information.
          </li>
          <li>
            Include sending, knowingly receiving, uploading,
            downloading, using, or reusing any material that does not
            comply with the User Submissions and Site Content
            Standards;
          </li>
          <li>
            Impersonate or attempt to impersonate the Company, a
            Company employee, another user, or any other person or
            entity (including, without limitation, by using email
            addresses, or screen names associated with any of the
            foregoing).
          </li>
          <li>
            Transmit, or procure the sending of, any advertisements or
            promotions without our prior written consent, sales, or
            encourage any other commercial activities, including,
            without limitation, any "spam", "junk mail", "chain
            letter", contests, sweepstakes and other sales promotions,
            barter, or advertising or any other similar solicitation.
          </li>
          <li>
            Encourage any other conduct that restricts or inhibits
            anyone's use or enjoyment of the Website, or which, as
            determined by us, may harm the Company or users of the
            Website or expose them to liability.
          </li>
          <li>
            Cause annoyance, inconvenience, or needless anxiety or be
            likely to upset, embarrass, or alarm any other person.
          </li>
          <li>
            Harass, bully, or use any language that could be
            classified as violent, defamatory, malicious or rude.
          </li>
          <li>
            Promote any illegal activity, or advocate, promote, or
            assist any unlawful act.
          </li>
          <li>
            Give the impression that they originate from or are
            endorsed by us or any other person or entity, if this is
            not the case.
          </li>
          <li>
            Recruit or otherwise solicit any User to join third-party
            services or websites that are competitive to the Company,
            without the Company’s prior written approval
          </li>
          <li>
            Contact another User regarding any Listing on the Website
            and subsequently facilitate a rental transaction
            independent of the Website in order to circumvent the
            obligation to pay any service fees related to the
            Company’s provision of services or for any other reason.
          </li>
          <li>
            Contact any other User for any purpose other than to ask a
            question related to an Accommodation or Listing (as later
            defined), and/or for the express purpose of communicating
            about renting a property or unit, or the care and
            maintenance thereof.
          </li>
        </ol>
        <p>
          We have the right to monitor and review all communications
          between Landlords and Tenants through the messaging
          platform. We have the right to investigate and prosecute
          violations of any of the above to the fullest extent of the
          law.
        </p>

        <p>
          In addition, and as set in these Terms and Conditions, we
          may take a range of actions against you, including but not
          limited to sending a warning, removing or disabling access
          to any or all of your User Submissions or deactivating or
          canceling your account.
        </p>

        <h4>User Submissions: Grant Of Licence</h4>

        <p>
          The Website may contain Interactive Functions allowing User
          Submissions on or through the Website.
        </p>

        <p>
          None of the User Submissions you submit to the Website will
          be subject to any confidentiality by the Company. By
          providing any User Submission to the Website, you grant us
          and our affiliates and service providers, and each of their
          and our respective licensees, successors, and assigns the
          right to a world-wide, royalty free, perpetual, irrevocable,
          non-exclusive licence to use, reproduce, modify, perform,
          display, distribute, and otherwise disclose to third parties
          any such material for any purpose and according to your
          account settings and/or incorporate such material into any
          form, medium or technology throughout the world without
          compensation to you. You further waive any moral rights or
          other rights of authorship as a condition of submitting any
          User Submission.
        </p>

        <p>
          By submitting the User Submissions, you declare and warrant
          that you own or have the necessary rights to submit the User
          Submissions and have the right to grant the licence hereof
          to us and our affiliates and service providers, and each of
          their and our respective licensees, successors, and assigns
          to the User Submissions and comply with these Terms and
          Conditions. You represent and warrant that all User
          Submissions comply with applicable laws and regulations and
          the User Submissions and Site Content Standards set out in
          these Terms and Conditions.
        </p>

        <p>
          You understand and agree that you, not the Company nor
          Company's parent, subsidiaries, affiliates nor their
          respective directors, officers, employees, agents, service
          providers, contractors, licensors, suppliers or successors,
          are fully responsible for any User Submissions you submit or
          contribute, and you are fully responsible and legally
          liable, including to any third party, for such content and
          its accuracy. We are not responsible or legally liable to
          any third party for the content or accuracy of any User
          Submissions submitted by you or any other user of the
          Website.
        </p>

        <h4>User Submissions: Landlord Submission Standards</h4>

        <p>
          Users with a Landlord account will have the capability to
          submit certain information to create an informational post
          (“Listing”) for properties available for lease
          (“Accommodation(s)”), as well as to prepare and/or process
          forms, applications, documents, leases, notices, repair and
          maintenance requests, rent payments and other such materials
          and submissions related to the properties, and to submit
          references for tenants.
        </p>

        <p>
          To create a Listing, you will be asked a variety of
          questions about the Accommodation to be listed, including,
          but not limited to, the location, size, features, and
          availability of the Accommodation, pricing and any other
          relevant information. In order to be featured on the
          Listings page of the Website, all Accommodations must have
          valid municipal addresses. The placement or ranking of
          Listings in Website search results may depend on a variety
          of factors such as a proprietary algorithm as well as paid
          advertising.
        </p>

        <p>
          Any photograph or video of an Accommodation that a Landlord
          uploads to the Website shall become the property of the
          Company and the User waives all right and claims to the use,
          reproduction, distribution, copying or other activity for
          any purpose. If a Landlord requests that the Company produce
          photographs and/or video of their property the Landlord
          affirms that any and all of these productions are the
          exclusive property of the Company and the Landlord has no
          rights or claims to such productions and may not use these
          productions for any purposes whatsoever without explicit
          written authorization by the Company. If an existing tenant
          is occupying the Accommodation, the Landlord is solely
          responsible for facilitating a mutually acceptable time for
          the existing tenant and the Company approved photographer or
          videographer to take photos and videos of the Accommodation.
          The Landlord is solely responsible for ensuring that the
          appropriate notification has been made to any existing
          tenants occupying the Accommodation at the time the Company
          approved photographer or videographer arrives at the
          Accommodation.
        </p>

        <p>
          We will own all copyright of any photographs and/or videos
          of the Accommodation that have been taken by photographers
          or videographers employed by the Company. You will not have
          any right to use such photographs or videos without our
          explicit permission.
        </p>

        <p>
          We retain a right to and may use any photograph or video
          uploaded to the Website for advertising, marketing,
          commercial and/or other business purposes in any media or
          platform, whether in relation to your Listing or otherwise,
          without further notice or compensation. The Company may
          transfer this right to its service providers, contractors,
          licensors, licensees, suppliers or any other third party.
        </p>

        <p>
          Any Landlord who has created a Listing is solely responsible
          for ensuring that the Accommodation is accurately
          represented by the information, photographs and videos on
          the Listing.
        </p>

        <p>
          Any Landlord who creates a Listing represents and warrants
          that:
        </p>
        <ul>
          <li>
            They will not be in breach of any agreements that the
            Landlord has entered into with any third parties,
            including, without limitation, homeowners association,
            condominium, or other third party agreements by creating
            the Listing; and
          </li>
          <li>
            They are in compliance with all applicable laws, tax
            requirements, intellectual property laws, and rules and
            regulations that may apply to any Accommodation included
            in a Listing (including having all required permits,
            licenses and registrations).
          </li>
        </ul>
        <p>
          We assume no responsibility for a Landlord’s noncompliance
          with any agreements with third parties, tax requirements,
          intellectual property laws, or other applicable laws, rules
          and regulations.
        </p>

        <p>
          We reserve the right, at any time and without prior notice,
          to remove, edit or disable access to any Listing for any
          reason, including Listings that the Company, in its sole
          discretion, considers to be objectionable for any reason, is
          in violation of these Terms and Conditions or is otherwise
          harmful to the Company and/or the Website.
        </p>

        <p>
          We recommend that Landlords obtain appropriate insurance for
          all Listings, paying particular attention to the coverage
          for actions taken by any Tenant. Landlords are solely
          responsible for obtaining such insurance and determining the
          adequacy for the purposes of their proposed arrangement with
          any Tenant and the Company will have no responsibility of
          verifying the adequacy of such policies.
        </p>

        <h4>User Submissions: Tenant Submission Standards</h4>

        <p>
          Users with a Tenant account will have the capability to
          submit certain personal information to create a Tenant
          profile as well as the preparation and/or processing of
          forms, applications, documents, notices, repair tickets,
          rent payments and other such materials and submissions
          related to the Accommodation, and to submit references
          required by Landlords. Users will also be submitting
          information related to credit checks, criminal background
          checks, bank checks,, video submissions, ID verification.
          None of the User Submissions you submit to the Website will
          be subject to any confidentiality by the Company. By
          providing any User Submission to the Website, you grant us
          and our affiliates and service providers, and each of their
          and our respective licensees, successors, and assigns the
          right to a world-wide, royalty free, perpetual, irrevocable,
          non-exclusive licence to use, reproduce, modify, perform,
          display, distribute, and otherwise disclose to third parties
          any such material for any purpose and according to your
          account settings and/or incorporate such material into any
          form, medium or technology throughout the world without
          compensation to you. You further waive any moral rights or
          other rights of authorship as a condition of submitting any
          User Submission.
        </p>

        <p>
          Users with a Landlord account will have access to the
          information displayed on your Tenant profile. You are
          responsible for keeping your personal information up to
          date.
        </p>

        <p>
          The use, reproduction, hosting, transmission, and disclosure
          of any User Submissions from Tenants must at all times be in
          compliance with our Privacy Policy.
        </p>

        <h4>Relationship</h4>

        <p>
          By using the Website, creating an account, and/or posting
          any User Submissions, your relationship shall not be
          construed to be that of an employee, contractor, agent,
          joint ventures or partner of the Company in any way. By
          using the Website, you act exclusively on your own behalf
          and for your own benefit, and not on behalf of or for the
          benefit of the Company. The Company maintains the right to
          control your account and Listings, but does not control nor
          does it wish to control your offline activities associated
          with your Listings. You may not do anything to create a
          false impression that you are endorsed by, partnering with,
          or acting on behalf of or for the benefit of the Company.
        </p>

        <h4>References And Reporting</h4>

        <p>
          The Website may include a reference/rating function where
          Users have the capability to periodically submit
          references/ratings for a User. By using this Website, you
          agree to be eligible for references/ratings. These
          references/ratings are User Submissions and solely reflect
          the opinions of Users. The references/ratings are in no way
          an evaluation conducted by the Company or a reflection of
          the Company’s opinions. Users cannot appeal a
          reference/rating or respond to a reference/rating. If a User
          feels particularly maligned by a reference/rating, they can
          contact the Company via the contact feature of the Website;
          the Company will consider the case accordingly.
          References/ratings are gathered and displayed by us in the
          manner that we see fit and subject to our discretion. User
          has no rights or claims to the references/ratings or to
          prevent the references/ratings from being displayed on the
          Website.
        </p>

        <p>
          If any Landlord or Tenant has an interaction in which one or
          both parties feel that that the other party is acting or has
          acted inappropriately, including but not limited to anyone
          who (i) engages in offensive, violent or sexually
          inappropriate behavior, (ii) has engaged in theft or is
          suspected to have engaged in theft, or (iii) engages in any
          other disturbing conduct, you should immediately report such
          person to the appropriate authorities and then to the
          Company by contacting us with your police station and report
          number. Your report will not obligate us to take any action
          beyond that required by law (if any) or cause us to incur
          any liability or other responsibility.
        </p>

        <h4>Feedback</h4>

        <p>
          We welcome and encourage you to provide feedback, comments
          and suggestions for improvements to the Website. You may
          submit feedback by emailing us through the “About Us”
          section of the Website. All feedback that you provide will
          be the sole and exclusive property of the Company and you
          hereby irrevocably assign to the Company all of your right,
          title, and interest in and to all feedback, including
          without limitation all worldwide patent, copyright, trade
          secret, moral and other proprietary or intellectual property
          rights therein, and waive any moral rights you may have in
          such feedback. At the Company’s request and expense, you
          will execute documents and take such further acts as the
          Company may reasonably request to assist the Company to
          acquire, perfect, and maintain its intellectual property
          rights and other legal protections for the feedback.
        </p>

        <h4>
          Site Monitoring And Enforcement, Suspension, And Termination
        </h4>

        <p>Company has the right, without provision of notice to:</p>
        <ul>
          <li>
            Remove or refuse to post on the Website any User
            Submissions for any or no reason in our sole discretion.
          </li>
          <li>
            At all times, take such actions with respect to any User
            Submission deemed necessary or appropriate in our sole
            discretion, including, without limitation, for violating
            the Website and User Submissions and Site Content
            Standards or Terms and Conditions.
          </li>
          <li>
            Take appropriate legal action, including, without
            limitation, referral to law enforcement or regulatory
            authority, or notifying the harmed party of any illegal or
            unauthorized use of the Website. Without limiting the
            foregoing, we have the right to fully cooperate with any
            law enforcement authorities or court order requesting or
            directing us to disclose the identity or other information
            of anyone posting any materials on or through the Website.
          </li>
          <li>
            Terminate or suspend your access to all or part of the
            Website for any or no reason, including, without
            limitation, any violation of these Terms and Conditions.
          </li>
        </ul>
        <p>
          <b>
            YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS PARENT,
            SUBSIDIARIES, AFFILIATES, AND THEIR RESPECTIVE DIRECTORS,
            OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
            CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND
            SUCCESSORS FROM ANY AND ALL CLAIMS RESULTING FROM ANY
            ACTION TAKEN BY THE COMPANY AND ANY OF THE FOREGOING
            PARTIES RELATING TO ANY, INVESTIGATIONS BY EITHER THE
            COMPANY OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.
          </b>
        </p>

        <p>
          We have no obligation, nor any responsibility to any party
          to monitor the Website or its use, and do not and cannot
          undertake to review material that you or other users submit
          to the Website. We cannot ensure prompt removal of
          objectionable material after it has been posted and we have
          no liability for any action or inaction regarding
          transmissions, communications, or content provided by any
          user or third party, subject to applicable laws.
        </p>

        <h4>No Reliance</h4>

        <p>
          The content on our Website is provided for general
          information purposes only. It is not intended to amount to
          advice on which you should rely. You must obtain more
          specific or professional advice before taking, or refraining
          from, any action or inaction on the basis of the content on
          our site.
        </p>

        <p>
          Although we make reasonable efforts to update the
          information on our Website, we make no representations,
          warranties, or guarantees, whether express or implied, that
          the content on our Website is accurate, complete, or up to
          date. Your use of the Website is at your own risk and
          neither the Company nor its parent, subsidiaries,
          affiliates, and their respective directors, officers,
          employees, agents, service providers, contractors,
          licensors, licensees, suppliers, or successors have any
          responsibility or liability whatsoever for your use of this
          Website.
        </p>

        <p>
          The Company does not endorse any Listing or Accommodation.
          The Company makes no representations that photographs,
          videos or other User Submissions relating to any
          Accommodation on the Website accurately depict the
          Accommodation in its current state. The Company does not
          confirm the identity of or endorse any Landlord or the
          quality, legality or any other feature of the Listing or
          Accommodation.
        </p>

        <p>
          This Website includes content provided by third parties,
          including from other users and third-party licensors. All
          statements and/or opinions expressed in any such third-party
          content, other than the content provided by the Company, are
          solely the opinions and the responsibility of the person or
          entity providing those materials. Such materials do not
          necessarily reflect the opinion of the Company. Neither the
          Company nor its parent, subsidiaries, affiliates, and their
          respective directors, officers, employees, agents, service
          providers, contractors, licensors, licensees, suppliers, or
          successors have any responsibility or liability whatsoever
          to you, or any third party, for the content or accuracy of
          any third-party materials.
        </p>

        <h4>Privacy</h4>

        <p>
          By submitting your personal information and using our
          Website, you consent to the collection, use, reproduction,
          hosting, transmission, and disclosure of any such user
          content submissions in compliance with our Privacy Policy,
          found at{" "}
          <a href="https://rentpanda.ca/privacy-policy">
            Privacy Policy
          </a>
          , as we deem necessary for use of the Website and provision
          of services.
        </p>

        <p>
          By using this Website you are consenting to the use of
          cookies which allow a server to recall previous requests or
          registration and/or IP addresses to analyze website use
          patterns. You can set your browser to notify you before you
          receive a cookie, giving you the chance to decide whether to
          accept it. You can also set your browser to turn off
          cookies. If you do, however, some areas of the Website may
          not function adequately. For more information on this
          automated information gathering practices, see{" "}
          <a href="https://rentpanda.ca/privacy-policy#InformationCollectThroughCookies">
            Privacy Policy Cookies Provision
          </a>
          .
        </p>

        <h4>Third-Party Websites</h4>

        <p>
          For your convenience, this Website may provide links or
          pointers to third-party sites. We make no representations
          about any other websites that may be accessed from this
          Website. If you choose to access any such sites, you do so
          at your own risk. We have no control over the contents of
          any such third-party sites and accept no responsibility for
          such sites or for any loss or damage that may arise from
          your use of them. You are subject to any terms and
          conditions of such third-party sites.
        </p>

        <p>
          Such links to third-party sites from the Website may include
          links to certain social media features that enable you to
          link or transmit on your own or using certain third-party
          websites, certain content from this Website. You may only
          use these features when they are provided by us and solely
          with respect to the content identified.
        </p>

        <p>
          You may link to our homepage, provided you do so in a way
          that is fair and legal and does not damage our reputation or
          take advantage of it. You must not establish a link in such
          a way as to suggest any form of association, approval, or
          endorsement on our part where none exists. Our Website must
          not be framed on any other site, nor may you create a link
          to any part of our Website other than the homepage. We
          reserve the right to withdraw linking permission without
          notice. The website in which you are linking must comply in
          all respects with the Conditions of Use and User Submissions
          and Site Content Standards. You agree to cooperate with us
          in causing any unauthorized framing or linking to
          immediately stop.
        </p>

        <h4>Geographic Restrictions</h4>

        <p>
          The owner of the Website is based in Ontario in Canada. We
          provide this Website for use only by persons located in
          Canada. This Website is not intended for use in any
          jurisdiction where its use is not permitted. If you access
          the Website from outside Canada, you do so at your own risk
          and you are responsible for compliance with local laws of
          your jurisdiction.
        </p>

        <h4>Disclaimer Of Warranties</h4>

        <p>
          <b>
            YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS
            CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED
            THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
            CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED
            THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS
            AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF
            ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT
            LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE
            FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </b>
        </p>

        <p>
          <b>
            NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES,
            AFFILIATES, OR THEIR RESPECTIVE DIRECTORS, OFFICERS,
            EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS,
            LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY
            WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO
            THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY,
            ACCURACY, CURRENCY, OR AVAILABILITY OF THE WEBSITE OR ITS
            CONTENTS. WITHOUT LIMITING THE FOREGOING, NEITHER THE
            COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR
            RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
            PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS,
            OR SUCCESSORS REPRESENT OR WARRANT THAT THE WEBSITE, ITS
            CONTENT, OR ANY SERVICES OR ITEMS FOUND OR ATTAINED
            THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE,
            ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
            CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT MAKES IT
            AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
          </b>
        </p>

        <p>
          <b>
            WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR
            DATA AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE
            WEBSITE WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE.
            YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR USE OF
            THE WEBSITE AND YOUR COMPUTER, INTERNET, AND DATA
            SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL
            NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY
            DENIAL-OF-SERVICE ATTACK, DISTRIBUTED DENIAL-OF-SERVICE
            ATTACK, OVERLOADING, FLOODING, MAIL BOMBING, OR CRASHING,
            VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER
            TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
            COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER
            PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY
            SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE OR
            TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON
            ANY WEBSITE LINKED TO IT.
          </b>
        </p>

        <h4>Limitation On Liability</h4>

        <p>
          <b>
            EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER
            NO CIRCUMSTANCE WILL THE COMPANY NOR ITS PARENT,
            SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS,
            OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
            CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR
            SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE,
            NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES
            OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT,
            INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
            DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY,
            PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE,
            LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
            LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER
            CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT,
            BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS
            ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR
            IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OR
            RELIANCE ON, THE WEBSITE, ANY LINKED WEBSITES OR SUCH
            OTHER THIRD-PARTY WEBSITES, NOR ANY WEBSITE CONTENT,
            MATERIALS, POSTING, OR INFORMATION THEREON EVEN IF THE
            PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW.
          </b>
        </p>

        <h4>Indemnification</h4>

        <p>
          To the maximum extent permitted by applicable law, you agree
          to defend, indemnify, and hold harmless the Company, its
          parent, subsidiaries, affiliates, and their respective
          directors, officers, employees, agents, service providers,
          contractors, licensors, suppliers, successors, and assigns
          from and against any claims, liabilities, damages,
          judgments, awards, losses, costs, expenses, or fees
          (including reasonable attorneys' fees) arising out of or
          relating to your breach of these Terms and Conditions or
          your use of the Website, including, but not limited to, your
          User Submissions, third-party sites, any use of the
          Website's content, services, and products other than as
          expressly authorized in these Terms and Conditions.
        </p>

        <h4>Governing Law And Choice Of Forum</h4>

        <p>
          The Website and these Terms and Conditions will be governed
          by and construed in accordance with the laws of the Province
          of Ontario and the federal laws of Canada applicable
          therein, without giving effect to any choice or conflict of
          law provision, principle, or rule (whether of the laws of
          the Province of Ontario or any other jurisdiction) and
          notwithstanding your domicile, residence, or physical
          location.
        </p>

        <p>
          Any action or proceeding arising out of or relating to this
          Website and under these Terms and Conditions will be
          instituted in the courts of the Province of Ontario and/or
          the Federal Court of Canada, and each party irrevocably
          submits to the exclusive jurisdiction of such courts in any
          such action or proceeding. You waive any and all objections
          to the exercise of jurisdiction over you by such courts and
          to the venue of such courts.
        </p>

        <h4>Waiver</h4>

        <p>
          No failure to exercise, or delay in exercising, any right,
          remedy, power, or privilege arising from these Terms and
          Conditions operates, or may be construed, as a waiver
          thereof. No single or partial exercise of any right, remedy,
          power, or privilege hereunder precludes any other or further
          exercise thereof or the exercise of any other right, remedy,
          power, or privilege.
        </p>

        <h4>Severability</h4>

        <p>
          If any term or provision of these Terms and Conditions is
          invalid, illegal, or unenforceable in any jurisdiction, such
          invalidity, illegality, or unenforceability shall not affect
          any other term or provision of these Terms and Conditions or
          invalidate or render unenforceable such term or provision in
          any other jurisdiction.
        </p>

        <h4>Entire Agreement</h4>

        <p>
          The Terms and Conditions and our Privacy Policy constitute
          the sole and entire agreement between you and the Company
          regarding the Website and supersedes all prior and
          contemporaneous understandings, agreements, representations
          and warranties, both written and oral, regarding such
          subject matter.
        </p>

        <h4>Reporting And Contact</h4>

        <p>
          This website is operated by Rent Panda Inc., an Ontario
          corporation with its mailing address at 1100 Memorial
          Avenue, Suite 125, Thunder Bay, Ontario, P7B 4A3.
        </p>

        <p>
          Should you become aware of misuse of the website including
          libelous or defamatory conduct, you must report it to the
          Company at hello@rentpanda.ca
        </p>

        <p>
          All other feedback, comments, requests for technical
          support, and other communications relating to the Website
          should be directed to hello@rentpanda.ca
        </p>
      </div>
    </section>
  )
}

export default TermsConditions
