import "./tenantPay.scss";
import ProgressBar from "../../../../Components/ProgressBar/ProgressBar";
import TenantPayRejectModal from "./TenantPayRejectModal/TenantPayRejectModal";
import {useState} from "react";

function TenantPay() {
    const [openTenantPayRejectModal, setOpenTenantPayRejectModal] = useState(false);

    return (
        <section className="TenantPay">
            <div className="container">
                <ProgressBar/>

                <h1>the landlord would like some more information from you to complete
                    the application.</h1>
                <p className="mb-3">The landlord would like you to to add some advanced info to your application.
                    This information is kept secure and only shared with the landlord who has requested it.
                    Would you like to consent and move forward? </p>
                <div className="row">
                    <div className="col-md-6">
                        <div className="TenantPay__feature-wrapper">
                            <div className="TenantPay__feature">
                                <span className="circle-check--small"></span>
                                <div>
                                    <h4>credit check</h4>
                                    <p>industry standard credit checks are a secure and trusted way to back up your application with financial info.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="TenantPay__feature-wrapper">
                            <div className="TenantPay__feature">
                                <span className="circle-check--small"></span>
                                <div>
                                    <h4>id verification</h4>
                                    <p>let the landlord know you are a real person, so they aren’t worried about any scams.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="TenantPay__feature-wrapper">
                            <div className="TenantPay__feature">
                                <span className="circle-check--small"></span>
                                <div>
                                    <h4>background check</h4>
                                    <p>get an instant background check, so you and the landlord dont have to go searching.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="TenantPay__feature-wrapper">
                            <div className="TenantPay__feature">
                                <span className="circle-check--small"></span>
                                <div>
                                    <h4>verified landlord references</h4>
                                    <p>get your old landlords to leave you a reference, so no one has to bother with old school calls.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="TenantPay__feature-wrapper">
                            <div className="TenantPay__feature">
                                <span className="circle-check--small"></span>
                                <div>
                                    <h4>personalized video</h4>
                                    <p>introduce yourself to your potential landlord, so they can get to know you quickly and easily.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="TenantPay__feature-wrapper">
                            <div className="TenantPay__feature">
                                <span className="circle-check--small"></span>
                                <div>
                                    <h4>income & expenses verification</h4>
                                    <p>securely connect with your bank, to verify your income and rent payments so you dont have to pull paystubs or letters of employment anymore.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="btn-group mt-3">
                    <button className="btn btn--back">back</button>
                    <button className="btn btn--secondary-alt"
                        onClick={() => {
                            setOpenTenantPayRejectModal(true);
                        }}
                    >reject
                    </button>
                    <button className="btn btn--primary">continue</button>
                </div>
            </div>
            {openTenantPayRejectModal && (
                <TenantPayRejectModal closeModal={setOpenTenantPayRejectModal}/>
            )}
        </section>
    );
}

export default TenantPay;
