import { useState, useContext } from "react"
import { AuthContext } from "Context/authContext/AuthContext"
import "./employmentInfo.scss"

function EmploymentInfo({ display }) {
  const { user } = useContext(AuthContext)

  const [updateMode, setUpdateMode] = useState()
  const [showData, setShowData] = useState(true)
  const [student, setStudent] = useState(
    display?.is_student ? "yes" : "no"
  )
  const [school, setSchool] = useState(
    display?.school_name ?? "unknown"
  )
  const [program, setProgram] = useState(
    display?.school_program ?? "unknown"
  )
  const [start, setStart] = useState(display?.start_date ?? "unknown")
  const [employment, setEmployment] = useState(
    display?.current_status ?? "unknown"
  )
  const [salary, setSalary] = useState(
    display?.salary ? `${display.salary}/month` : "unknown"
  )
  const [jobTitle, setJobTitle] = useState(
    display?.job_title ?? "unknown"
  )
  const [employer, setEmployer] = useState(
    display?.employer ?? "unknown"
  )
  const [jobStart, setJobStart] = useState(
    display?.start_date ?? "unknown"
  )

  // TODO: Info not provided
  const [refName, setRefName] = useState(
    display?.employment_references?.[0]?.name ?? "unknown"
  )
  const [refNumber, setRefNumber] = useState(
    display?.employment_references?.[0]?.phone_number ?? "unknown"
  )

  const [refEmail, setRefEmail] = useState(
    display?.employment_references?.[0]?.email ?? "unknown"
  )

  return (
    <section className="employment-info application-preview-container">
      <div className="info-title-container">
        <div className="info-title">
          <div className="info__label info__label--completed">
            completed
          </div>
          <h2>employment & income info</h2>
        </div>
        <div className="info-title-controls">
          {showData ? (
            <>
              {user.roles[0].name == "tenant" && (
                <button
                  className="btn btn--secondary-alt btn--small btn--shrink"
                  onClick={
                    updateMode
                      ? // CHANGE THIS TO HANDLESUBMIT?
                        () => setUpdateMode(false)
                      : //
                        () => setUpdateMode(true)
                  }
                >
                  {updateMode ? "submit" : "edit"}
                </button>
              )}
            </>
          ) : null}
          <button
            className={
              showData
                ? "btn btn--toggle btn--toggle-inverse"
                : "btn btn--toggle btn--toggle-inverse active"
            }
            onClick={() => setShowData(!showData)}
          ></button>
        </div>
      </div>

      {/* THIS SECTION HANDLES STUDENT CASES */}
      {showData ? (
        <>
          {(display?.is_student === 1) ? (
           <>
            <div className="row">
              <div className="col-md-6">
                <h5>are you a student</h5>
                {updateMode ? (
                  <input
                    type="text"
                    value={student}
                    onChange={(e) => setStudent(e.target.value)}
                    />
                ) : (
                  <p>{student}</p>
                )}
              </div>
            </div>
           </>
          ) : '' }
            <div className="row">
              {(display?.is_student === 1) ? (
                <>
                <div className="col-md-6">
                  <h5>what school do you attend?</h5>
                  {updateMode ? (
                    <input
                      type="text"
                      value={school}
                      onChange={(e) => setSchool(e.target.value)}
                      />
                  ) : (
                    <p>{school}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <h5>what program are you taking?</h5>
                  {updateMode ? (
                    <input
                      type="text"
                      value={program}
                      onChange={(e) => setProgram(e.target.value)}
                      />
                  ) : (
                    <p>{program}</p>
                  )}
                </div>
              </>
              ) : ''}

              { display.is_student === 1  && <div className="col-md-6">
              <h5>start date</h5>
              {updateMode ? (
                <input
                  type="text"
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                />
              ) : (
                <p>{start}</p>
              )}
            </div>}
          </div>

          {(display?.is_student !== 1) ? (
            <>
           <h3>current employment info 1</h3>
           <div className="row">
             <div className="col-md-6">
               <h5>what is your current employment status?</h5>
               {updateMode ? (
                 <input
                   type="text"
                   value={employment}
                   onChange={(e) => setEmployment(e.target.value)}
                   />
               ) : (
                 <p>{employment}</p>
               )}
             </div>
           </div>
           <div className="row">
             <div className="col-md-6">
               <h5>what's your monthly income for this job?</h5>
               {updateMode ? (
                 <input
                   type="text"
                   value={salary}
                   onChange={(e) => setSalary(e.target.value)}
                   />
               ) : (
                 <p>{salary}</p>
               )}
             </div>
             <div className="col-md-6">
               <h5>what is your current job title?</h5>
               {updateMode ? (
                 <input
                   type="text"
                   value={jobTitle}
                   onChange={(e) => setJobTitle(e.target.value)}
                   />
               ) : (
                 <p>{jobTitle}</p>
               )}
             </div>
             <div className="col-md-6">
               <h5>who is your employer?</h5>
               {updateMode ? (
                 <input
                   type="text"
                   value={employer}
                   onChange={(e) => setEmployer(e.target.value)}
                   />
               ) : (
                 <p>{employer}</p>
               )}
             </div>
             <div className="col-md-6">
               <h5>when did you start at this job?</h5>
               {updateMode ? (
                 <input
                   type="text"
                   value={jobStart}
                   onChange={(e) => setJobStart(e.target.value)}
                   />
               ) : (
                 <p>{jobStart}</p>
               )}
             </div>
             {/* //TODO: file stuff */}
             {/* <div className="col-md-6">
              <h5>proof of income</h5>
              <p>
                <a href="/path-to-file" download="name-of-file.pdf">
                  click to download
                </a>
              </p>
            </div> */}
           </div>
           {(display?.employment_references.length || updateMode) && (
            <>
              <h4>reference 1</h4>
              <div className="row">
                <div className="col-md-auto">
                  <h5>name</h5>
                  {updateMode ? (
                    <input
                      type="text"
                      value={refName}
                      onChange={(e) => setRefName(e.target.value)}
                      />
                  ) : (
                    <p>{refName}</p>
                  )}
                </div>
                <div className="col-md-auto">
                  <h5>phone number</h5>
                  <p>
                    {updateMode ? (
                      <input
                        type="text"
                        value={refNumber}
                        onChange={(e) => setRefNumber(e.target.value)}
                        />
                    ) : (
                      <a href="tel:11234567890">{refNumber}</a>
                    )}
                  </p>
                </div>
                <div className="col-md-auto">
                  <h5>email address</h5>
                  <p>
                    {updateMode ? (
                      <input
                        type="text"
                        value={refEmail}
                        onChange={(e) => setRefEmail(e.target.value)}
                        />
                    ) : (
                      <a href="mailto:johnsmith@acme.com">
                        {refEmail}
                      </a>
                    )}
                  </p>
                </div>
              </div>
            </>
          )}
          </>
          ) : ''}
        </>
      ) : null}
    </section>
  )
}

export default EmploymentInfo
