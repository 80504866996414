import {
  HasSlotData,
  MountedElementContainer,
  MountedProvider,
  MountedSlot,
} from "Components/MountedElements/MountedElements"
import styles from "./Modal.module.scss"
export const Modal = ({ children, maxWidth, minHeight }) => {
  return (
    <MountedElementContainer>
      <div className={styles.modal}>
        <div style={{ display: "none" }}>{children}</div>
        <div className={styles.overlay}></div>
        <div
          className={styles.wrapper}
          style={{
            maxWidth: (maxWidth || 750) + "px",
          }}
        >
          <div
            className={styles.container}
            style={{
              minHeight: (minHeight || 750) + "px",
            }}
          >
            {/* 
				These are wrapped in a HasSlotData check so that they can be conditionally wrapped in elements to control form visuals
				The contents of the HasSlotData component is only rendered if the slot is not empty
			*/}
            <HasSlotData.Head>
              <MountedSlot.Head />
            </HasSlotData.Head>
            <HasSlotData.Body>
              <MountedSlot.Body />
            </HasSlotData.Body>
            <HasSlotData.Footer>
              <MountedSlot.Footer />
            </HasSlotData.Footer>
          </div>
        </div>
      </div>
    </MountedElementContainer>
  )
}
const ModalHead = ({ children }) => (
  <MountedProvider.Head>{children}</MountedProvider.Head>
)
const ModalBody = ({ children }) => (
  <MountedProvider.Body>{children}</MountedProvider.Body>
)
const ModalFooter = ({ children }) => (
  <MountedProvider.Footer>{children}</MountedProvider.Footer>
)
Modal.Head = ModalHead
Modal.Body = ModalBody
Modal.Footer = ModalFooter
