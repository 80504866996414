import { useState } from "react"
import { useNavigate } from "react-router-dom"
import LLReference from "./LLReference"

function VerifiedLandlordReference({ display }) {
  const [showData, setShowData] = useState(true)
  return (
    <section className="verified-landlord-reference-info application-preview-container">
      <div className="info-title-container">
        <div className="info-title">
          <div className="info__label info__label--completed">
            completed
          </div>
          <h2>verified landlord reference</h2>
        </div>
        <div className="info-title-controls">
          <button
            className="btn btn--toggle btn--toggle-inverse"
            onClick={() => setShowData(!showData)}
          ></button>
        </div>
      </div>
      {showData && <LLReference display={display} key="LLRef" />}
    </section>
  )
}

export default VerifiedLandlordReference
