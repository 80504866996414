import "./landlordDashboard.scss"
import { useState, useEffect, useContext } from "react"
import VerifyInfoModal from "./VerifyInfoModal/VerifyInfoModal"
import SelectInput from "../../../Components/Input/SelectInput"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import config from "../../../config/config"
import HandleLoginRedirect from "../../../API/HandleLoginRedirect"
import Cookies from "universal-cookie"
import ChangePropertyStatusModal from "../../../Components/Modals/ChangePropertyStatusModal/ChangePropertyStatusModal.jsx"
import { AuthContext } from "Context/authContext/AuthContext"
import Helmet from "react-helmet"

function LandlordDashboard(props) {
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [properties, setProperties] = useState({})

  const [activeModal, setActiveModal] = useState(null)
  const cookies = new Cookies()
  const { user } = useContext(AuthContext)
  const apiConfig = {
    headers: {
      Authorization: "Bearer " + cookies.get("auth_token"),
    },
  }

  const setPropertyStatus = (propertyId, status) => {
    let propertyItemKey = null
    for (var prop in properties) {
      if (properties.hasOwnProperty(prop)) {
        if (properties[prop].id === propertyId) propertyItemKey = prop
      }
    }

    // Check to validate that propertyId exists.
    if (propertyId != null && propertyId != false) {
      if (status === "inactive") {
        setActiveModal(
          <ChangePropertyStatusModal
            onConfirm={() => {
              setProperties(
                properties.map((property) =>
                  property.id === propertyId
                    ? { ...property, status: "inactive" }
                    : property
                )
              )
              axios
                .post(
                  `${config.BASE_URL}api/property/change-status/` +
                    propertyId,
                  {},
                  apiConfig
                )
                .then(function (response) {
                  // @todo
                  // Change the field.
                  if (
                    properties[propertyItemKey].status === "active"
                  ) {
                    setProperties(
                      properties.map((property) =>
                        property.id === propertyId
                          ? { ...property, status: "inactive" }
                          : property
                      )
                    )
                  } else {
                    setProperties(
                      properties.map((property) =>
                        property.id === propertyId
                          ? { ...property, status: "active" }
                          : property
                      )
                    )
                  }
                })
                .catch(function (error) {
                  setProperties(
                    properties.map((property) =>
                      property.id === propertyId
                        ? { ...property, status: "active" }
                        : property
                    )
                  )
                })
              setActiveModal(null)
            }}
            onCancel={() => setActiveModal(null)}
          ></ChangePropertyStatusModal>
        )
      } else {
        setProperties(
          properties.map((property) =>
            property.id === propertyId
              ? { ...property, status: "active" }
              : property
          )
        )
        axios
          .post(
            `${config.BASE_URL}api/property/change-status/` +
              propertyId,
            {},
            apiConfig
          )
          .then(function (response) {
            // @todo
            // Change the field.
            if (properties[propertyItemKey].status === "active") {
            } else {
              setProperties(
                properties.map((property) =>
                  property.id === propertyId
                    ? { ...property, status: "active" }
                    : property
                )
              )
            }
          })
          .catch((e) => {
            setProperties(
              properties.map((property) =>
                property.id === propertyId
                  ? { ...property, status: "inactive" }
                  : property
              )
            )
          })
      }
    }
  }

  useEffect(() => {
    const res = axios
      .get(
        `${config.BASE_URL}api/property?sort_field=created_at&sort_asc=1`,
        apiConfig
      )
      .then(function (response) {
        // @todo
        // alert(response.data.message)
        if (HandleLoginRedirect(navigate, response) == false) {
          // Results are in: response.data.data
          setProperties(response.data.data)
        }
      })
      .catch(function (error) {
        let response = error.response

        if (HandleLoginRedirect(navigate, response) == false) {
          // Results are in: response.data.data
          setProperties(response.data.data)
        }
      })
  }, [props])

  const checkVerifiedUser = () => {
    // @TODO: Check if user is verified.
    return true

    // const user = JSON.parse(localStorage.getItem("user"))

    // if (
    //   user["verified_at"] === "" ||
    //   user["verified_at"] === null ||
    //   user["verified_at"] === undefined
    // ) {
    //   setOpenModal(true)
    //   return false
    // }

    // return true
  }

  return (
    <section className="LandlordDashboard">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Smart Screening - Landlord Dashboard | Rent Panda
        </title>
        <meta
          name="description"
          content="Your Smart Screening landlord account dashboard"
        />
      </Helmet>
      <div className="container">
        <h1>
          hi, {user.first_name} {user.last_name}!
        </h1>
        <div className="title-container">
          <h2>your rentals</h2>
          <button
            className="btn btn--primary"
            onClick={() => {
              navigate("/landlord/property/create")
            }}
          >
            create a new application
          </button>
        </div>

        <div className="LandlordDashboard__properties">
          <table>
            <thead>
              <tr>
                <th className="text-left">address</th>
                <th>date posted</th>
                <th>complete applications</th>
                <th>status</th>
                <th>view applicants</th>
              </tr>
            </thead>
            <tbody>
              {properties.length > 0 &&
                Object.entries(properties).map(([key, value]) => (
                  <tr
                    className="LandlordDashboard__property"
                    data-property-id={properties[key].id}
                    key={key}
                  >
                    <td className="text-left">
                      {properties[key].unit
                        ? `Unit: ${properties[key].unit}, `
                        : null}
                      {properties[key].address}
                    </td>
                    <td>
                      {new Date(properties[key].created_at)
                        .toISOString()
                        .slice(0, 10)}
                    </td>
                    <td>{properties[key].applicants.length}</td>
                    <td>
                      {properties[key].status == "active" ? (
                        <button
                          className="mx-auto btn btn--switch"
                          onClick={() => {
                            setPropertyStatus(
                              properties[key].id,
                              "inactive"
                            )
                          }}
                        ></button>
                      ) : (
                        ""
                      )}

                      {properties[key].status != "active" ? (
                        <button
                          className="btn btn--switch btn--switch-inactive"
                          onClick={() => {
                            setPropertyStatus(
                              properties[key].id,
                              "active"
                            )
                          }}
                        ></button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <button
                        className="btn btn--secondary-alt btn--small"
                        onClick={() => {
                          if (checkVerifiedUser() === false) {
                            setOpenModal(true)
                          } else {
                            navigate("/landlord/application")
                            cookies.set(
                              "current_property_application_id",
                              properties[key].id,
                              { path: "/" }
                            )
                          }
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {activeModal}
      {openModal && <VerifyInfoModal setOpenModal={setOpenModal} />}
    </section>
  )
}

export default LandlordDashboard
