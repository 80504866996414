import React, { useState, useContext } from "react"
import { AuthContext } from "Context/authContext/AuthContext"

//TODO: is there a reason this is misspelled?
function HaseholdInfo({ display }) {
  const { user } = useContext(AuthContext)

  const [updateMode, setUpdateMode] = useState(false)
  const [showData, setShowData] = useState(true)
  const [rentors, setRentors] = useState(
    display?.tenant_count ?? "unknown"
  )
  const [children, setChildren] = useState(
    display?.minor_count ?? "unknown"
  )
  const [parking, setParking] = useState(
    display?.parking_count ?? "unknown"
  )
  const [length, setLength] = useState(
    display?.renting_duration ?? "unknown"
  )
  const [income, setIncome] = useState(
    display?.monthly_income ?? "unknown"
  )
  const [smoke, setSmoke] = useState(
    display && "smoke" in display
      ? display.smoke
        ? "yes"
        : "no"
      : "unknown"
  )
  const [accessible, setAccessible] = useState(
    display && "accessible" in display
      ? display.accessible
        ? "yes"
        : "no"
      : "unknown"
  )
  const [pets, setPets] = useState(
    display && "pets" in display
      ? display.pets?.length > 2 // This was breaking without the second "?"
        ? "yes"
        : "no"
      : "unknown"
  )
  const [petData, setPetData] = useState(
    JSON.parse(display?.pets ?? "[]")
  )
  const [details, setDetails] = useState(
    display?.description ?? "unknown"
  )
  return (
    <section className="household-info application-preview-container">
      <div className="info-title-container">
        <div className="info-title">
          <div className="info__label info__label--completed">
            completed
          </div>
          <h2>household & personal info</h2>
        </div>
        <div className="info-title-controls">
          {showData ? (
            <>
              {user.roles[0].name == "tenant" && (
                <button
                  className="btn btn--secondary-alt btn--small btn--shrink"
                  onClick={
                    updateMode
                      ? // CHANGE THIS TO HANDLESUBMIT?
                        () => setUpdateMode(false)
                      : //
                        () => setUpdateMode(true)
                  }
                >
                  {updateMode ? "submit" : "edit"}
                </button>
              )}
            </>
          ) : null}
          <button
            className="btn btn--toggle btn--toggle-inverse"
            onClick={() => setShowData(!showData)}
          ></button>
        </div>
      </div>
      {showData ? (
        <>
          {" "}
          <div className="row">
            <div className="col-md">
              <h5>how many people are you renting with?</h5>
              {updateMode ? (
                <input
                  min={0}
                  type="number"
                  value={rentors}
                  onChange={(e) => {
                   if (e.target.value >= 0) setRentors(e.target.value)
                  }}
                />
              ) : (
                <p>{rentors}</p>
              )}
            </div>
            <div className="col-md">
              <h5>how many of those are children/minors?</h5>
              {updateMode ? (
                <input
                  min={0}
                  type="number"
                  value={children}
                  onChange={(e) => {
                    if (e.target.value >= 0) setChildren(e.target.value)
                  }}
                />
              ) : (
                <p>{children}</p>
              )}
            </div>
            <div className="col-md">
              <h5>how many parking spots do you require?</h5>
              {updateMode ? (
                <input
                  min={0}
                  type="number"
                  value={parking}
                  onChange={(e) => {
                    if (e.target.value >= 0) setParking(e.target.value)
                  }}
                />
              ) : (
                <p>{parking}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h5>how many months do you want to rent for ?</h5>
              {updateMode ? (
                <input
                  type="text"
                  value={length}
                  onChange={(e) => setLength(e.target.value)}
                />
              ) : (
                <p>{length}</p>
              )}
            </div>
            <div className="col-md-6">
              <h5>
                what is the total household monthly income (everyone
                living in the home)?
              </h5>
              {updateMode ? (
                <input
                  type="text"
                  value={income}
                  onChange={(e) => setIncome(e.target.value)}
                />
              ) : (
                <p>{income}</p>
              )}
            </div>
            <div className="col-md-6">
              <h5>do you or any member of your household smoke?</h5>
              {updateMode ? (
                <input
                  type="text"
                  value={smoke}
                  onChange={(e) => setSmoke(e.target.value)}
                />
              ) : (
                <p>{smoke}</p>
              )}
            </div>
            <div className="col-md-6">
              <h5>do you require an accessible home?</h5>
              {updateMode ? (
                <input
                  type="text"
                  value={accessible}
                  onChange={(e) => setAccessible(e.target.value)}
                />
              ) : (
                <p>{accessible}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h5>do you have any pets as part of your household?</h5>
              {updateMode ? (
                <input
                  type="text"
                  value={pets}
                  onChange={(e) => setPets(e.target.value)}
                />
              ) : (
                <p>{pets}</p>
              )}
            </div>
          </div>
          {petData.map((pet, index) => (
            <React.Fragment key={index}>
              <h3>pet info {index + 1}</h3>
              <div className="row">
                <div className="col-md-6">
                  <h5>what type?</h5>
                  {updateMode ? (
                    <input
                      type="text"
                      value={pet.type}
                      onChange={(e) =>
                        setPetData((old) =>
                          old.map((p, i) =>
                            i == index
                              ? { ...p, type: e.target.value }
                              : p
                          )
                        )
                      }
                    />
                  ) : (
                    <p>{pet.type}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <h5>what size?</h5>
                  {updateMode ? (
                    <input
                      type="text"
                      value={pet.size}
                      onChange={(e) =>
                        setPetData((old) =>
                          old.map((p, i) =>
                            i == index
                              ? { ...p, size: e.target.value }
                              : p
                          )
                        )
                      }
                    />
                  ) : (
                    <p>{pet.size}</p>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
          <div className="row">
            <div className="col">
              <h5>
                this is the space to tell the landlord a little bit
                about your household.
              </h5>
              {updateMode ? (
                <input
                  type="text"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              ) : (
                <p>{details}</p>
              )}
            </div>
          </div>
        </>
      ) : null}
    </section>
  )
}

export default HaseholdInfo
