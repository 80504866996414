import { useNavigate } from "react-router-dom"

function RejectionNoticeModal({ setOpenModal }) {
  const navigate = useNavigate()
  const address = { name: "123 Fake Street" }
  return (
    <div className="RejectionNoticeModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h3>
            your application for {address.name}{" "}
            has been withdrawn
          </h3>

          <div className="btn-group">
            <button
              className="btn btn--primary"
              onClick={() => {
                navigate("/tenant/dashboard")
              }}
            >
              done
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RejectionNoticeModal
