import "./landlordReference.scss"
import ChooseInput from "../../../Components/Input/ChooseInput"
import TextInput from "../../../Components/Input/TextInput"
import FileUpload from "../../../Components/Input/FileUpload"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect } from "react"
import axios from "axios"
import config from "config/config"
import { objectToFormData } from "util/objectToFormData"
import PlacesAutocomplete from "react-places-autocomplete"
import { useId } from "react"
import { useRandomId } from "Hooks/useRandomId"

const ChoiceInputs = ({ name, options, onChange }) => {
  return (
    <>
      {options.map((option, index) => {
        return (
          <ChooseInput
            label={option}
            name={name}
            key={option}
            value={option}
            onChange={() => {
              onChange(option)
            }}
          />
        )
      })}
    </>
  )
}
function cap(str) {
  return str[0].toUpperCase() + str.slice(1)
}
function LandlordReference() {
  const { token } = useParams()
  const [data, setData] = useState({
    address: "",
    payOnTime: "",
    causeDamage: "",
    rentToAgain: "",
    phoneNumber: "",
    idFile: null,
    leaseFile: null,
  })
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const update = (updateData) => {
    setData({
      ...data,
      ...updateData,
    })
  }
  useEffect(() => {
    if (token) {
      axios
        .get(
          `${config.BASE_URL}api/landlord-reference/token/${token}`
        )
        .then((res) => {
          console.log(res)
          setName(
            cap(res.data.reference_tenant_info.first_name) +
              " " +
              cap(res.data.reference_tenant_info.last_name)
          )
        })
    }
  }, [token])
  const searchOptions = {
    componentRestrictions: { country: ["ca"] },
  }
  const addressId = useRandomId()
  return (
    <section className="LandlordReference">
      <div className="container">
        <h1>
          {name} has put you down as a landlord reference on a rental
          application
        </h1>
        <p>
          Please fill out the form below and submit it to leave a
          reference for this tenant.
        </p>
        <div className="row mt-4">
          <div className="col-xl-4 col-md-6 form-group">
            <label htmlFor={addressId}>address</label>
            <PlacesAutocomplete
              value={data.address}
              onChange={(e) => {
                update({ address: e })
              }}
              onSelect={(e) => {
                update({ address: e })
              }}
              id="address_autocomplete"
              searchOptions={searchOptions}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div
                  key={suggestions.placeId}
                  className="LandlordReferencePlaces"
                >
                  <input
                    {...getInputProps({
                      placeholder: "Search Places ...",
                      className: "form-group__form-control",
                    })}
                    id={addressId}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item"
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          }
                      return (
                        <div
                          key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {/* <TextInput
              type="text"
              label="what was the address of the rental?"
              name="tenant_address"
              value={data.address}
              onChange={(ev) => {
                update({ address: ev.target.value })
              }}
            /> */}
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="form-group">
              <label>did the tenant pay rent on time?</label>
              <div className="form-choose-group">
                <ChoiceInputs
                  name="rent_on_time"
                  options={["yes", "sometimes", "no"]}
                  onChange={(value) => {
                    update({ payOnTime: value })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div className="form-group">
              <label>did this tenant cause damage?</label>
              <div className="form-choose-group">
                <ChoiceInputs
                  name="cause_damage"
                  options={["yes", "little", "no"]}
                  onChange={(value) => {
                    update({ causeDamage: value })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="form-group">
              <label>would you rent to this tenant again?</label>
              <div className="form-choose-group">
                <ChoiceInputs
                  name="rent_again"
                  options={["yes", "maybe", "no"]}
                  onChange={(value) => {
                    update({ rentToAgain: value })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-6">
            <TextInput
              type="text"
              label="what is the best phone number to contact you for additional verification."
              name="phone_number"
              value={data.phoneNumber}
              onChange={(ev) => {
                update({ phoneNumber: ev.target.value })
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <FileUpload
              label="please upload your id (optional)"
              name="id"
              onChange={(ev) => {
                update({ idFile: ev })
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <FileUpload
              label="please upload this tenant’s lease to verify the tenancy (optional)"
              name="lease"
              onChange={(ev) => {
                update({ leaseFile: ev })
              }}
            />
          </div>
        </div>
        <div className="btn-group">
          <button
            className="btn btn--primary"
            onClick={() => {
              if (
                data.address &&
                data.causeDamage &&
                data.rentToAgain &&
                data.phoneNumber
              ) {
                axios
                  .post(
                    `${config.BASE_URL}api/landlord-reference/response`,
                    objectToFormData({
                      tenant_address: data.address,
                      rent_on_time: data.payOnTime,
                      cause_damage: data.causeDamage,
                      rent_to_again: data.rentToAgain,
                      phone_number: data.phoneNumber,
                      token,
                      ...(data.leaseFile?.[0]?.file && {
                        lease: data.leaseFile?.[0]?.file,
                      }),
                      ...(data.idFile?.[0]?.file && {
                        id: data.idFile?.[0]?.file,
                      }),
                    })
                  )
                  .then((res) => {
                    if (res.data.status === 1) {
                      navigate(
                        "/verified-landlord-reference/thank-you"
                      )
                    } else {
                    }
                  })
              }
            }}
          >
            done
          </button>
        </div>
      </div>
    </section>
  )
}

export default LandlordReference
