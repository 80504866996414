import { useState } from "react"

function PersonalizedVideo() {
  const [showData, setShowData] = useState(true)
  return (
    <section className="personalized-video-info application-preview-container">
      <div className="info-title-container">
        <div className="info-title">
          <div className="info__label info__label--completed">
            completed
          </div>
          <h2>personalized video</h2>
        </div>
        <div className="info-title-controls">
          {showData ? (
            <>
              <button className="btn btn--secondary-alt btn--small btn--shrink">
                edit
              </button>
            </>
          ) : null}
          <button
            className="btn btn--toggle btn--toggle-inverse"
            onClick={() => setShowData(!showData)}
          ></button>
        </div>
      </div>
      <video src="#"></video>
    </section>
  )
}

export default PersonalizedVideo
