import Cookies from "universal-cookie"
import { useState } from "react"

export const useCookie = (name, defaultValue) => {
  const cookie = new Cookies()
  const [value, setValue] = useState(cookie.get(name) || defaultValue)
  if (cookie.get(name) === undefined) {
    cookie.set(name, value)
  }
  return [
    value,
    (newValue) => {
      cookie.set(name, newValue)
      setValue(newValue)
    },
  ]
}
