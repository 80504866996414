import "./verifyInfoModal.scss"
import TextInput from "../../../../Components/Input/TextInput"
import SelectInput from "../../../../Components/Input/SelectInput"
import FileUpload from "../../../../Components/Input/FileUpload"
import VerifyEmailModal from "../VerifyEmailModal/VerifyEmailModal"

import { useState } from "react"
import axios from "axios"
import config from "../../../../config/config"
import Cookies from "universal-cookie"

function VerifyInfoModal({ closeModal }) {
  const cookies = new Cookies()

  const apiConfig = {
    headers: {
      Authorization: "Bearer " + cookies.get("auth_token"),
    },
  }

  const [openVerifyEmailModal, setOpenVerifyEmailModal] =
    useState(false)

  const [fileUpload, setFileUpload] = useState(false)

  const fileHandler = (data) => {
    setFileUpload(data)
  }

  const initLandlord = {
    first_name: "",
    last_name: "",
    email: "",
    email_confirmation: "",
    // Default to landlord as it is pre-selected in dropdown
    type: "landlord",
    reference: "",
    id: fileUpload || null,
  }

  // Create a landlord object in state from all the fields.
  const [landlord, setLandlord] = useState(initLandlord)

  const handleChange = (e) => {
    e.preventDefault()
    setLandlord((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log("RIGHT BEFORE SUBMIT")
    console.log(landlord)
    const res = await axios.post(
      `${config.BASE_URL}api/verify-landlord`,
      { ...landlord },
      apiConfig
    )
    console.log("Response: ")
    console.log(res)
  }

  return (
    <div className="VerifyInfoModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2>please verify your information</h2>
          <p>
            to abide by the information privacy act and credit
            compliance guidelines, we are required to collect the
            following information from you. you will only be asked for
            this information once.{" "}
          </p>
          <div className="VerifyInfoModal__form-container">
            <div className="row">
              <div className="col-lg-6">
                <TextInput
                  type="text"
                  label="First Name"
                  name="first_name"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6">
                <TextInput
                  type="text"
                  label="Last Name"
                  name="last_name"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6">
                <TextInput
                  type="email"
                  label="Email Address"
                  name="email"
                  lastNam
                  onChange={handleChange}
                  e
                />
              </div>
              <div className="col-lg-6">
                <TextInput
                  type="email"
                  label="Confirm Email Address"
                  name="email_confirmation"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6">
                <SelectInput
                  label="I am a"
                  name="type"
                  onChange={handleChange}
                >
                  <option value="landlord">Landlord</option>
                  <option value="tenant">Tenant</option>
                  <option value="property-manager">
                    Property manager
                  </option>
                  <option value="real-estate-agent">
                    Real estate agent
                  </option>
                </SelectInput>
              </div>
              <div className="col-lg-6">
                <SelectInput
                  label="How did you hear about us? (Optional)"
                  name="reference"
                  onChange={handleChange}
                >
                  <option value="social-media">Social media</option>
                  <option value="google">Google</option>
                  <option value="word-of-mouth">Word of mouth</option>
                  <option value="lawn-sign">Lawn sign</option>
                  <option value="other">Other</option>
                </SelectInput>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FileUpload
                  label="Upload your ID (Optional)"
                  name="id"
                  onChange={fileHandler}
                />
              </div>
            </div>
          </div>
          <div className="btn-group">
            <button
              className="btn btn--primary"
              onClick={(e) => {
                setOpenVerifyEmailModal(true)
                handleSubmit(e).then(() => {
                  console.log("Submitted to API")
                })
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      {openVerifyEmailModal && (
        <VerifyEmailModal
          closeModal={setOpenVerifyEmailModal}
          userEmail={landlord.email}
        />
      )}
    </div>
  )
}

export default VerifyInfoModal
