import "./applicationPreview.scss"
import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import HousingInfo from "../../../Components/ApplicationPreview/HousingInfo/HousingInfo"
import EmploymentInfo from "../../../Components/ApplicationPreview/EmploymentInfo/EmploymentInfo"
import HouseholdInfo from "../../../Components/ApplicationPreview/HouseholdInfo/HouseholdInfo"
import Guarantor from "../../../Components/ApplicationPreview/Guarantor/Guarantor"
import PremiumLocked from "../../../Components/ApplicationPreview/PremiumLocked/PremiumLocked"
import CreditCheck from "../../../Components/ApplicationPreview/CreditCheck/CreditCheck"
import BackgroundCheck from "../../../Components/ApplicationPreview/BackgroundCheck/BackgroundCheck"
import IDVerification from "../../../Components/ApplicationPreview/IDVerification/IDVerification"
import PersonalizedVideo from "../../../Components/ApplicationPreview/PersonalizedVideo/PersonalizedVideo"
import VerifiedLandlordReference from "../../../Components/ApplicationPreview/VerifiedLandlordReference/VerifiedLandlordReference"
import BankCheck from "../../../Components/ApplicationPreview/BankCheck/BankCheck"
import { useEffect, useState } from "react"
import ConfirmModal from "./ConfirmModal/ConfirmModal"
import CoApplicants from "../../../Components/ApplicationPreview/CoApplicants/CoApplicants"
import axios from "axios"
import Cookies from "universal-cookie"
import { useNavigate } from "react-router-dom"
import config from "../../../config/config"
import { usePremiumFlow } from "Context/flowPremiumContext/FlowPremiumContext"
function ApplicationPreview() {
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const navigate = useNavigate()
  const [showData, setShowData] = useState(true)
  const [{ applicant }, setData] = useState({})
  const { hasPremiumFeature, completedPremiumFeature } =
    usePremiumFlow()
  useEffect(() => {
    const cookie = new Cookies()
    axios
      .get(
        `${config.BASE_URL}api/applicant/${localStorage.getItem(
          "currentApplicantId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${cookie.get("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setData(res.data)
      })
  }, [])
  return (
    <section className="ApplicationPreview">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate(-1)
          }}
        >
          back
        </button>
        <ProgressBar type="tenant" step="8" />
        <div className="row">
          <div className="col-lg-8">
            <h1 className="h2">
              Please review your application for unit-
              {applicant?.application?.unit}{" "}
              {applicant?.application?.address} and submit to the
              landlord.
            </h1>
          </div>
        </div>

        <div className="ApplicationPreview__container">
          {/*
          <div className="ApplicationPreview__coapplicants-container">
            <h4>
              you have applied with {applicant?.coapplicants?.length}{" "}
              co-applicants
            </h4>
            <div className="ApplicationPreview__coapplicants">
              {applicant?.coapplicants.map((coapplicant, index) => {
                return (
                  <div
                    className="ApplicationPreview__coapplicant"
                    key={coapplicant.id}
                  >
                    {coapplicant.first_name}
                  </div>
                )
              })}
            </div>
          </div>
          */}
          {applicant?.housings?.map((house) => {
            return (
              <HousingInfo
                display={house}
                key={house.id}
                type={house.type}
                editable="true"
                showData={showData}
                setShowData={setShowData}
              />
            )
          })}
          {applicant?.employments?.map((employment) => {
            return (
              <EmploymentInfo
                display={employment}
                key={employment.id}
              />
            )
          })}
          {applicant?.households?.map((household) => {
            return (
              <HouseholdInfo display={household} key={household.id} />
            )
          })}

          {/*
          <Guarantor
            editable="true"
            showData={showData}
            setShowData={setShowData}
          />

          <PremiumLocked
            title="some title"
            description="some description"
            showData={showData}
            setShowData={setShowData}
          />
		*/}
          {hasPremiumFeature("credit_check") &&
            applicant?.credit_checks?.map((credit_check) => {
              return (
                <CreditCheck
                  type="tenant"
                  data={JSON.parse(credit_check.certn_data)}
                  key={credit_check.id}
                />
              )
            })}

          {hasPremiumFeature("background_check") &&
            applicant?.background_checks?.map((background_check) => {
              return (
                <BackgroundCheck
                  type="tenant"
                  data={JSON.parse(background_check.certn_data)}
                  key={background_check.id}
                />
              )
            })}
          {/* <BackgroundCheck
            type="tenant"
            showData={JSON.parse(background_check)}
          /> */}
          {hasPremiumFeature("id_verification") &&
            applicant?.id_verifications?.map((id_verification) => {
              return (
                <IDVerification
                  display={id_verification}
                  key={id_verification.id}
                />
              )
            })}

          {/* <PersonalizedVideo
            editable="true"
            showData={showData}
            setShowData={setShowData}
          /> */}

          {/*
          <VerifiedLandlordReference
            editable="true"
            showData={showData}
            setShowData={setShowData}
          />
          */}
          {applicant?.employment_income_verifications?.map(
            (verification) => {
              return (
                <BankCheck
                  display={verification}
                  key={verification.id}
                />
              )
            }
          )}

          {/* <BankCheck
            editable="true"
            showData={showData}
            setShowData={setShowData}
          /> */}
        </div>

        <div className="btn-group mt-3">
          <button
            className="btn btn--primary"
            onClick={() => {
              setOpenConfirmModal(true)
            }}
          >
            submit
          </button>
        </div>
      </div>

      {openConfirmModal && (
        <ConfirmModal
          closeModal={setOpenConfirmModal}
          address={applicant?.application?.address}
        />
      )}
    </section>
  )
}

export default ApplicationPreview
