import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import "./addressInformation.scss"
import TextInput from "../../../Components/Input/TextInput"
import { useNavigate } from "react-router-dom"
import PlacesAutocomplete from "react-places-autocomplete"
import { useState } from "react"
import axios from "axios"
import config from "../../../config/config"
import HandleLoginRedirect from "../../../API/HandleLoginRedirect"
import Cookies from "universal-cookie"
import Helmet from "react-helmet"

export default function AddressInformation() {
  // Use URL to render further steps in the components
  let navigate = useNavigate()
  const [address, setAddress] = useState("")
  const [error, setError] = useState(false)
  const [addressUnit, setAddressUnit] = useState("")
  const cookies = new Cookies()
  const handleSelect = async (value) => {
    setAddress(value)
    console.log(value)
  }
  const searchOptions = {
    componentRestrictions: { country: ["ca"] },
  }
  const storeProperty = function () {
    let apiConfig = {
      headers: {
        Authorization: "Bearer " + cookies.get("auth_token"),
      },
    }
    let propertyData = {
      address: address,
      unit: addressUnit,
    }

    //alert(addressUnit);
    const res = axios
      .post(`${config.BASE_URL}api/property`, propertyData, apiConfig)
      .then(function (response) {
        // @todo
        // alert(response.data.message)
        if (HandleLoginRedirect(navigate, response) == false) {
          // Results are in: response.data.data
          let applicantId = response.data.applicationId
          if (applicantId != null) {
            cookies.set(
              "current_property_application_id",
              applicantId,
              { path: "/" }
            )
            navigate("/landlord/property/path")
          }
          if (applicantId == null) {
            setError(true)
          }
        }
      })
      .catch(function (error) {
        let response = error.response
        if (HandleLoginRedirect(navigate, response) == false) {
          // Results are in: response.data.data
          let applicantId = response.data.applicationId
          if (applicantId != null) {
            cookies.set(
              "current_property_application_id",
              applicantId,
              { path: "/" }
            )
            navigate("/landlord/property/path")
          }
        }
      })
  }

  return (
    <section className="AddressInformation">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Smart Screening - Create A Property To Start | Rent Panda
        </title>
        <meta
          name="description"
          content="Input address to get started with Rent Panda Smart Screening AI technology for landlords and tenants."
        />
      </Helmet>
      <div className="container">
        <ProgressBar type="landlord" step="2" />
        <h1>input the address you are screening tenants for.</h1>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="address_autocomplete">
                The full address
              </label>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
                className="form-group__form-control"
                id="address_autocomplete"
                searchOptions={searchOptions}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div key={suggestions.placeId}>
                    <input
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "location-search-input",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item"
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            }
                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              {error ? (
                <span className="errorMessage">
                  You must enter an address!
                </span>
              ) : null}

              {/* <TextInput
              type="text"
              label="Your Rental Property Address"
              name="address"
            /> */}
            </div>
          </div>
          <div className="col-lg-4">
            <TextInput
              type="text"
              label="Unit name (optional)"
              placeholder="ex. basement"
              name="unit"
              onChange={(e) => setAddressUnit(e.target.value)}
            />
          </div>
        </div>
        <div className="btn-group nextBtn">
          <button
            className="btn btn--primary"
            onClick={() => {
              storeProperty()
            }}
          >
            next
          </button>
        </div>
      </div>
    </section>
  )
}
