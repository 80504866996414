const TextAppendBtnInput = ({ value, label, name, placeholder, type, onChange, onClick, btnText }) => (
    <div className="form-group">
      {label && <label htmlFor={name}><h3>{label}</h3></label>}
        <div className="form-group_appended">
            <input
                type={type}
                value={value}
                name={name}
                id={name}
                className="form-group__form-control"
                placeholder={placeholder}
                onChange={onChange}
            />
            <button className="btn btn--primary" onClick={onClick}>
                {btnText}
            </button>
        </div>
    {/*  error message here  */}
    </div>
);

export default TextAppendBtnInput;