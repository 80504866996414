import "./guarantorLink.scss";
import TextInput from "../../../Components/Input/TextInput";
import FileUpload from "../../../Components/Input/FileUpload";
import CheckboxInput from "../../../Components/Input/CheckboxInput";
import {useState} from "react";

function GuarantorLink() {
    const [showThankyou, setShowThankyou] = useState(false);

    return (
        <>
            {Boolean(!showThankyou) && (
                <section className="GuarantorLink">
                    <div className="container">

                        <h1>you’ve been invited to be a guarantor!</h1>
                        <h2>-full name- has asked you to be their guarantor on a rental application for -property address-.
                            Please fill out the form below to complete the request.</h2>

                        <div className="row">
                            <div className="col-lg-4">
                                <TextInput
                                    type="text"
                                    label="first name"
                                    name="first_name"
                                />
                            </div>
                            <div className="col-lg-4">
                                <TextInput
                                    type="text"
                                    label="last name"
                                    name="last_name"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4">
                                <TextInput
                                    type="email"
                                    label="email address"
                                    name="email"
                                />
                            </div>
                            <div className="col-lg-4">
                                <TextInput
                                    type="text"
                                    label="phone number"
                                    name="phone_number"
                                />
                            </div>
                            <div className="col-lg-4">
                                <TextInput
                                    type="text"
                                    label="monthly income"
                                    name="monthly_income"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <FileUpload
                                    label="upload proof of income (paystub, notice of assessment, letter of employment, etc.)"
                                    name="proof_of_income"
                                />
                            </div>
                        </div>

                        <CheckboxInput
                            name="accept"
                            label="I consent to being a guarantor for this rental application"
                        />

                        <div className="btn-group">
                            <button className="btn btn--primary">Submit</button>
                        </div>
                    </div>
                </section>
            )}
            {showThankyou && (
                <section className="GuarantorLinkThankyou">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <h1>thank you!</h1>
                                <p>you may receive a call from the landlord, requesting more information or to confirm
                                    your information. please connect with the renter you are supporting if you have any
                                    questions about the application or home they are applying for.</p>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default GuarantorLink;
