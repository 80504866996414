import "./creditReport.scss"
import { useState } from "react"
import CreditReportModal from "./CreditReportModal/CreditReportModal"
import { useNavigate } from "react-router-dom"
import { useEffectOnce } from "Hooks/useEffectOnce"
import axios from "axios"
import config from "../../../../config/config"
import Cookies from "universal-cookie"
import { usePremiumFlow } from "../../../../Context/flowPremiumContext/FlowPremiumContext"

function CreditReport() {
  const navigate = useNavigate()
  const { premiumFeatureComplete } = usePremiumFlow()
  const [openCreditReportModal, setOpenCreditReportModal] =
    useState(false)
  return (
    <section className="CreditReport">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate(-1)
          }}
        >
          back
        </button>
        <h1>Credit report</h1>
        <h2>status</h2>
        <p>
          {!premiumFeatureComplete("credit_check") && "in"}complete
        </p>

        <div className="btn-group">
          <button
            className="btn btn--primary"
            onClick={() => {
              setOpenCreditReportModal(true)
            }}
          >
            start credit check
          </button>
        </div>
      </div>
      {openCreditReportModal && (
        <CreditReportModal closeModal={setOpenCreditReportModal} />
      )}
    </section>
  )
}

export default CreditReport
