import { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { EMAIL_VALIDATOR } from "../../util/validators"

function EmailForm(props) {
  const [input, setInput] = useState("")
  const [emailIsValid, setEmailIsValid] = useState(true)
  const [invalidMsg, setInvalidMsg] = useState("")

  const handleBlur = () => {
    const result = EMAIL_VALIDATOR(input)
    if (! result.valid) {
      setEmailIsValid(false)
      setInvalidMsg(result.errors[0].message)
    } else {
      setEmailIsValid(true)
      setInvalidMsg("")
    }
  }

  const handleChange = (e) => {
    setInput(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (emailIsValid === true) {
      props.onSubmit({
        id: uuidv4(),
        text: input,
      })
      setInput("")
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {!emailIsValid ? (
        <p className={"text-danger"}>{invalidMsg}</p>
      ) : (
        <p></p>
      )}
      <div className="inline-input-group">
        <div className="form-group-inline">
          <input
            type="email"
            className="inline-input"
            value={input}
            name="text"
            placeholder="type an email address"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <button className="btn btn--secondary-alt btn--small">
        Add
      </button>
    </form>
  )
}

export default EmailForm
