import { useContext, useState } from "react"
import "./housingInfo.scss"
import { AuthContext } from "Context/authContext/AuthContext"

function HousingInfo(props) {
  const display = props.display
  const { user } = useContext(AuthContext)
  const [title, setTitle] = useState(display?.ownership ?? "rent")
  const [address, setAddress] = useState(
    display?.address ?? "234 Example Street, Example, Ontario"
  )
  const [length, setLength] = useState(display?.duration ?? "3 years")
  const [unit, setUnit] = useState(display?.unit_number ?? "unit 105")
  const [cost, setCost] = useState("$" + (display?.monthly_rent ?? 0))
  const [reason, setReason] = useState(
    display?.reason_for_leaving ??
      "Lorem ipsum dolor sit amet,consectetur adipisicing elit. Harumillum, in iste iusto libero omnis qui quibusdam reprehenderit? Accusantiumculpa doloribus facilis fugit hiciure, laboriosam nesciunt quaeratreprehenderit ullam."
  )

  const [updateMode, setUpdateMode] = useState(false)
  const [showData, setShowData] = useState(true)

  return (
    <section className="housing-info application-preview-container">
      <div className="info-title-container">
        <div className="info-title">
          <div className="info__label info__label--completed">
            completed
          </div>
          {Boolean(props.type == "current") && (
            <h2>current housing info</h2>
          )}
          {Boolean(props.type == "previous") && (
            <h2>previous housing info</h2>
          )}
        </div>
        <div className="info-title-controls">
         {/* {showData ? (
            <>
              {user.roles[0].name == "applicant" && (
                <button
                  className="btn btn--secondary-alt btn--small btn--shrink"
                  onClick={
                    updateMode
                      ? // CHANGE THIS TO HANDLESUBMIT?
                        () => setUpdateMode(false)
                      : //
                        () => setUpdateMode(true)
                  }
                >
                  {updateMode ? "submit" : "edit"}
                </button>
              )}
            </>
          ) : null}*/}

          <button
            className={
              showData
                ? "btn btn--toggle"
                : "btn btn--toggle btn--toggle-inverse"
            }
            onClick={() => setShowData(!showData)}
          ></button>
        </div>
      </div>

      {showData ? (
        <>
          <div className="row">
            <div className="col-md-6">
              <h5>do you own or rent?</h5>
              {updateMode ? (
                <input
                  className="inline-input"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              ) : (
                <p>{title}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {updateMode ? (
                <>
                  <h5>address</h5>
                  <input
                    className="inline-input"
                    type="text"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                  />
                </>
              ) : (
                <>
                  <h5>address</h5>
                  <p>{address}</p>
                </>
              )}
            </div>
            <div className="col-md-6">
              <h5>unit number</h5>
              {updateMode ? (
                <input
                  className="inline-input"
                  type="text"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                />
              ) : (
                <p>{unit}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h5>how many months do you want to rent for?</h5>
              {updateMode ? (
                <input
                  className="inline-input"
                  value={length}
                  onChange={(e) => {
                    if (e.target.value >= 0) setLength(e.target.value)
                  }}
                />
              ) : (
                <p>{length}</p>
              )}
            </div>
            {(display.ownership === "rent") && (<div className="col-md-6">
              <h5>how much do you pay in rent?</h5>
              {updateMode ? (
                <input
                  className="inline-input"
                  type="text"
                  value={cost}
                  onChange={(e) => setCost(e.target.value)}
                />
              ) : (
                <p>{cost}</p>
              )}
            </div>)}
          </div>
          <div className="row">
            <div className="col">
              <h5>reason for leaving</h5>
              {updateMode ? (
                <textarea
                  className="inline-input"
                  type="text"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              ) : (
                <p>{reason}</p>
              )}
            </div>
          </div>
        </>
      ) : null}
    </section>
  )
}

export default HousingInfo
