import { useState } from "react"

function Email({ emails, removeEmail }) {
  const [edit, setEdit] = useState({
    id: null,
    value: "",
  })

  return emails.map((email, index) => (
    <div
      key={index}
      className="inline-input-group"
    >
      <div className="inline input">
        <span
          onClick={() => {
            setEdit({
              id: email.id,
              value: email.text,
            })}
          }
        >
          {email.text}
        </span>
        <button
          className="btn"
          onClick={() => removeEmail(email.id)}
        >
          &#10006;
        </button>
      </div>
    </div>
  ))
}

export default Email
