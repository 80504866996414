import React from "react"
import emptyStarSrc from "../assets/svg/star.svg"
import filledStartSrc from "../assets/svg/starGreen.svg"

export function Stars({ count, rating, alt }) {
  return (
    <div aria-label={alt} title={alt}>
      <span aria-hidden>
        {[...Array(count)].map((_, i) => {
          return (
            <img
              src={i < rating ? filledStartSrc : emptyStarSrc}
              width={20}
              height={20}
              alt={alt ?? (i < rating ? "filled star" : "empty star")}
              key={i}
            />
          )
        })}
      </span>
    </div>
  )
}
