import {useState} from "react";
import RequestConfirmModal from "../RequestConfirmModal/RequestConfirmModal";

function RequestApplicantPayModal({closeModal}) {
    const [openRequestConfirmModal, setOpenRequestConfirmModal] = useState(false);

    return (
        <div className="RequestApplicantPayModal modal">
            <div className="modal__overlay"></div>
            <div className="modal__wrapper">

                <div className="modal__container">

                    <h2>please confirm that you’d like the applicant/s to pay for the advanced screening features.</h2>
                    <p className="mb-3">Note that if the applicant does not pay for the advanced features, they will be considered to have no interest in applying for the rental.</p>
                    <div className="btn-group">
                        <button
                            className="btn btn--back btn--small"
                            onClick={() =>
                                closeModal(false)
                            }
                        >
                            back
                        </button>
                        <button
                            className="btn btn--primary btn--small"
                            onClick={() => {
                                setOpenRequestConfirmModal(true);
                            }}
                        >
                            confirm
                        </button>
                    </div>
                </div>
            </div>
            {openRequestConfirmModal && (
                <RequestConfirmModal closeModal={setOpenRequestConfirmModal}/>
            )}
        </div>
    );
}

export default RequestApplicantPayModal;
