import "./landlordReferences.scss"
import TextInput from "../../../../Components/Input/TextInput"
import FileUpload from "../../../../Components/Input/FileUpload"
import { useState } from "react"
import DeleteModal from "./DeleteModal/DeleteModal"
import { useNavigate } from "react-router-dom"
import { useEffectOnce } from "Hooks/useEffectOnce"
import axios from "axios"
import config from "config/config"
import Cookies from "universal-cookie"
import { objectToFormData } from "util/objectToFormData"
import { EMAIL_VALIDATOR } from "util/validators"

function LandlordReferences() {
  const navigate = useNavigate()
  const [modal, setModal] = useState(null)
  const [referenceList, setReferenceList] = useState([])

  const [errorReporting, setErrorReporting] = useState(false)
  // LANDLORD EDIT
  const addReference = () => {
    setReferenceList((prevReferenceList) => {
      return [
        ...prevReferenceList,
        {
          isEditing: true,
          name: "",
          email: "",
          leaseFile: null,
          idFile: null,
          status: null,
          errorStatus: [],
          id: Math.random(),
        },
      ]
    })
  }
  useEffectOnce(() => {
    addReference()
  })

  const removeReference = (item) => {
    setModal(
      <DeleteModal
        onDelete={() =>
          setReferenceList(
            referenceList.filter(
              (listItem) => listItem.id !== item.id
            )
          )
        }
        onClose={() => setModal(null)}
      />
    )
  }
  return (
    <section className="LandlordReferences">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate(-1)
          }}
        >
          back
        </button>
        <h1>verified landlord references</h1>

        {/* todo: should the edit be on a different page or use the form below? */}
        {referenceList.map((item) => {
          const update = (data) => {
            setReferenceList((prevReferenceList) => {
              return prevReferenceList.map((listItem) => {
                if (listItem.id === item.id) {
                  return { ...listItem, ...data }
                }
                return listItem
              })
            })
          }
          return (
            <div
              className="LandlordReferences__references"
              key={item.id}
            >
              {!item.isEditing ? (
                <div className="LandlordReferences__reference">
                  <div className="row justify-content-end">
                    <div className="col-auto">
                      <div className="btn-group">
                        <button
                          className="btn btn--secondary-alt btn--small"
                          onClick={() => update({ isEditing: false })}
                        >
                          edit
                        </button>

                        <button className="btn btn--secondary-alt btn--small">
                          resend
                        </button>
                        <button
                          className="btn btn--danger btn--small"
                          onClick={() => {
                            removeReference(item)
                          }}
                        >
                          delete
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <h4>name of landlord</h4>
                      <p>{item.name}</p>
                    </div>
                    <div className="col-md-6">
                      <h4>email address</h4>
                      <p>{item.email}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <h4>lease</h4>
                      <p>
                        <a href="/path/to/lease" target="_blank">
                          mylease.pdf
                        </a>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <h4>status</h4>
                      {/* completed = .status--completed*/}
                      {/* incomplete = .status--incomplete*/}
                      <p>
                        <span className="status--completed">
                          completed
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="LandlordReferences__reference">
                  <div className="row justify-content-end">
                    <div className="col-auto">
                      {referenceList.length > 1 && (
                        <button
                          className="btn btn--danger btn--small"
                          onClick={() => {
                            // setOpenDeleteModal(true)
                            removeReference(item)
                          }}
                        >
                          delete
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-4 col-md-6">
                      <TextInput
                        type="text"
                        label="name of landlord"
                        name="name"
                        value={item.name}
                        onChange={(e) =>
                          update({ name: e.target.value })
                        }
                        hasError={
                          item.errorStatus.includes("name") ||
                          (errorReporting && item.name.length)
                        }
                      />
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <TextInput
                        type="text"
                        label="phone number"
                        name="phone_number"
                        value={item.phone_number}
                        onChange={(e) =>
                          update({ phone_number: e.target.value })
                        }
                        hasError={
                          item.errorStatus.includes("phone_number") ||
                          (errorReporting &&
                            item.name.replace(/[^0-9]/g, "")
                              .length !== 10)
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-4 col-md-6">
                      <TextInput
                        type="email"
                        label="email address"
                        name="email"
                        value={item.email}
                        onChange={(e) =>
                          update({ email: e.target.value })
                        }
                        hasError={
                          item.errorStatus.includes("email") ||
                          (errorReporting &&
                            !EMAIL_VALIDATOR(item.email).valid)
                        }
                      />
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <TextInput
                        type="text"
                        label="address of rental property"
                        name="address"
                        value={item.address}
                        onChange={(e) =>
                          update({ address: e.target.value })
                        }
                        hasError={item.errorStatus.includes(
                          "address"
                        )}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8">
                      <FileUpload
                        label="upload your lease"
                        name="leaseFile"
                        onChange={(fileMeta) =>
                          update({ leaseFile: fileMeta })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8">
                      <FileUpload
                        label="upload id"
                        name="idFile"
                        onChange={(fileMeta) => {
                          update({ idFile: fileMeta })
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* Saved Landlord Reference */}
              {/* {referenceList.map((id, index) => (
            <>
              <LandlordAdd
                key={index}
                id={id}
                setOpenModal={setOpenModal}
                openModal={openModal}
                removeReference={() => removeReference(id)}
              />
            </>
          ))} */}
              {/* Entering a new landlord reference
          <div className="LandlordReferences__reference">
            <div className="row justify-content-end">
              <div className="col-auto">
                <button
                  className="btn btn--danger btn--small"
                  onClick={() => {
                    setOpenDeleteModal(true)
                  }}
                >
                  delete
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <TextInput
                  type="text"
                  label="name of landlord"
                  name="name"
                />
              </div>
              <div className="col-xl-4 col-md-6">
                <TextInput
                  type="text"
                  label="phone number"
                  name="phone_number"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <TextInput
                  type="email"
                  label="email address"
                  name="email"
                />
              </div>
              <div className="col-xl-4 col-md-6">
                <TextInput
                  type="text"
                  label="address of rental property"
                  name="address"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <FileUpload
                  label="upload your lease"
                  name="lease"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <FileUpload
                  label="upload id"
                  name="id"
                />
              </div>
            </div>
          </div> */}
            </div>
          )
        })}
        <button
          className="btn btn--secondary-alt btn--small llrbtn-add-more"
          onClick={addReference}
        >
          add more
        </button>
        <div className="btn-group mt-4">
          <button
            className="btn btn--primary"
            onClick={() => {
              if (
                referenceList.findIndex((item) => {
                  return !(
                    item.name.length &&
                    item.name.length <= 100 &&
                    item.phone_number.replace(/[^0-9]/g, "")
                      .length === 10 &&
                    EMAIL_VALIDATOR(item.email).valid &&
                    item.address.length &&
                    item.leaseFile.length &&
                    item.idFile.length
                  )
                }) === -1
              ) {
                Promise.all(
                  referenceList.map((item) => {
                    const update = (data) => {
                      setReferenceList((prevReferenceList) => {
                        return prevReferenceList.map((listItem) => {
                          if (listItem.id === item.id) {
                            if (typeof data === "function")
                              return data(listItem)
                            return { ...listItem, ...data }
                          }
                          return listItem
                        })
                      })
                    }
                    return axios
                      .post(
                        `${config.BASE_URL}api/landlord-reference/request`,
                        objectToFormData({
                          email: item.email,
                          name: item.name,
                          application_id: localStorage.getItem(
                            "currentApplicationId"
                          ),
                          lease: item.leaseFile?.[0]?.file,
                        }),
                        {
                          headers: {
                            Authorization:
                              "Bearer " +
                              new Cookies().get("auth_token"),
                          },
                        }
                      )
                      .then((res) => {
                        if (res.data.status === 0) {
                          update((prevItem) => {
                            return {
                              ...prevItem,
                              errorStatus: Object.keys(
                                res.data.errors
                              ),
                            }
                          })
                          setErrorReporting(true)
                        }
                      })
                  })
                ).then(() => {
                  navigate("/tenant/premium-features")
                })
              } else {
                setErrorReporting(true)
              }
            }}
          >
            done
          </button>
        </div>
      </div>
      {modal}
    </section>
  )
}

export default LandlordReferences
