import AppRoutes from "./Routes/AppRoutes"

function App() {
  // const user = true;
  // const { user } = useContext(AuthContext);
  return (
    <div className="app">
      <>
        <AppRoutes />
      </>
    </div>
  )
}

export default App
