import { Link } from "react-router-dom"
import { AuthContext } from "../../Context/authContext/AuthContext"
import axios from "axios"
import TextInput from "Components/Input/TextInput"
import CheckboxInput from "Components/Input/CheckboxInput"
import SplashImg from "../../../src/assets/images/login-splash.jpg"
import "./login.scss"
import { useState, useContext, useRef, useEffect } from "react"
import { login } from "Context/authContext/apiCalls"
import Helmet from "react-helmet"

export default function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  // CONTEXT
  const { isFetching, error, dispatch, user } =
    useContext(AuthContext)

  const handleLogin = async (e) => {
    e.preventDefault()
    const response = await login({ email, password }, dispatch)
  }

  return (
    <section className="Login">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Smart Screening - Login - Account | Rent Panda</title>
        <meta
          name="description"
          content="Log in to get started with Rent Panda Smart Screening AI technology for landlords and tenants."
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <form onSubmit={handleLogin}>
              <div className="Login__form-container">
                <h1>log in</h1>
                <TextInput
                  type="email"
                  label="Email"
                  name="email"
                  placeholder=""
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextInput
                  type="password"
                  label="Password"
                  name="password"
                  placeholder=""
                  onChange={(e) => setPassword(e.target.value)}
                />
                <CheckboxInput
                  type="checkbox"
                  label="Remember me"
                  name="remember"
                  placeholder=""
                  // onClick={setRemember(
                  //   (remember) => !remember
                  // )}
                />

                {error && (
                  <p className="Login__error">
                    There was an error trying to sign-in. Please try
                    again!
                  </p>
                )}
                <button
                  className="btn btn--primary"
                  disabled={isFetching}
                  type="submit"
                >
                  sign in
                </button>

                <div className="login-links">
                  <p className="Login__forgot-password">
                    Forgot Password?{" "}
                    <Link to="/forgot-password" className="link-2">
                      Reset Here
                    </Link>
                  </p>
                  <p className="Login__sign-up">
                    Don't have an account?{" "}
                    <Link to="/signup" className="link-2">
                      Sign Up
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-6">
            <img
              className="Login__img"
              src={SplashImg}
              alt="Rent Panda Login"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
