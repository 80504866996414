import "./landlordReferenceThankYou.scss";

function LandlordReferenceThankYou() {
    return (
        <section className="LandlordReferenceThankYou">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <h1>thank you for leaving your reference of this tenant!</h1>
                        <p className="mb-4">
                            if you are looking for a new tenant yourself, Rent Panda’s smart
                            screening tool is the easiest and most comprehensive screening,
                            so you can find great tenants and keep your property safe.
                        </p>
                        <a href="#" className="btn btn--primary">click to learn more</a>
                    </div>
                    {/*<div className="col-lg-5 desktop-only">*/}
                    {/*    <img src="#" alt="Rent Panda"/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </section>
    );
}

export default LandlordReferenceThankYou;