import { Modal } from "Components/Modal/Modal"
import "./ChangePropertyStatusModal.jsx"
import styles from "./ChangePropertyStatusModal.module.scss"
function ChangePropertyStatusModal({ onConfirm, onCancel }) {
  return (
    <Modal maxWidth={750} minHeight={404}>
      <Modal.Body>
        <div className={styles.modalHead}>
          <h1 className={styles.text}>
            are you sure you want to deactivate this property?
          </h1>
          <p className={styles.text}>
            Tenants will no longer be able to apply but you will still
            be able to view previous applicant info.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-group">
          <button className="btn btn--primary" onClick={onConfirm}>
            yes
          </button>
          <button
            className="btn btn--secondary-alt"
            onClick={onCancel}
          >
            no
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangePropertyStatusModal
