import "./payment.scss"
import TextInput from "../../Components/Input/TextInput"
import TextAppendBtnInput from "../../Components/Input/TextAppendBtnInput"
import DatePickerInput from "../../Components/Input/DatePickerInput"
import CongratulationsModal from "./CongratulationsModal/CongratulationsModal"
import React, { useEffect, useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { PaymentElement } from "@stripe/react-stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { useNavigate } from "react-router-dom"
import Cookies from "universal-cookie"
import { CartState } from "Context/cartContext/CartContext"
import axios from "axios"
import config from "../../config/config"
import SetupForm from "./SetupForm"

// Make sure to call loadStripe outside a component's render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE)
function Payment(props) {

  const navigate = useNavigate()
  const [setupIntentId, setSetupIntentId] = useState(false)
  const [setupClientSecret, setSetupClientSecret] = useState("")
  const [paymentConfirmed, setPaymentConfirmed] = useState(false)
  const [coupon, setCoupon] = useState("")
  const [discount, setDiscount] = useState(null)
  const [couponError, setCouponError] = useState(false)
  const [couponErrorMsg, setCouponErrorMsg] = useState("")
  
  const appearance = {
    theme: "flat",
    variables: {
      colorPrimary: "#8cc947",
      colorBackground: "#ffffff",
      colorText: "#000105",
      colorDanger: "#C54A03",
      fontFamily: "Montserrat Alternates, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "16px",
    },
  }
  
  const initSetupOptions = {
    clientSecret: '',
    // Fully customizable with appearance API.
    appearance: appearance,
  }
  
  const [setupOptions, setSetupOptions] = useState(initSetupOptions)

  const initPayState = {
    preCoupon: 0,
    postCoupon: 0,
    taxAmt: 0,
    discountAmt: 0,
    finalTotal: 0,
  }

  const [paymentState, setPaymentState] = useState(initPayState)

  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  // This runs on submit, creates the intent, fullfills the intent with payment information, and shows modal.
  const {
    state: { cart, applicants },
  } = CartState()

  const cookies = new Cookies()
  const apiConfig = {
    headers: {
      Authorization: "Bearer " + cookies.get("auth_token"),
    },
  }

  async function onSetupAction() {
    // Get the cart, and applicants.
    if (
      typeof cart != "undefined" &&
      typeof applicants != "undefined" &&
      cart.length > 0 &&
      applicants.length > 0
    ) {
      let screeningRequestInformation = []
      for (var applicant in applicants) {
        if (applicants.hasOwnProperty(applicant)) {
          var applicantType = ""
          if (applicants[applicant].phone !== null)
            applicantType = "phone_number"
          if (applicants[applicant].email !== null)
            applicantType = "email"

          var screeningRequestNodeItems = []
          var applicantCartItems = cart.filter(function (el) {
            return el[0].applicantId === applicants[applicant].id
          })

          for (var applicantCartItem in applicantCartItems) {
            if (
              applicantCartItems.hasOwnProperty(applicantCartItem)
            ) {
              screeningRequestNodeItems.push(
                applicantCartItems[applicantCartItem][0].id
              )
            }
          }

          var screeningRequestNode = {
            name: applicants[applicant].name,
            type: applicantType,
            items: screeningRequestNodeItems,
          }

          if (applicantType === "email")
            screeningRequestNode.email = applicants[applicant].email
          if (applicantType === "phone_number")
            screeningRequestNode.phone_number =
              applicants[applicant].phone

          screeningRequestInformation.push(screeningRequestNode)
        }
      }

      const screeningRequestApplicationId = cookies.get(
        "current_property_application_id"
      )
      let screeningRequestApiInfo = {
        application_id: screeningRequestApplicationId,
        request_tenant_pays: false,
        screening_requests: screeningRequestInformation,
      }

      const res = await axios.post(
        `${config.BASE_URL}api/screening-request/landlord-request-items`,
        screeningRequestApiInfo,
        apiConfig
      )

      let results = res.data


      return results
    }
  }

  const runPaymentIntent = function () {
    let definedCartItems = []

    for (var item in cart) {
      if (cart.hasOwnProperty(item)) {
        var itemIdAttr = cart[item][0].id

        definedCartItems.push(itemIdAttr)
      }
    }

    axios
      .post(
        `${config.BASE_URL}api/payment/create-setup-intent`,
        {},
        apiConfig
      )
      .then(function (response) {
        if (
          response.data.status === 1 &&
          typeof response.data.setup_intent_id != "undefined"
        ) {
          setSetupOptions({
            clientSecret: response.data.client_secret,
            // Fully customizable with appearance API.
            appearance: appearance,
          });
        
          setSetupIntentId(response.data.setup_intent_id)
          setSetupClientSecret(response.data.client_secret)
        }
      })
      .catch((error) => {
        console.log("Error: ", error)
      })
  }

  const [openCongratulationsModal, setOpenCongratulationsModal] =
    useState(false)

  // (Cameron/Matt) You will need to update that payment intent when they change what items they selected
  // Here is an example from stripe
  // https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements&html-or-react=react#fetch-updates
  // (async () => {
  //     const response = await fetch('/api/payment/update-intent');
  //     if (response.status === 'requires_payment_method') {
  //         const {error} = await elements.fetchUpdates();
  //     }
  //})();

  const applyHST = (amount) => {
    return parseFloat((amount * 0.13).toFixed(2))
  }

  useEffect(() => {
    const URLSearch = new URLSearchParams(window.location.search)
    let RedirectConfirmed = URLSearch.get("redirect_status")

    if (RedirectConfirmed === "succeeded") {
      setPaymentConfirmed(true)
      setOpenCongratulationsModal(true)
    } else {

      let total = 0
      Object.keys(cart).map((item) =>
        Object.keys(cart[item]).map(
          (cartItem) =>
            (total =
              total +
              Number(cart[item][cartItem].price) *
                cart[item][cartItem].qty)
        )
      )

      if (discount !== null) {
        setPaymentState({
          ...paymentState,
          preCoupon: total,
          discountAmt: discount,
          postCoupon: total - discount,
          taxAmt: applyHST(total - discount),
          finalTotal: (total - discount + applyHST(total - discount)) ,
        })
      } else {
        setPaymentState({
          ...paymentState,
          preCoupon: total,
          postCoupon: total,
          taxAmt: applyHST(total).toFixed(2),
          finalTotal: (total + applyHST(total)).toFixed(2),
        })
      }

      // Run payment options.
      runPaymentIntent()
      processCoupon()
    }
  }, [cart])

  const COUPON_INVALID = "Coupon is invalid"

  const processCoupon = () => {
    // in localStorage access user.user_id and extract and parse it
    const userId = JSON.parse(localStorage.getItem("user")).user_id

    axios.post(  `${config.BASE_URL}api/coupon/use-coupon`,
      {
        coupon_code: coupon,
        user_id: userId,
      }, apiConfig )
      .then((response) => {
      })
      .catch((error) => {
        console.log("Error in process coupon: ", error)
      })
  }

  const getCoupon = () => {
    axios
      .get(`${config.BASE_URL}api/coupon/get-coupon?coupon_code=${coupon}`,
        apiConfig
      )
      .then((res) => {
        if (res.data.status === 1) {
          if (res.data.is_enabled === 1) {
            // Coupon is in db and is enabled
            setDiscount(res.data.coupon_amount)
          } else {
            // Coupon is in db but is not enabled
            setCouponError(true)
            setCouponErrorMsg(COUPON_INVALID)
          }
        } else {
          setCouponError(true)
          setCouponErrorMsg(res.data.errors.coupon_code[0])
        }

      })
      .catch((err) => {
        console.log("Error: ", err)
      })
  }

  return (
    <section className="Payment">
      {paymentConfirmed === false && (
        <div className="container">
          <button
            className="btn btn--back"
            onClick={() => {
              navigate(-1)
            }}
          >
            back
          </button>
          <div className="row justify-content-between">
            {/* Payment form */}
            <div className="col-xl-6">
              <h1>payment</h1>
              <h2 className="subText">your payment details</h2>
              {Boolean(props.type === "landlord") && (
                <p>
                  You will only be charged when a tenant submits their
                  application.
                </p>
              )}
              {Boolean(props.type === "tenant") && (
                <p>
                  Your payment details are kept secure and only used
                  to process this transaction.
                </p>
              )}

              <div>
                { couponError && <p className="text-danger">{couponErrorMsg}</p> }
                <TextAppendBtnInput
                  type="text"
                  label="coupon code/ referral code"
                  name="coupon"
                  btnText="Apply"
                  onChange={(e) => {
                    setCoupon(e.target.value)
                  }}
                  onClick={getCoupon}
                />

              </div>

              <div className="row">
                {setupIntentId !== false &&
                  setupClientSecret !== false &&
                  setupOptions.clientSecret !== '' && (
                    <Elements
                      stripe={stripePromise}
                      options={setupOptions}
                    >
                      <SetupForm
                        onSetupAction={onSetupAction}
                        hasError={setHasError}
                        errMsg={setErrorMessage}
                      />
                    </Elements>
                  )}
              </div>
            </div>
            {hasError && (
              <div className={"cc-err"}>{errorMessage}</div>
            )}
            {/* Order Summary */}
            <div className="col-xl-5">
              <div className="order-summary">
                <div className="order-summary__details">
                  <h2>Order Summary</h2>
                  {cart.length > 0 ? (
                    <>
                      {cart.map((feature) => (
                        <div className="order-summary__order-item">
                          <span>{feature[0].name}</span>
                          <span>${feature[0].price}</span>
                        </div>
                      ))}

                      { discount !== null && (
                        <div className="order-summary__order-item">
                          <span>coupon/ referral discount</span>
                          <span>-${paymentState.discountAmt}.00</span>
                        </div>
                      )}
                      <div className="order-summary__order-item">
                        <span>HST</span>
                        <span>${paymentState.taxAmt}</span>
                      </div>
                    </>
                  ) : (
                    "add premium features!"
                  )}
                </div>
                <div className="order-summary__total">
                  <h2>total amount</h2>
                  <h2>${paymentState.finalTotal}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {paymentConfirmed === true && openCongratulationsModal && (
        <CongratulationsModal
          closeModal={setOpenCongratulationsModal}
        />
      )}
    </section>
  )
}

export default Payment
