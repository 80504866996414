import "./tenantPremiumFeatures.scss"
import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import { useNavigate } from "react-router-dom"
import { usePremiumFlow } from "Context/flowPremiumContext/FlowPremiumContext"
import clsx from "clsx"

const premiumFeatures = [
  {
    id: "credit_check",
    name: "credit check",
    location: "/tenant/credit-report",
  },
  {
    id: "background_check",
    name: "background check",
    location: "/tenant/background-check",
  },
]
function TenantPremiumFeatures() {
  const navigate = useNavigate()
  const { hasPremiumFeature, premiumFeatureComplete } =
    usePremiumFlow()
  return (
    <section className="TenantPremiumFeatures">
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate(-1)
          }}
        >
          back
        </button>
        <ProgressBar type="tenant" step="7" />
        <h1>premium features</h1>
        <div className="TenantPremiumFeatures__container">
          {premiumFeatures
            .filter((feature) => hasPremiumFeature(feature.id))
            .map((feature) => {
              return (
                <div className="premium-feature" key={feature.id}>
                  <div className="info-title-container">
                    <div className="info-title">
                      <div
                        className={
                          "info__label info__label--" +
                          (premiumFeatureComplete(feature.id)
                            ? "completed"
                            : "incomplete")
                        }
                      >
                        {premiumFeatureComplete(feature.id)
                          ? "complete"
                          : "incomplete"}
                      </div>
                      <h2>{feature.name}</h2>
                    </div>
                    {!premiumFeatureComplete(feature.id) && (
                      <div className="info-title-controls">
                        <button
                          className="btn btn--primary btn--small"
                          onClick={() => {
                            navigate(feature.location)
                          }}
                        >
                          start {feature.name}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          {/* <div className="premium-feature">
            <div className="info-title-container">
              <div className="info-title">
                <div className="info__label info__label--incomplete">
                  incomplete
                </div>
                <h2>personalized video</h2>
              </div>
              <div className="info-title-controls">
                <button className="btn btn--primary btn--small">
                  add video
                </button>
              </div>
            </div>
          </div>	


				*/}
          {hasPremiumFeature("reference_check") && (
            <div className="premium-feature">
              <div className="info-title-container">
                <div className="info-title">
                  <div
                    className={clsx([
                      "info__label",
                      premiumFeatureComplete("reference_check")
                        ? "info__label--completed"
                        : "info__label--incomplete",
                    ])}
                    x="info__label info__label--incomplete"
                  >
                    {premiumFeatureComplete("reference_check")
                      ? "complete"
                      : "incomplete"}
                  </div>
                  <h2>verified landlord references</h2>
                </div>
                <div className="info-title-controls">
                  <button
                    className="btn btn--primary btn--small"
                    onClick={() => {
                      navigate("/tenant/landlord-references")
                    }}
                  >
                    add references
                  </button>
                </div>
              </div>
            </div>
          )}
          {(hasPremiumFeature("id_verification") ||
            hasPremiumFeature("financial_verification")) && (
            <div className="premium-feature">
              <div className="info-title-container">
                <div className="info-title">
                  <div className={clsx([
                    "info__label",
                    premiumFeatureComplete("financial_verification")
                      ? "info__label--completed"
                      : "info__label--incomplete"
                    ])}
                  >
                    {(premiumFeatureComplete("id_verification") || premiumFeatureComplete("financial_verification"))
                      ? "complete"
                      : "incomplete"}
                  </div>
                  <h2>
                    {(hasPremiumFeature("financial_verification")) && (
                      <span>automated employment / income verification</span>
                    )}
                    {(hasPremiumFeature("financial_verification") && hasPremiumFeature("id_verification")) && (
                      <span>&nbsp;&amp;&nbsp;</span>
                    )}
                    {(hasPremiumFeature("id_verification")) && (
                      <span>id verification</span>
                    )}
                  </h2>
                </div>
                {((hasPremiumFeature("id_verification") && !premiumFeatureComplete("id_verification")) || (hasPremiumFeature("financial_verification") && !premiumFeatureComplete("financial_verification"))) && (
                  <div className="info-title-controls">
                    <button
                      className="btn btn--primary btn--small"
                      onClick={() => {
                        navigate(
                          "/tenant/employment-income-verification"
                        )
                      }}
                    >
                      start verification
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="btn-group mt-3">
          <button
            className="btn btn--primary"
            onClick={() => {
              navigate("/tenant/application-preview")
            }}
          >
            next
          </button>
        </div>
      </div>
    </section>
  )
}

export default TenantPremiumFeatures
