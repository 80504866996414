import { useRandomId } from "Hooks/useRandomId"

const CheckboxInput = ({
  value,
  label,
  name,
  type,
  isMega,
  onChange,
  children,
}) => {
  // using a random id to avoid conflicts with other inputs on the page
  // since we are using react there should be no reason to have to reference the id programmatically
  const id = useRandomId()
  return (
    <div className="form-group">
      <label
        htmlFor={id}
        className={
          isMega
            ? "form-checkbox form-checkbox--mega-checkbox"
            : "form-checkbox"
        }
      >
        <input
          type={type ? type : "checkbox"}
          checked={value}
          name={name}
          id={id}
          className="form-checkbox__form-control"
          onChange={onChange}
        />
        <span>
          {label && (
            <span className="form-checkbox__label">{label}</span>
          )}
          {children && (
            <div className="form-checkbox__details">{children}</div>
          )}
          {/*  error message here  */}
        </span>
      </label>
    </div>
  )
}
export default CheckboxInput
