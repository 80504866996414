export const cartReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_TENANT_INFO":
      return {
        ...state,
        applicants: action.payload,
      }
    case "ADD_TO_CART":
      return {
        ...state,
        cart: [
          ...state.cart,
          {
            0: {
              ...action.payload,
              qty: 1,
            },
          },
        ],
      }
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter(
          (c) =>
            c[0].id !== action.payload.id ||
            c[0].applicantId !== action.payload.applicantId
        ),
      }
    case "RESET":
      return {
        cart: [],
        applicants: [],
        premiumFeatures: state.premiumFeatures,
      }
    default:
      return state
  }
}
