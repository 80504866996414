import { motion } from "framer-motion"

const PWDRequisite = ({
  capsLetterFlag,
  numberFlag,
  pwdLengthFlag,
  specialCharFlag,
}) => {
  return (
    <motion.div
      initial={{ x: -200, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ delay: 0.5, duration: 1 }}
    >
      <motion.p
        transition={{ delay: 0.7 }}
        className={capsLetterFlag}
      >
        Password Must have at least 1 Capital letter!
      </motion.p>
      <motion.p transition={{ delay: 0.9 }} className={numberFlag}>
        Password must have at least 1 Number!
      </motion.p>
      <motion.p
        transition={{ delay: 0.11 }}
        className={specialCharFlag}
      >
        Password must contain at least 1 special character!
      </motion.p>
      <motion.p
        transition={{ delay: 0.13 }}
        className={pwdLengthFlag}
      >
        Password must be at least 8 characters long!
      </motion.p>
    </motion.div>
  )
}

export default PWDRequisite
