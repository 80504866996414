import "./basicModal.scss"

function BasicModal({ setOpenBasicModal }) {
  return (
    <div className="BasicModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h1>some helpful info for you</h1>
          <h2>basic screening features</h2>

          <div className="row justify-content-between BasicModal__modal__details">
            <div className="col-lg-5">
              <h3>current housing</h3>
              <p>
                Whether the applicant owns or rents, what their
                monthly housing expenses are, how long they’ve been
                there and why they are leaving.
              </p>
            </div>
            <div className="col-lg-5">
              <h3>previous housing</h3>
              <p>
                where the applicant has lived, for how long, what
                their monthly housing expenses were and why they left
                the home/s.
              </p>
            </div>
            <div className="col-lg-5">
              <h3>employment info</h3>
              <p>
                what the applicant’s monthly income is, where they
                work/where the income is from, employment details,
                employment references.
              </p>
            </div>
            <div className="col-lg-5">
              <h3>personal and household</h3>
              <p>
                number of people in the household, pets details,
                smoking, accessibility needs, total monthly income of
                household, intended duration to rent.{" "}
              </p>
            </div>
            <div className="col-lg-5">
              <h3>guarantor info</h3>
              <p>
                guarantor personal details, contact info, income and
                identification.
              </p>
            </div>
          </div>

          <button
            className="btn btn--primary btn--small"
            onClick={() => setOpenBasicModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default BasicModal
