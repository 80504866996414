import "./viewScreeningRequests.scss"

function ViewScreeningRequests() {
    return (
        <div>
            <section className="ViewScreeningRequestsHeader">
                <div className="container">
                    <button className="btn btn--back">back</button>
                    <div className="title-container">
                        <h1>1234 panda rd, toronto</h1>
                        <button className="btn btn--edit"></button>
                    </div>
                    <button className="btn btn--primary">
                        invite more applicants
                    </button>
                </div>
            </section>
            <section className="ViewScreeningRequests">
                <div className="container">
                    <div className="row justify-content-between mb-2">
                        <div className="col-auto">
                            <h2>your pending advanced screening requests</h2>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn--secondary-alt">cancel all pending requests</button>
                        </div>
                    </div>
                    <div className="screening-request">
                        <div className="row justify-content-between align-items-center mb-2">
                            <div className="col-auto">
                                <div className="status-label">
                                    <div className="circle-check--small"></div>
                                    <span>completed</span>
                                </div>
                                <h3 className="mb-0">Ryan Han</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <h5>email</h5>
                                <p>ryan@email.com</p>
                            </div>
                            <div className="col-md-3">
                                <h5>status</h5>
                                <p>completed</p>
                            </div>
                        </div>
                        <h3 className="mt-2 mb-2">advanced features requested</h3>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="screening-request__feature">
                                    <span className="box-check"></span>
                                    <span>credit check</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="screening-request__feature">
                                    <span className="box-check"></span>
                                    <span>id verification</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="screening-request__feature">
                                    <span className="box-check"></span>
                                    <span>background check</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="screening-request__feature">
                                    <span className="box-check"></span>
                                    <span>verified landlord references</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="screening-request__feature">
                                    <span className="box-check"></span>
                                    <span>automated income & expenses verification</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="screening-request__feature">
                                    <span className="box-check"></span>
                                    <span>personalized video</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="screening-request">
                        <div className="row justify-content-between align-items-center mb-2">
                            <div className="col-auto">
                                <div className="status-label status-label--inactive">
                                    <span>pending</span>
                                </div>
                                <h3 className="mb-0">Jesse Pinkman</h3>
                            </div>
                            <div className="col-auto">
                                <button className="btn btn--small btn--shrink btn--secondary-alt">cancel request</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <h5>email</h5>
                                <p>jesse@email.com</p>
                            </div>
                            <div className="col-md-3">
                                <h5>status</h5>
                                <p>pending</p>
                            </div>
                        </div>
                        <h3 className="mt-2 mb-2">advanced features requested</h3>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="screening-request__feature">
                                    <span className="box-check"></span>
                                    <span>credit check</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="screening-request__feature">
                                    <span className="box-check"></span>
                                    <span>id verification</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="screening-request__feature">
                                    <span className="box-check"></span>
                                    <span>background check</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="screening-request__feature">
                                    <span className="box-check"></span>
                                    <span>verified landlord references</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="screening-request__feature">
                                    <span className="box-check"></span>
                                    <span>automated income & expenses verification</span>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="screening-request__feature">
                                    <span className="box-check"></span>
                                    <span>personalized video</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}
export default ViewScreeningRequests