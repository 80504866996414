import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';

const SetupForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  // The original "error" this dealt with isn't useful anymore.
  // only relevant for line 29 to an empty conditional.
  const [error, setError] = useState(null);

  async function runSaveCreditCard(event) {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    props.onSetupAction().then(function() {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }

    stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: window.location.protocol + '//' + window.location.host + '/landlord/screen-applicants/payment',
        }
      })
      .then((result) => {
        // This is the only code I can discern that actually catches an error
        if (result.error) {
          props.hasError(true)
          props.errMsg(result.error.message)
        }
      })

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
      } else {
        props.hasError(false)
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    });
  }

  return (
    <form onSubmit={runSaveCreditCard}>
      <PaymentElement />
      <div className="btn-group mt-2">
          <button
            className="btn btn--primary"
          >
            pay now
          </button>
        </div>
      {/* Show error message to your customers */}
    </form>
  )
};

export default SetupForm;