import "./employment.scss"
import { forwardRef, useEffect, useState } from "react"
import ChooseInput from "../../../../Components/Input/ChooseInput"
import TextInput from "../../../../Components/Input/TextInput"
import DatePickerInput from "../../../../Components/Input/DatePickerInput"
import FileUpload from "../../../../Components/Input/FileUpload"
import Trash from "../../../../assets/svg/trash.svg"
import { EMAIL_VALIDATOR } from "util/validators"

function bindEvent(
  value,
  setter,
  hasBeenSubmited,
  validator = "empty"
) {
  let _hasError =
    (validator === "empty" && value.length === 0) ||
    (validator === "email" && !EMAIL_VALIDATOR(value.trim()).valid) ||
    (validator === "phone" &&
      value.replace(/[^0-9]/g, "").length !== 10)
  return {
    onChange(e) {
      if (typeof e === "object") {
        setter(e.target.value)
      } else {
        setter(e)
      }
    },
    value,
    hasError: _hasError && hasBeenSubmited,
    _hasError,
  }
}
function Employment(props, ref) {
  const data = props.data
  //   const [currentStatus, setCurrentStatus] =
  //     useState("social_assistance")
  const [currentStatus, setCurrentStatus] = useState(
    data.current_status ?? ""
  )
  const [jobTitle, setJobTitle] = useState(data.job_title ?? "")
  const [employer, setEmployer] = useState(data.employer ?? "")
  const [duration, setDuration] = useState(data.duration ?? "")

  const [referenceName, setReferenceName] = useState(
    data.employment_references?.[0]?.name ?? ""
  )
  const [referencePhone, setReferencePhone] = useState(
    data.employment_references?.[0]?.phone_number ?? ""
  )
  const [referenceEmail, setReferenceEmail] = useState(
    data.employment_references?.[0]?.email ?? ""
  )

  // Unclear about how else to deal with the past employment data NOT accepting a salary field
  const [salary, setSalary] = useState(data.salary ?? "0")
  const [startDate, setStartData] = useState(data.start_date ?? "")
  const [endDate, setEndDate] = useState(data.end_date ?? "")
  // TODO: how should this be handled with a default value as its a file. should it be left empty?
  const [proofOfIncomeFile, setProofOfIncomeFile] = useState(null)
  const [salaryNotes, setSalaryNodes] = useState(
    data.salary_notes ?? ""
  )
  const [hasBeenSubmited, setHasBeenSubmited] = useState(
    props?.hasBeenSubmited || false
  )
  const boundJobTitle = bindEvent(
    jobTitle,
    setJobTitle,
    hasBeenSubmited
  )
  const boundEmployer = bindEvent(
    employer,
    setEmployer,
    hasBeenSubmited
  )
  const boundDuration = bindEvent(
    duration,
    setDuration,
    hasBeenSubmited
  )
  const boundReferenceName = bindEvent(
    referenceName,
    setReferenceName,
    hasBeenSubmited
  )
  const boundReferencePhone = bindEvent(
    referencePhone,
    setReferencePhone,
    hasBeenSubmited,
    "phone"
  )
  const boundReferenceEmail = bindEvent(
    referenceEmail,
    setReferenceEmail,
    hasBeenSubmited,
    "email"
  )
  const boundSalary = bindEvent(salary, setSalary, hasBeenSubmited)
  const boundStartDate = bindEvent(
    startDate,
    setStartData,
    hasBeenSubmited
  )
  const boundEndDate = bindEvent(
    endDate,
    setEndDate,
    hasBeenSubmited,
    "empty"
  )
  const boundSalaryNotes = bindEvent(
    salaryNotes,
    setSalaryNodes,
    hasBeenSubmited
  )
  useEffect(() => {
    if (ref) {
      props.rerender()
      const validations = {
        full_time: [
          boundSalary,
          boundJobTitle,
          boundEmployer,
          boundStartDate,
          boundReferenceName,
          boundReferenceEmail,
          boundReferencePhone,
        ],
        part_time: [
          boundSalary,
          boundJobTitle,
          boundEmployer,
          boundStartDate,
          boundReferenceName,
          boundReferenceEmail,
          boundReferencePhone,
        ],
        social_assistance: [boundSalary, boundSalaryNotes],
        retired: [boundSalary, boundSalaryNotes],
        not_employed: [boundSalary],
      }
      ref.current = {
        getData() {
          return {
            is_past: Number(props.type === "past"),
            current_status: currentStatus,
            salary: parseInt(salary),
            salary_notes: salaryNotes,
            job_title: jobTitle,
            employer: employer,
            start_date: startDate,
            end_date: endDate,
            proof_of_income: proofOfIncomeFile,
            references: [
              {
                name: referenceName,
                email: referenceEmail,
                phone_number: referencePhone,
              },
            ],
            // application_id
          }
        },
        getId() {
          return data.id
        },
        hasErrors() {
          if (props.type === "past") {
            return [
              boundJobTitle,
              boundEmployer,
              boundStartDate,
              boundEndDate,
              boundReferenceName,
              boundReferenceEmail,
              boundReferencePhone,
            ].some(({ _hasError }) => _hasError)
          } else {
            return (
              currentStatus === "" ||
              validations[currentStatus].some(
                ({ _hasError }) => _hasError
              )
            )
          }
        },
        setHasBeenSubmited,
      }
    }
  }, [
    currentStatus,
    duration,
    employer,
    jobTitle,
    proofOfIncomeFile,
    props.type,
    ref,
    referenceEmail,
    referenceName,
    referencePhone,
    salary,
    salaryNotes,
    startDate,
    endDate,
    data,
    boundDuration,
    boundSalary,
    boundJobTitle,
    boundEmployer,
    boundStartDate,
    boundEndDate,
    boundReferenceName,
    boundReferenceEmail,
    boundReferencePhone,
    boundSalaryNotes,
    props,
  ])
  return (
    <div className="EmploymentInfo__box">
      {/* PAST EMPLOYMENT ------- */}
      {Boolean(props.type === "past") && (
        <div className="employmentContainer">
          <h4>past employment info</h4>
          <button
            className="btn trash"
            onClick={() => props.removePastJob(props.id)}
          >
            <img src={Trash} alt="remove job" />
          </button>
          <input type="hidden" name="is_past" value="1" />
          <div className="row">
            <div className="col-xl-4 col-md-6">
              <TextInput
                type="text"
                label="what was your previous job title?"
                name="job_title"
                {...boundJobTitle}
              />
            </div>
            <div className="col-xl-4 col-md-6">
              <TextInput
                type="text"
                label="who was your employer?"
                name="employer"
                {...boundEmployer}
              />
            </div>
            <div className="col-xl-4 col-md-6">
              <DatePickerInput
                label="Start Date"
                name="start_date"
                {...boundStartDate}
              />
            </div>
            <div className="col-xl-4 col-md-6">
              <DatePickerInput
                label="End Date"
                name="end_date"
                {...boundEndDate}
              />
            </div>
          </div>

          <div className="EmploymentInfo__reference">
            <label>provide a reference for this job</label>

            <div className="row">
              <div className="col-md-4">
                <TextInput
                  type="text"
                  name="reference_name"
                  placeholder="name"
                  {...boundReferenceName}
                />
              </div>
              <div className="col-md-4 col-sm-6">
                <TextInput
                  type="text"
                  name="reference_phone"
                  placeholder="phone number"
                  {...boundReferencePhone}
                />
              </div>
              <div className="col-md-4 col-sm-6">
                <TextInput
                  type="email"
                  name="reference_email"
                  placeholder="email address"
                  {...boundReferenceEmail}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* CURRENT EMPLOYMENT ------- */}
      {Boolean(props.type === "current") && (
        <div className="employmentContainer">
          <h4>current employment info</h4>
          <button
            className="btn trash"
            onClick={() => props.removeJob(props.id)}
          >
            <img src={Trash} alt="remove job" />
          </button>
          <input type="hidden" name="is_past" value="0" />

          <div className="form-group">
            <label>what is your current employment status?</label>
            <div className="form-choose-group current-employment">
              <button
                id="full_time"
                // label="full time"
                // name="current_status"
                // value="full_time"

                className={
                  currentStatus === "full_time"
                    ? "choose-input-group label active"
                    : "choose-input-group label"
                }
                onClick={(e) => {
                  setCurrentStatus(e.target.id)
                  e.preventDefault()
                }}
              >
                full time
              </button>

              <button
                id="part_time"
                // label="full time"
                // name="current_status"
                // value="full_time"

                className={
                  currentStatus === "part_time"
                    ? "choose-input-group label active"
                    : "choose-input-group label"
                }
                onClick={(e) => {
                  setCurrentStatus(e.target.id)
                  e.preventDefault()
                }}
              >
                part time
              </button>
              <button
                id="social_assistance"
                // label="full time"
                // name="current_status"
                // value="full_time"

                className={
                  currentStatus === "social_assistance"
                    ? "choose-input-group label active"
                    : "choose-input-group label"
                }
                onClick={(e) => {
                  setCurrentStatus(e.target.id)
                  e.preventDefault()
                }}
              >
                social assistance
              </button>
              <button
                id="retired"
                // label="full time"
                // name="current_status"
                // value="full_time"

                className={
                  currentStatus === "retired"
                    ? "choose-input-group label active"
                    : "choose-input-group label"
                }
                onClick={(e) => {
                  setCurrentStatus(e.target.id)
                  e.preventDefault()
                }}
              >
                retired
              </button>
              <button
                id="not_employed"
                // label="full time"
                // name="current_status"
                // value="full_time"

                className={
                  currentStatus === "not_employed"
                    ? "choose-input-group label active"
                    : "choose-input-group label"
                }
                onClick={(e) => {
                  setCurrentStatus(e.target.id)
                  e.preventDefault()
                }}
              >
                not employed
              </button>
            </div>
          </div>

          {/* Full Time & Part Time*/}
          {/* REWROTE W/ REACT TERNARY */}
          {currentStatus === "full_time" && (
            <>
              <div className="row">
                <div className="col-xl-4 col-md-6">
                  <TextInput
                    type="number"
                    label="what's your monthly income for this job?"
                    name="salary"
                    {...boundSalary}
                  />
                </div>
                <div className="col-xl-4 col-md-6">
                  <TextInput
                    type="text"
                    label="what is your current job title?"
                    name="job_title"
                    {...boundJobTitle}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-md-6">
                  <TextInput
                    type="text"
                    label="who is your employer?"
                    name="employer"
                    {...boundEmployer}
                  />
                </div>
                <div className="col-xl-4 col-md-6">
                  <DatePickerInput
                    label="when did you start at this job?"
                    name="start_date"
                    {...boundStartDate}
                  />
                </div>
              </div>
               <FileUpload
                label="upload your proof of income."
                name="proof_of_income"
                onChange={(files) =>
                  setProofOfIncomeFile(files[0]?.file)
                }
              />

              <div className="EmploymentInfo__reference">
                <label>provide a reference for this job</label>
                <div className="row">
                  <div className="col-md-4">
                    <TextInput
                      type="text"
                      name="reference_name"
                      placeholder="name"
                      {...boundReferenceName}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <TextInput
                      type="text"
                      name="reference_phone"
                      placeholder="phone number"
                      {...boundReferencePhone}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <TextInput
                      type="email"
                      name="reference_email"
                      placeholder="email address"
                      {...boundReferenceEmail}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {currentStatus === "part_time" && (
            <>
              <div className="row">
                <div className="col-xl-4 col-md-6">
                  <TextInput
                    type="number"
                    label="what's your monthly income for this job?"
                    name="salary"
                    {...boundSalary}
                  />
                </div>
                <div className="col-xl-4 col-md-6">
                  <TextInput
                    type="text"
                    label="what is your current job title?"
                    name="job_title"
                    {...boundJobTitle}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-md-6">
                  <TextInput
                    type="text"
                    label="who is your employer?"
                    name="employer"
                    {...boundEmployer}
                  />
                </div>
                <div className="col-xl-4 col-md-6">
                  <DatePickerInput
                    label="when did you start at this job?"
                    name="start_date"
                    {...boundStartDate}
                  />
                </div>
              </div>
               <FileUpload
                label="upload your proof of income (ex. pay stub, letter of employment, etc.)"
                name="proof_of_income"
                onChange={(files) =>
                  setProofOfIncomeFile(files[0]?.file)
                }
              />

              <div className="EmploymentInfo__reference">
                <label>provide a reference for this job</label>
                <div className="row">
                  <div className="col-md-4">
                    <TextInput
                      type="text"
                      name="reference_name"
                      placeholder="full name"
                      {...boundReferenceName}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <TextInput
                      type="text"
                      name="reference_phone"
                      placeholder="phone number"
                      {...boundReferencePhone}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <TextInput
                      type="email"
                      name="reference_email"
                      placeholder="email address"
                      {...boundReferenceEmail}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Social Assistance & Retired*/}
          {currentStatus === "retired" && (
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <TextInput
                  type="number"
                  label="what is your monthly income?"
                  name="salary"
                  {...boundSalary}
                />
              </div>
              <div className="col-xl-4 col-md-6">
                <TextInput
                  type="text"
                  label="Notes"
                  name="salary_notes"
                  {...boundSalaryNotes}
                />
              </div>
            </div>
          )}

          {currentStatus === "social_assistance" && (
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <TextInput
                  type="number"
                  label="what is your monthly income from all sources of assistance?"
                  name="salary"
                  {...boundSalary}
                />
              </div>
              <div className="col-xl-4 col-md-6">
                <TextInput
                  type="text"
                  label="Notes"
                  name="salary_notes"
                  {...boundSalaryNotes}
                />
              </div>
            </div>
          )}
          {/* Not Employed */}
          {currentStatus === "not_employed" && (
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <TextInput
                  type="number"
                  label="what is your monthly income?"
                  name="salary"
                  {...boundSalary}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default forwardRef(Employment)
