import { useEffect, useState } from "react"
import Number from "./Number"
import NumberForm from "./NumberForm"

function NumberList({props}) {
  const [numbers, setNumbers] = useState([])

  useEffect(() => {
    if (numbers !== []) {
      let numbersData = numbers.map((number) => number.text)
      props("nums", numbersData)
    }
  }, [numbers])

  const addNumber = (number) => {
    if (
      !number.text ||
      /^\s*$/.test(number.text)
    ) {
      return
    }
    const newNumbers = [number, ...numbers]

    setNumbers(newNumbers)
    console.log(number, ...numbers)
  }

  const removeNumber = (id) => {
    const removeArr = [...numbers].filter(
      (number) => number.id !== id
    )
    setNumbers(removeArr)
  }

  return (
    <div>
      <Number
        numbers={numbers}
        removeNumber={removeNumber}
      />
      <NumberForm onSubmit={addNumber} />
    </div>
  )
}

export default NumberList
