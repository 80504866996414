import { useNavigate } from "react-router-dom"
import EmploymentIncomeVerification from "../EmploymentIncomeVerification"

function EmploymentIncomeVerificationConfirmModal({
  setOpenEmploymentIncomeVerificationConfirmModal,
}) {
  const navigate = useNavigate()
  return (
    <div className="EmploymentIncomeVerificationConfirmModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2>
            your information is being pulled. please check back
            shortly for the results (typically takes 2-3 minutes)
          </h2>

          <div className="btn-group">
            <button
              className="btn btn--primary"
              onClick={() => {
                navigate("/tenant/premium-features")
              }}
            >
              confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmploymentIncomeVerificationConfirmModal
