import CartContext from "Context/cartContext/CartContext"
import React from "react"
import ReactDOM from "react-dom"
import App from "./app"
import { AuthContextProvider } from "./Context/authContext/AuthContext"
// ES module-style import
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: '2218c7d36055e05501c45acc224f3815',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: [ 'production', 'staging' ]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

ReactDOM.render(
  <React.StrictMode>
    <>
      <ErrorBoundary>
        <AuthContextProvider>
          <CartContext>
            <App />
          </CartContext>
        </AuthContextProvider>
      </ErrorBoundary>
    </>
  </React.StrictMode>,
  document.getElementById("root")
)
