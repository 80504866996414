
function DeleteAccountModal({closeModal}) {
    return (
        <div className="DeleteAccountModal modal">
            <div className="modal__overlay"></div>
            <div className="modal__wrapper">
                <div className="modal__container">
                    <h2>delete my account</h2>

                    <p>If you’d like your account and information deleted, please send an email to <a href="mailto:hello@rentpanda.ca">hello@rentpanda.ca </a>
                        with the subject line “Delete My Account” and your account will be deleted within 48 hours.</p>

                    <button
                        className="btn btn--primary btn--small"
                        onClick={() =>
                            closeModal(false)
                        }
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DeleteAccountModal;