import { useNavigate } from "react-router-dom"

function RequestConfirmModal({ closeModal }) {
  const navigate = useNavigate()

  return (
    <div className="RequestConfirmModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2 className="mb-3">
            all sent! we will notify you by email when there is an application ready to review.
          </h2>
          <div className="btn-group">
            <button
              className="btn btn--primary btn--small"
              onClick={() =>
                navigate("/landlord/dashboard")
              }
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestConfirmModal
