function InviteContactReminder({ props }) {
  return (
    <div className="VerificationModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className={"small_modal"}>
          <div className="modal__container">
            <h2>just a quick reminder</h2>

            <div className="textContainer">
              <p>Important: make sure to sign up with the same email / phone number that the invitation to apply was sent to.</p>
            </div>

            <button className="btn btn--primary"
                    onClick={() => props(false)}>
              Got it
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviteContactReminder;