import "./forgotPassword.scss"
import { useState } from "react"
import config from "../../config/config"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import Cookies from "universal-cookie"
import Helmet from "react-helmet"

function ForgotPassword() {
  // STATE

  const [email, setEmail] = useState("")
  const [focused, setFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errMsg, setErrMsg] = useState(false)
  const [noUser, setNoUser] = useState(false)
  const navigate = useNavigate()

  // API

  const cookies = new Cookies()

  const apiConfig = {
    headers: {
      Authorization: "Bearer " + cookies.get("auth_token"),
    },
  }

  // FUNCTIONS
  const handleFocus = (e) => {
    setFocused(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      const res = await axios.post(
        `${config.BASE_URL}api/forgot-password?email=${email}`,
        {},
        apiConfig
      )
      if (res.status == 200) {
        setIsLoading(false)
        setSuccess(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="forgotPassword">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Smart Screening - Forgot Password | Rent Panda</title>
        <meta
          name="description"
          content="Let's reset your password for Rent Panda Smart Screening."
        />
      </Helmet>
      <section className="ForgotPasswordHeader">
        <div className="container">
          <button
            className="btn btn--back"
            onClick={() => navigate(-1)}
          >
            back
          </button>
        </div>
      </section>
      <section className="ForgotPasswordInfo">
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="ForgotPassword__container">
              <h2>Let's reset your password!</h2>
              <div className="row">
                <div className="col-lg-4">
                  <label htmlFor="email">email address</label>
                  <input
                    className="form_group"
                    type="email"
                    label="email address"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    onBlur={handleFocus}
                    value={email}
                    focused={focused.toString()}
                  />
                  {success && (
                    <span className="loadingMessage success">
                      Check your email to reset your password!
                    </span>
                  )}
                  {isLoading && (
                    <span className="loadingMessage">
                      hang on a second...
                    </span>
                  )}
                  {errMsg && (
                    <span className="errorMessage">
                      Must be a valid email address!
                    </span>
                  )}
                  {noUser && (
                    <span className="errorMessage">
                      we're sorry... there is no account with that
                      email
                    </span>
                  )}
                </div>
              </div>
              <div className="btn-group">
                {success ? null : (
                  <button
                    className="btn btn--primary btn--small"
                    type="submit"
                  >
                    submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  )
}

export default ForgotPassword
