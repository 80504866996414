import { useNavigate } from "react-router-dom"
import "./premiumLocked.scss"

function PremiumLocked(props) {
  const navigate = useNavigate()

  return (
    <section className="premium-feature-locked application-preview-container">
      <div className="title-container">
        <div className="premium-feature-locked__icon"></div>
        <h5 className="premium-feature-locked__title">
          this premium feature is locked
        </h5>
      </div>
      <h2>{props.title}</h2>
      <p>{props.description}</p>
      <button
        className="btn btn--accent"
        onClick={() => {
          navigate(
            `/landlord/screen-applicants?${Object.entries({
              name: props.meta.name,
              contact_type: props.meta.contact_type,
              contact_info: props.meta.contact_info,
              selected_features:
                props.meta.selected_features.join(","),
              hide_add_more: true,
            })
              .map(
                ([key, value]) =>
                  `${key}=${btoa(JSON.stringify(value))}`
              )
              .join("&")}`
          )
        }}
      >
        purchase
      </button>
    </section>
  )
}

export default PremiumLocked
