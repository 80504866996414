import React, { useCallback, useEffect, useState } from "react"
import ReactDOM from "react-dom"
// Import React FilePond
import { FilePond, File, registerPlugin } from "react-filepond"

// Import FilePond styles
import "filepond/dist/filepond.min.css"

// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import { useFnRef } from "Hooks/useFnRef"

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview
)

function FileUpload({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
}) {
  const [files, setFiles] = useState([])

  const onchange = useFnRef(onChange)
  useEffect(() => {
    if (files.length > 0) {
      onchange(files)
    }
  }, [files, onchange])
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <div className="form-group__file-upload-container">
        <FilePond
          files={files}
          allowMultiple={true}
          server={{
            load: (source, load, error, progress, abort, headers) => {
              var myRequest = new Request(source)
              fetch(myRequest).then(function (response) {
                response.blob().then(function (myBlob) {
                  load(myBlob)
                })
              })
            },
          }}
          onupdatefiles={setFiles}
          name="files"
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        />
      </div>
      {/*  error message here  */}
    </div>
  )
}

export default FileUpload
