import "./coApplicants.scss"
import { useEffect, useState } from "react"
import Carousel from "../../Carousel/Carousel"


function CoApplicants({
  coapplicants,
  editable,
  coreData,
  previousUser,
  setPreviousUser,
  userId
}) {
  const [showData, setShowData] = useState(true)

  return (
      <section className="CoApplicants application-preview-container">
        <div className="info-title-container">
          <div className="info-title">
            <h4>co-applicants / co-tenants ({coapplicants.length})</h4>
          </div>
          <div className="info-title-controls">
            <button
              className={
                showData
                  ? "btn btn--toggle"
                  :  "btn btn--toggle btn--toggle-inverse"
              }
              onClick={() => setShowData(!showData)}
            />
          </div>
        </div>
        { showData && (
          <div className="CoApplicants__container">
            <Carousel coreData={coreData} coapplicants={coapplicants}
                      previousUser={previousUser}
                      setPreviousUser={setPreviousUser}
                      userId={userId}
            />
          </div>
        )}

      </section>
  )
}

export default CoApplicants
