import "./MultiRangeSlider.scss"
import { useState, useEffect, useCallback, useRef } from "react"


/**
 * @param hasDouble zero
 *
 * @param cosmeticDefaultVal Do not include if you want the slider to be functional
 *
 * @summary The above to params are to be used if you still want the slider to display, but be set with custom values, The slider will not be functional.
 */
function MultiRangeSlider({
  min,
  max,
  prevValues = null,
  currency = false,
  name,
  onChange,
  onBlur,
  hasDoubleZero = false,
  cosmeticDefaultVal= null,
}) {
  // NOTE: This component is designed to keep track of previous state.
  const [rangeClassName, setRangeClassName] = useState("")
  const [minVal, setMinVal] = useState(min)
  const [maxVal, setMaxVal] = useState(max)

  const minValRef = useRef(null)
  const maxValRef = useRef(null)
  const range = useRef(null)

  const getPercent = useCallback((value) => {
    return Math.round(((value - min) / (max - min)) * 100)
  }, [min, max]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal)
      const maxPercent = getPercent(+maxValRef.current.value)

      if (range.current) {
        range.current.style.left = `${minPercent}%`
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value)
      const maxPercent = getPercent(maxVal)

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [maxVal, getPercent])


  useEffect(() => {
    const test = (minVal > max - 100) ? "thumb--zindex-3" : "thumb--zindex-5"
    setRangeClassName(test)
    onChange({ min: minVal, max: maxVal })
  }, [minVal, maxVal, onChange])

  useEffect(() => {
    // This allows the filter to track the previous values
    setMinVal((prevValues && prevValues.min) || min)
    setMaxVal((prevValues && prevValues.max) || max)
  }, [])

  const formatValues = (value) => {
    if (currency) {
      return `$ ${new Intl.NumberFormat("en-US").format(value)}`
    }
    return value
  }

  return (
    <div className={"multi-range-filter-container"}>
      <div className="slider__values__container">
        <div className="slider__left-value">
          { hasDoubleZero ? formatValues(cosmeticDefaultVal.min) : formatValues(minVal) } -
        </div>
        <div className="slider__right-value">
          { hasDoubleZero ? formatValues(cosmeticDefaultVal.max) :formatValues(maxVal) }
        </div>
      </div>
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          ref={minValRef}
          name={name}
          className={`thumb ${rangeClassName}`}
          onChange={(event) => {
            const value = Math.min(+event.target.value, maxVal - 1)
            setMinVal(value);
            event.target.value = value.toString()
          }}
          onBlur={onBlur}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          ref={maxValRef}
          name={name}
          className="thumb thumb--zindex-4"
          onChange={(event) => {
            const value = Math.max(+event.target.value, minVal + 1)
            setMaxVal(value);
            event.target.value = value.toString()
          }}
          onBlur={onBlur}
        />
        <div className="slider">
          <div className="slider__track" />
          <div ref={range} className="slider__range" />
        </div>
    </div>
  )
}

export default MultiRangeSlider