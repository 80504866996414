import "./betaNotifyModal.scss"

function BetaNotifyModal({ props }) {
  return (
    <div className="VerificationModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2>You're Entering the Smart Screening BETA Test</h2>

          <div className="textContainer">
            <p>As one of the first guinea pigs, you may encounter some bugs in the system and a less than perfect experience.
              Please send us an email to hello@rentpanda.ca with any problems you encounter and our team will fix them during these early days of testing.
            </p>
          </div>

          <button className="btn btn--primary"
                  onClick={() => props(false)}>
            Got it
          </button>
        </div>
      </div>
    </div>
  );
}

export default BetaNotifyModal;