import { useNavigate } from "react-router-dom"
import "./coapplicantConfirmInvitation.scss"

function CoapplicantConfirmInvitation() {
  const navigate = useNavigate()
  return (
    <section className="CoapplicantConfirmInvitation">
      <div className="container">
        <h1>
          you’ve been invited by -full name- to apply to -address- as a co-applicant.
        </h1>
        <div className="row">
          <div className="col-lg-8">
            <h4 className="mb-3">
              getting started is easy. you’ll complete all your application information online, submitting it to the landlord in a safe and secure way.
            </h4>

            <div className="row">
              <div className="col-auto">
                <button
                  className="btn btn--secondary-alt"
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  reject invitation
                </button>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn--primary"
                  onClick={() => {
                    navigate(
                      "/tenant/current-housing"
                    )
                  }}
                >
                  get started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CoapplicantConfirmInvitation
