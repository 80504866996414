import { useState } from "react"
import { useNavigate } from "react-router-dom"
import "./verifyEmailModal.scss"

function VerifyEmailModal({ userEmail }) {
  //   openModal,
  //   setOpenModal,

  const [openModal, setOpenModal] = useState(false)
  let navigate = useNavigate()

  return (
    <div className="VerifyEmailModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className="modal__container">
          <h2>please verify your email</h2>

          <div className="VerifyEmailModal__resend-message">
            <span className="circle-check--small"></span>
            <span>we've just re-sent your verification code</span>
          </div>
          <label className="mb-1">
            Please enter the verification code sent to {userEmail}
          </label>
          {/* *** Cameron check out at this codepen to make this work. ************************************ */}
          {/* https://codepen.io/davebitter/pen/VweaZqY */}
          <fieldset
            className="code-form-group"
            name="number-code"
            data-number-code-form
          >
            <input
              type="number"
              min="0"
              max="9"
              name="number-code-0"
              data-number-code-input="0"
              required
            />
            <input
              type="number"
              min="0"
              max="9"
              name="number-code-1"
              data-number-code-input="1"
              required
            />
            <input
              type="number"
              min="0"
              max="9"
              name="number-code-2"
              data-number-code-input="2"
              required
            />
            <input
              type="number"
              min="0"
              max="9"
              name="number-code-3"
              data-number-code-input="3"
              required
            />
          </fieldset>
          <p className="VerifyEmailModal__resend">
            <small>
              Didn't get the code?{" "}
              <button className="btn">Resend</button>
            </small>
          </p>
          <div className="btn-group">
            <button
              className="btn btn--primary"
              onClick={() => {
                navigate("/landlord/application")
              }}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmailModal
