
function HowWeRankModal({closeModal}) {
    return (
        <div className="HowWeRankModal modal">
            <div className="modal__overlay"></div>
            <div className="modal__wrapper">
                <div className="modal__container">
                    <h1>some helpful info for you</h1>
                    <h2>how we rank applicants</h2>

                    <p>Applicants are ranked based on 51 individual inputs within their application, with a AI powered smart
                        weighting system that brings applicants with a lower risk of potential rent arrears, tenant caused damage
                        and problematic behaviours to the top of your recommended tenant list. </p>

                    <button
                        className="btn btn--primary btn--small"
                        onClick={() =>
                            closeModal(false)
                        }
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default HowWeRankModal;
