import {
  createContext,
  useContext,
  useReducer,
  useEffect,
} from "react"
import { cartReducer } from "./CartReducer"
//import config from "../../../config/config"
//import HandleLoginRedirect from "../../../Hooks/HandleLoginRedirect";
//import Cookies from 'universal-cookie';

const Cart = createContext()

const CartContext = ({ children }) => {
  /*const premiumFeatures = useState([]); */
  const premiumFeatures = [
    {
      name: "credit check",
      id: "credit_check",
      price: "15.00",
    },
    //! disabled as part of RS-14
    {
      name: "id verification",
      id: "id_verification",
      price: "4.00",
    },
    {
      name: "background check",
      id: "background_check",
      price: "6.00",
    },
    {
      name: "verified landlord references",
      id: "reference_check",
      price: "1.00",
    },
    // {
    //   name: "personalized video",
    //   id: "video",
    //   price: "1.00",
    // },
    //! disabled as part of RS-14
    {
      name: "automated income & expenses verification",
      id: "financial_verification",
      price: "10.00",
    },
  ]
  useEffect(() => {
    /*
    let apiConfig = {
      headers: {
        'Authorization': 'Bearer ' + cookies.get('auth_token')
      }
    }
    
    const res = axios.get(
      `${config.BASE_URL}api/property?sort_field=created_at&sort_asc=1`,
      apiConfig
    ).then(function (response) {
      // @todo
      if(response.data.status == 0 && typeof response.data.authed != 'undefined' && response.data.authed == false) {
        HandleLoginRedirect();
      } else {
        // Results are in: response.data.data
        setProperties(response.data.data);
      }
    })
    */
  })

  const [state, dispatch] = useReducer(cartReducer, {
    premiumFeatures: premiumFeatures,
    cart: [],
    applicants: [],
    //   JSON.parse(
    //     localStorage.getItem("cart")
    //   ) || [],
  })
  // useEffect(() => {
  //   localStorage.setItem("cart", JSON.stringify(state.cart), [
  //     state.cart,
  //   ])
  // })

  return (
    <Cart.Provider value={{ state, dispatch }}>
      {children}
    </Cart.Provider>
  )
}

export default CartContext

export const CartState = () => {
  return useContext(Cart)
}
