import "./progress-bar.scss"
import { useState, useEffect } from "react"

function ProgressBar(props) {
  const [progressBarType, setProgressBarType] = useState("landlord") // default to landlord
  const [currentStep, setCurrentStep] = useState(1)

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (props.type) {
      switch (props.type) {
        default:
          setProgressBarType("landlord")
        case "landlord":
        case "tenant":
          setProgressBarType(props.type)
          break
      }
    }

    if (props.step) {
      setCurrentStep(props.step)
    }
  }, [props])

  return (
    <section className="ProgressBar">
      {progressBarType == "landlord" && (
        <div className="ProgressBar__container">
          <div
            className={
              currentStep > 1
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 1
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">1</div>
            <div className="ProgressBar__item__label">sign up</div>
          </div>
          <div
            className={
              currentStep > 2
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 2
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">2</div>
            <div className="ProgressBar__item__label">
              rental info
            </div>
          </div>
          <div
            className={
              currentStep > 3
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 3
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">3</div>
            <div className="ProgressBar__item__label">
              choose path
            </div>
          </div>
          <div
            className={
              currentStep > 4
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 4
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">4</div>
            <div className="ProgressBar__item__label">customize</div>
          </div>
          <div
            className={
              currentStep > 5
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 5
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">5</div>
            <div className="ProgressBar__item__label">send link</div>
          </div>
        </div>
      )}

      {progressBarType == "tenant" && (
        <div className="ProgressBar__container">
          <div
            className={
              currentStep > 1
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 1
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">1</div>
            <div className="ProgressBar__item__label">basic info</div>
          </div>
          <div
            className={
              currentStep > 2
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 2
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">2</div>
            <div className="ProgressBar__item__label">
              add <span className="nowrap">co-applicants</span>
            </div>
          </div>
          <div
            className={
              currentStep > 3
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 3
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">3</div>
            <div className="ProgressBar__item__label">housing</div>
          </div>
          {/*  */}
          <div
            className={
              currentStep > 4
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 4
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">4</div>
            <div className="ProgressBar__item__label">
              employment info
            </div>
          </div>
          <div
            className={
              currentStep > 5
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 5
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">5</div>
            <div className="ProgressBar__item__label">
              household info
            </div>
          </div>

          {/*
          <div
            className={
              currentStep > 6
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 6
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">6</div>
            <div className="ProgressBar__item__label">guarantor</div>
          </div>
          <div
            className={
              currentStep > 7
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 7
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">7</div>
            <div className="ProgressBar__item__label">premium</div>
          </div>
          */}
          <div
            className={
              currentStep > 8
                ? "ProgressBar__item ProgressBar__item--completed"
                : currentStep == 8
                ? "ProgressBar__item ProgressBar__item--current"
                : "ProgressBar__item"
            }
          >
            <div className="ProgressBar__item__count">6</div>
            <div className="ProgressBar__item__label">review</div>
          </div>
        </div>
      )}
    </section>
  )
}

export default ProgressBar
