import ProgressBar from "../../../Components/ProgressBar/ProgressBar"
import "./choosePath.scss"
import { useNavigate } from "react-router-dom"
import { useRef, useState } from "react"
import Logo from "../../../assets/svg/rentPandaLogo.svg"
import Helmet from "react-helmet"

export default function ChoosePath() {
  let navigate = useNavigate()
  const [selected, setSelected] = useState("")
  const btnRef = useRef()
  const [path, setPath] = useState("")

  return (
    <section className="ChoosePath">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Smart Screening - Choose Path | Rent Panda</title>
        <meta
          name="description"
          content="Start with a basic application or skip right to the advanced screening now."
        />
      </Helmet>
      <div className="container">
        <button
          className="btn btn--back"
          onClick={() => {
            navigate("/signup")
          }}
        >
          back
        </button>

        <ProgressBar type="landlord" step="3" />
        <h1>choose your path to find great tenants</h1>
        <div className="row align-items-center ChoosePath__btn-container">
          <div className="col-lg-5">
            <button
              id="option1"
              onClick={(e) => {
                setSelected(e.target.id)
                setPath("/landlord/customize-application")
              }}
              className={
                selected === "option1"
                  ? "btn ChoosePath__btn ChoosePath__btn--active"
                  : "btn ChoosePath__btn"
              }
            >
              Start with a basic&nbsp;application
              <span
                id="option1"
                onClick={(e) => {
                  setSelected(e.target)
                  setPath("/landlord/customize-application")
                }}
              >
                I’ve begun advertising and need to start with a basic
                application to screen incoming applicants.
              </span>
            </button>
          </div>
          <div className="col-lg-auto">
            <h2 className="my-0 text center">or</h2>
          </div>
          <div className="col-lg-5">
            <button
              id="option2"
              onClick={(e) => {
                setSelected(e.target.id)
                setPath("/landlord/screen-applicants")
              }}
              className={
                selected === "option2"
                   ? "btn ChoosePath__btn ChoosePath__btn--active"
                   : "btn ChoosePath__btn"
               }
            >
              skip right to the advanced screening&nbsp;now
              <span
                class="form-checkbox__details"
                id="option2"
                onClick={(e) => {
                  setSelected(e.target)
                  setPath("/landlord/screen-applicants")
                }}
              >
                I’ve found a few applicants and need to conduct a
                thorough screening of them.
              </span>{" "}
            </button>
          </div>
        </div>
        <div className="btn-group">
          {/*        <button
            className="btn btn--back"
            onClick={() => {
              navigate(-1)
            }}
          >
            back
          </button> */}
          <button
            className="btn btn--primary"
            onClick={() => {
              navigate(path)
            }}
          >
            next
          </button>
        </div>
      </div>
    </section>
  )
}
