const { useRef } = require("react")

export const useFnRef = (fn) => {
  const ref = useRef()
  ref.current = fn
  const callRef = useRef()
  if (!callRef.current) {
    callRef.current = (...args) => {
      return ref.current(...args)
    }
  }
  return callRef.current
}
