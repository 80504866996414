import {
  Link,
  useNavigate,
} from "react-router-dom"

function AlreadyAppliedModal({ props }) {
  let navigate = useNavigate()
  
  return (
    <div className="AlreadyAppliedModal modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper">
        <div className={"small_modal"}>
          <div className="modal__container">
            <h2>you've already applied to this property</h2>

            <div className="textContainer">
              <p>You can re-access your application by going to your dashboard and continuing your application</p>
            </div>

            <button className="btn btn--primary"
                    onClick={() => navigate("/tenant/dashboard")}>
              Go to my dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlreadyAppliedModal;