function createValidator(...validators) {
  let validator = (content) => {
    const errors = validators.filter((validator) => {
      if (
        validator.test instanceof RegExp
          ? validator.test.test(content)
          : validator.test(content)
      ) {
        return false
      }
      return true
    })
    if (errors.length === 0) return { valid: true }
    return {
      valid: false,
      errors: errors
        .sort((a, b) => (a.priority ?? 1) - (b.priority ?? 1))
        .map((error) => {
          return {
            message:
              typeof error.error === "function"
                ? error.error(content)
                : error.error,
            id: error.id,
          }
        }),
    }
  }
  validator.fields = validators
  return validator
}

const EMAIL_REGEX =
  // see https://stackoverflow.com/a/201378
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i

export const EMAIL_VALIDATOR = createValidator({
  test: EMAIL_REGEX,
  error: "Invalid Email",
  id: "INVALID_EMAIL",
})

export const PASSWORD_VALIDATOR = createValidator(
  {
    test: (content) => content.length >= 8,
    error: "Password must be atleast 8 characters",
    id: "PASSWORD_LENGTH",
  },
  {
    test: /[a-z]/,
    error: "Password must include a lowercase letter",
    id: "PASSWORD_LOWERCASE",
  },
  {
    test: /[A-Z]/,
    error: "Password must include an uppercase letter",
    id: "PASSWORD_UPPERCASE",
  },
  {
    test: /[0-9]/,
    error: "Password must include a number",
    id: "PASSWORD_NUMBER",
  },
  {
    test: /[^a-zA-Z0-9]/,
    error: "Password must include a special character",
    id: "PASSWORD_SPECIAL",
  }
)
