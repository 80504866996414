import "./backgroundCheck.scss"
import { useState } from "react"
import BackgroundCheckModal from "./BackgroundCheckModal/BackgroundCheckModal"
import { useNavigate } from "react-router-dom"

function BackgroundCheck() {
  const navigate = useNavigate()
  const [
    openBackgroundCheckModal,
    setOpenBackgroundCheckModal,
  ] = useState(false)

  return (
    <section className="BackgroundCheck">
      <div className="container">
      <button
            className="btn btn--back"
            onClick={() => {
              navigate(-1)
            }}
          >
            back
          </button>
        <h1>Background Check</h1>
        <h2>status</h2>
        <p>incomplete</p>

        <div className="btn-group">

          <button
            className="btn btn--primary"
            onClick={() => {
              setOpenBackgroundCheckModal(true)
            }}
          >
            start background check
          </button>
        </div>
      </div>
      {openBackgroundCheckModal && (
        <BackgroundCheckModal
          setOpenBackgroundCheckModal={
            setOpenBackgroundCheckModal
          }
        />
      )}
    </section>
  )
}

export default BackgroundCheck
