import { Link, useNavigate } from "react-router-dom"
import "./navbar.scss"
import Logo from "../../assets/images/RentPanda_logo.png"
import { useState, useRef, useEffect } from "react"
import { AuthContext } from "Context/authContext/AuthContext"
import { useContext } from "react"
import { logout } from "../../Context/authContext/AuthActions"


export default function Navbar() {
  const navigate = useNavigate()
  const [authenticated, setAuthenticated] = useState(true)
  const [dropdownActive, setDropdownActive] = useState(false)
  const dropDownRef = useRef()
  const { dispatch, user } = useContext(AuthContext)

  const closeDropdown = (e) => {
    if (e.path[0] !== dropDownRef.current) setDropdownActive(false)
  }

  const [userTypeEndpoint, setUserTypeEndpoint] = useState("")

  useEffect(() => {
    if (user) {
      if (user.roles[0].pivot.role_id === 1) {
        setUserTypeEndpoint("/landlord/dashboard")
      } else {
        setUserTypeEndpoint("/tenant/dashboard")
      }
    }
  }, [user])

  useEffect(() => {
    document.body.addEventListener("click", closeDropdown)
    return () =>
      document.body.removeEventListener("click", closeDropdown)
  }, [])

  return (
    <header className="Navbar">
      <div className="container">
        <div className="row justify-content-between align-center">
          <div className="col-auto">
            <Link to="/">
              <img src={Logo} alt="Rent Panda Logo" />
            </Link>
          </div>
          <div className="navigation">
          <div className="col-auto">
            {Boolean(authenticated) && (
              <nav>
                {/* Remove notification button temp. */}
                {/*<button className="btn Navbar__notification-btn"></button>*/}

                <div className="Navbar__dropdown">
                  <button
                    className="btn Navbar__dropdown__btn"
                    onClick={() => {
                      setDropdownActive(!dropdownActive)
                    }}
                    ref={dropDownRef}
                  >
                <div className="burger burger1"/>
                <div className="burger burger2"/>
                <div className="burger burger3"/>
                  </button>
                  <ul
                    className={
                      dropdownActive
                        ? "Navbar__dropdown__menu Navbar__dropdown__menu--active"
                        : "Navbar__dropdown__menu"
                    }
                  >
                    <li>
                    {user ? (
                  <Link
                    to={userTypeEndpoint}
                    className="Navbar__user-name"
                  >
                    {user.first_name} {user.last_name}
                  </Link>
                ) : null}
                    </li>
                    {user ? (
                      <>
                        <li>
                          <button
                            className="btn Navbar__dropdown__menu-item"
                            onClick={() => {
                              navigate("/my-account")
                            }}
                          >
                            my account
                          </button>
                        </li>
                        <li>
                          {user &&
                          user.roles[0].name == "landlord" ? (
                            <button
                              className="btn Navbar__dropdown__menu-item"
                              onClick={() => {
                                navigate("/landlord/property/create")
                              }}
                            >
                              create new application
                            </button>
                          ) : null}
                        </li>
                        <li>
                          {user &&
                          user.roles[0].name == "landlord" ? null : (
                            <button
                              className="btn Navbar__dropdown__menu-item"
                              onClick={() => {
                                navigate(
                                  "/tenant/dashboard/application"
                                )
                              }}
                            >
                              my application
                            </button>
                          )}
                        </li>
                        <Link to="/login">
                          <button
                            className="btn btn--accent logout-btn"
                            onClick={() => {
                              dispatch(logout())
                            }}
                          >
                            log out
                          </button>
                        </Link>
                      </>
                    ) : (
                      <Link to="/login">
                        <button className="btn btn--accent login-btn">
                          log in
                        </button>
                      </Link>
                    )}
                  </ul>
                </div>
              </nav>
            )}
            {Boolean(!authenticated) && (
              <nav>
                <Link to="/login">
                  <button className="btn btn--primary btn--small btn--shrink">
                    Log in
                  </button>
                </Link>
              </nav>
            )}
          </div>
          </div>
        </div>
      </div>
    </header>
  )
}
